import { ClassicPreset } from "rete";
import { Dictionary } from "../../../../../expression/domain/dictionary";
import { Container } from "inversify";
import { IapWorkFlowActivity } from "../../../../domain/service/iapWorkFlowActivity";
import { Tuple } from "../../../../../expression/domain/tuple";

export class NavigationControl extends ClassicPreset.Control {
  constructor(
    public applicationId:number, 
    public applicationVersion:number,
    public currentComponentInputId:number,
    public variableRedirectTypeInput: Number,
    public variableParamsRedirectUrlInput: String,
    public variableParamsRedirectUrlTargetInput: String,
    public variableParamsRedirectUrlParamsInput: String,
    public variableMenuInput: Number,
    public variableParamsRedirectInput: Dictionary<String,Tuple<String,String,String>>[], 
    public variableCompsInput:any | null,
    public container: Container | undefined,
    public itemActivity: IapWorkFlowActivity | undefined,
    public onChange: (evt: any) => void,
    public getNode: (evt: any) => void, 
    public showExpression:(evt:any) => void) {
    super();
  }
}


