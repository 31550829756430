import BaseControlTypeConst from "./BaseControlTypeConst"

export default class SearchControlTypeConst extends BaseControlTypeConst {
  
    static INPUTTYPE='inputType'
    static INPUTCLASS='inputClass'
    static LABELDESC='labelDesc'
    static LABELCLASS='labelClass'
    static SHOWFILTER='showFilter'
    static ICONCLASS='iconClass'
    static ENABLEFILTERICON='enableFilterIcon'
    static DISABLEFILTERICON='disableFilterIcon'
    static LAYOUT='layout'
    static FILTERCLASS='filterClass'
    
    
  }
  