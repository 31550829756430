import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'

import type { ISearchConfigRepository } from '../domain/ISearchConfigRepository';
import { IServiceSearchConfig } from './IServiceSearchConfig';
import { IampSearchConfig } from '../domain/iapmSearchConfig';



@injectable()
export class ServiceSearchConfig implements IServiceSearchConfig {
  private readonly repo: ISearchConfigRepository

  public constructor (@inject(TYPES.SEARCH_USR_REPOSITORY) _repo: ISearchConfigRepository) {
    this.repo = _repo
  }
   

  async update (data: IampSearchConfig,dataToUpdate:IampSearchConfig[]): (Promise<IampSearchConfig[]>) {
    return await this.repo.update(data,dataToUpdate);
  }

  async add (data: IampSearchConfig): (Promise<IampSearchConfig>) {
    return await this.repo.add(data);
  }

  async getByAllByUserId (userId: number, applicationId: number, applicationVersion: number, componentId: number,searchType?:string) :Promise<IampSearchConfig[]> {
    return await this.repo.getByAllByUserId(userId,applicationId,applicationVersion,componentId,searchType)
  }

  async delete (id: number):Promise<boolean> {
    return await this.repo.delete(id)
  }
}
