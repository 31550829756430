import CatalogTypeConst from "@ilinium/shared/src/common/domain/constantes/CatalogTypeConst";
import FiltroBusquedaConst from "@ilinium/shared/src/common/domain/constantes/FiltroBusquedaConst";
import SqlTypesConst from "@ilinium/shared/src/common/domain/constantes/SqlTypesConst";
import DataSourceComp from "./dataSourceComp";
import { useStore } from "vuex";
import { Container } from "inversify";
import { IapComponentDataSource } from "../../../component/domain/iapComponentDataSource";
import { IapCatalog } from "../../../catalog/domain/iapCatalog";
import { ref } from "vue";
export default function SearchHelper(
    container:Container,
    dscInput: IapComponentDataSource,
    catalogos: IapCatalog[],
    store: any,
) {

    const {  isType, getCatalogData
    } = DataSourceComp(container as any, {} as any, undefined, undefined, dscInput,catalogos, store)

    const dateFilter:string[]=[FiltroBusquedaConst.FILTROBUSQUEDA_ENTRE,FiltroBusquedaConst.FILTROBUSQUEDA_CURRENTMONTH,FiltroBusquedaConst.FILTROBUSQUEDA_CURRENTYEAR,
        FiltroBusquedaConst.FILTROBUSQUEDA_PREVIOUSMONTH,FiltroBusquedaConst.FILTROBUSQUEDA_PREVIOUSYEAR,FiltroBusquedaConst.FILTROBUSQUEDA_PREVIOUSWEEK,FiltroBusquedaConst.FILTROBUSQUEDA_CURRENTWEEK,
        FiltroBusquedaConst.FILTROBUSQUEDA_DAYSAGO
        ];

        
    const notShowControl:string[]=[FiltroBusquedaConst.FILTROBUSQUEDA_NONULO,FiltroBusquedaConst.FILTROBUSQUEDA_NULO,FiltroBusquedaConst.FILTROBUSQUEDA_CURRENTMONTH,FiltroBusquedaConst.FILTROBUSQUEDA_CURRENTYEAR,
        FiltroBusquedaConst.FILTROBUSQUEDA_PREVIOUSMONTH,FiltroBusquedaConst.FILTROBUSQUEDA_PREVIOUSYEAR,FiltroBusquedaConst.FILTROBUSQUEDA_PREVIOUSWEEK,FiltroBusquedaConst.FILTROBUSQUEDA_CURRENTWEEK,
         ];   


         

        const getCatalogFilterByFieldType = (item: any) => {
            
            if(!item) return []

            let catalog = getCatalogData(CatalogTypeConst.FILTROBUSQUEDA);

            const text:string[]=[FiltroBusquedaConst.FILTROBUSQUEDA_CONTIENE,FiltroBusquedaConst.FILTROBUSQUEDA_NOTCONTAINS,FiltroBusquedaConst.FILTROBUSQUEDA_STARTWITH
                ,FiltroBusquedaConst.FILTROBUSQUEDA_ENDWITH
            ];
            const num:string[]=[FiltroBusquedaConst.FILTROBUSQUEDA_GREATTHAN,FiltroBusquedaConst.FILTROBUSQUEDA_LESSTHAN,FiltroBusquedaConst.FILTROBUSQUEDA_GREATTHANEQTO
                ,FiltroBusquedaConst.FILTROBUSQUEDA_LESSTHANEQTO,FiltroBusquedaConst.FILTROBUSQUEDA_DISTINTO
            ];


            if (isType(item, SqlTypesConst.VARCHAR)) {
                catalog = catalog.filter(x =>!num.includes(x.id) && !dateFilter.includes(x.id));
            }

            if (isType(item, SqlTypesConst.INT) || isType(item, SqlTypesConst.MONEY)) {
                catalog = catalog.filter(x =>!text.includes(x.id) && !dateFilter.includes(x.id));
            }

            if (isType(item, SqlTypesConst.DATETIME) || isType(item, SqlTypesConst.DATE)) {
                catalog = catalog.filter(x =>!text.includes(x.id));
            }


            return catalog.sort((a: { order: number; }, b: { order: number; }) => { return a.order - b.order; });
        }   

      


    return {
        getCatalogFilterByFieldType,
        notShowControl,
        dateFilter
    };
}


