<SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
:data="Component"
:vmodel="vmodel"
/>
<span v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE,Component) && canDoOperation(ObjectGroupConst.VIEW,Component)" 
:key="componentKey" :id="getPropertyValue(HtmlTypeConst.ID,Component)" :class="getCompClass(Component)" v-tooltip="getPropertyValue(HtmlTypeConst.TOOLTIP,Component)" 
:style="getPropertyValue(HtmlTypeConst.STYLE,Component)"  :name="getPropertyValue(HtmlTypeConst.NAME,Component)"
@click="processEventComponent(CatalogEventConst.CLICK,Component)" >
<slot name="content"></slot>
{{ getPropertyValue(HtmlTypeConst.TEXT,Component) }} {{ vmodel }}
</span>