    <template  src="./DynamicLabel.html">
    </template>
<script lang="ts">


import { defineComponent, onBeforeUnmount } from 'vue';
import ComponentCommonRender from '../../../../../domain/Functions/ComponentCommonRender';
import LabelTypeConst from '../../../../../domain/Constants/LabelTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_label',
    components: {
        SummaryDebug
    },
    props:
    {

        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },      
        slotProps: {
            type: Object,
            default: () => ({})
        },       
    },
    setup(props) {
        //
        
        const store = useStore();
        const { vmodel,canDoOperation, getPropertyValue, getPropertyBooleanValue, loaded,  baseOnBeforeUnmount 
            ,fieldKey,fieldKeyComponentDataSourceId,componentKey,getCompClass
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);


        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
        })

        return {
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            LabelTypeConst,
            loaded,
            BaseControlTypeConst,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey
            ,getCompClass
        };
    },

})




</script>
<style scoped></style>
