import { IErrorRepository } from '../domain/IErrorRepository'
import { injectable } from 'inversify'
import 'reflect-metadata'
import { http } from '../../../../common/infrastructure/funciones/HelperHttp'
import { errorInput } from '../domain/errorInput'


@injectable()
export class ErrorAxiosRepository implements IErrorRepository {
  constructor (urlBase: string) {
    http.initHttp(urlBase)
  }
 
  
  // Métodos de Error
  async logError (request: errorInput): (Promise<boolean>) {
    return (await http.post('/error/logError/', request))
  }

}
