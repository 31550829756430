<template  src="./DynamicInputText.html">
</template>
<script lang="ts">


import { defineComponent, onMounted, onBeforeUnmount, computed, ref } from 'vue';
import ComponentCommonRender from '../../../../../domain/Functions/ComponentCommonRender';
import InputTextTypeConst from '../../../../../domain/Constants/InputTextTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../../designer/domain/ComponentDataForm';
import CustomValidate from '../../../shared/CustomValidate.vue';
import HelperCommon from '../../../../../../../../common/infrastructure/funciones/HelperCommon';
import Mic from '../../../../../../../../common/infrastructure/componentes/base/common/mic/Mic.vue';
import { useStore } from 'vuex';
import ObjectGroupConst from '../../../../../../../../common/domain/constantes/ObjectGroupConst';
import CatalogCfgUbmChatConst from '../../../../../../catalog/domain/const/CatalogCfgUbmChatConst';
import SummaryDebug from '../../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_input-text',
    components: {
        CustomValidate,
        Mic,
        SummaryDebug
    },
    props:
    {

        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
        slotProps: {
            type: Object,
            default: () => ({})
        },
    },
    setup(props) {
        

        const store = useStore();
        const { vmodel, canDoOperation, getPropertyValue, getPropertyBooleanValue, loaded, baseOnMounted, baseOnBeforeUnmount, v$, submitted, canEdit, isValidData
            , fieldKey, fieldKeyComponentDataSourceId, sendUbmChat, sendMail,getCompClass, componentKey
        } = ComponentCommonRender(props.Component, props.slotProps, props.container, store);


        const menu = ref();
        const getItems = computed(() => {
            const items = [
                {
                    label: 'Copiar',
                    icon: 'pi pi-copy',
                    visible: getPropertyBooleanValue(InputTextTypeConst.COPYTOCLIPBOARDB),
                    command: () => {
                        HelperCommon.copyToClipBoard(vmodel.value);
                    }
                },
                {
                    label: 'Whatsapp',
                    icon: 'pi pi-whatsapp',
                    visible: getPropertyBooleanValue(InputTextTypeConst.CHAT) && hasUbmChat.value,
                    command: () => {
                        sendUbmChat();
                    }
                },
                {
                    label: 'Correo',
                    icon: 'pi pi-envelope',
                    visible: getPropertyBooleanValue(InputTextTypeConst.EMAIL),
                    command: () => {
                        sendMail();
                    }
                },

            ];

            return items.filter(x => x.visible);

        })

        const toggle = (event: Event) => {
            if (getItems.value.length > 1){
                menu.value.toggle(event);
                event.stopPropagation();
            }
            
        };


        const hasUbmChat = computed(() => {
            
            return store.getters.entityMainCfgValue(CatalogCfgUbmChatConst.CFGUBMCHAT_ENABLE)?.toLowerCase().trim() == 'true'
        })

        const hasGroupControls = computed(() => {
            return getPropertyBooleanValue(InputTextTypeConst.MIC) || getPropertyBooleanValue(InputTextTypeConst.COPYTOCLIPBOARDB)
                || (getPropertyBooleanValue(InputTextTypeConst.CHAT) && hasUbmChat.value) || getPropertyBooleanValue(InputTextTypeConst.EMAIL)
        })



        onMounted(() => {
            baseOnMounted();
        })

        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
            menu.value=null;
        })


        return {

            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            InputTextTypeConst,
            loaded,
            BaseControlTypeConst,
            v$,
            submitted,
            canEdit,
            hasGroupControls,
            HelperCommon,
            isValidData,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            , canDoOperation
            , hasUbmChat
            , sendUbmChat
            , sendMail
            , componentKey
            , getItems
            , menu
            , toggle
            ,getCompClass

        };
    },
});
</script>
<style scoped></style>
