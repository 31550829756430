import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-1 flex flex-grow-1" }
const _hoisted_2 = { style: {"width":"100%","height":"100%"} }
const _hoisted_3 = {
  key: 1,
  class: "flex flex-grow-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_LookUpEditor = _resolveComponent("LookUpEditor")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_TriStateCheckbox = _resolveComponent("TriStateCheckbox")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", {
        key: _ctx.componentKey,
        class: _normalizeClass(_ctx.containerClass)
      }, [
        _createCommentVNode("label"),
        _createElementVNode("label", {
          for: "",
          class: _normalizeClass(_ctx.labelClass)
        }, _toDisplayString(_ctx.labelDesc), 3 /* TEXT, CLASS */),
        _createElementVNode("div", _hoisted_1, [
          _createCommentVNode("filtro"),
          (_ctx.showFilter && _ctx.filterVisible && _ctx.showFilterIcon)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                onMouseover: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setTooltip()))
              }, [
                _createVNode(_component_Dropdown, {
                  modelValue: _ctx.vmodelFilter,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vmodelFilter) = $event)),
                  options: _ctx.getCatalogFilterByFieldType(_ctx.field),
                  optionLabel: "value",
                  optionValue: "id",
                  title: _ctx.toolTipDD,
                  class: _normalizeClass([_ctx.filterClass, "mr-1"]),
                  onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleEvt($event, _ctx.ControlTypeConst.CTDROPDOWN)))
                }, {
                  option: _withCtx((slotProps) => [
                    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createTextVNode(_toDisplayString(slotProps.option.value), 1 /* TEXT */)
                    ])), [
                      [_directive_tooltip, _ctx.getToolTipFilter(slotProps.option)]
                    ])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue", "options", "title", "class"])
              ], 32 /* NEED_HYDRATION */))
            : _createCommentVNode("v-if", true),
          _createCommentVNode("input"),
          (!_ctx.notShowControl.includes(_ctx.vmodelFilter))
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_ctx.inputType == _ctx.ControlTypeConst.CTLOOKUPEDITOR)
                  ? (_openBlock(), _createBlock(_component_LookUpEditor, {
                      key: 0,
                      modelValue: _ctx.vmodel,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.vmodel) = $event)),
                      dataSourceLookUp: _ctx.getDsLookUp(),
                      container: _ctx.container,
                      filterConditions: [],
                      showSearch: true,
                      showClear: true
                    }, null, 8 /* PROPS */, ["modelValue", "dataSourceLookUp", "container"]))
                  : _createCommentVNode("v-if", true),
                (_ctx.inputType == _ctx.ControlTypeConst.CTMULTISELECT)
                  ? (_openBlock(), _createBlock(_component_MultiSelect, {
                      key: 1,
                      modelValue: _ctx.vmodel,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.vmodel) = $event)),
                      options: _ctx.getOptions(),
                      filter: true,
                      optionLabel: "description",
                      optionValue: "id",
                      class: "flex-grow-1"
                    }, null, 8 /* PROPS */, ["modelValue", "options"]))
                  : _createCommentVNode("v-if", true),
                (_ctx.inputType == _ctx.ControlTypeConst.CTDROPDOWN)
                  ? (_openBlock(), _createBlock(_component_Dropdown, {
                      key: 2,
                      modelValue: _ctx.vmodel,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.vmodel) = $event)),
                      options: _ctx.getOptions(),
                      filter: true,
                      optionLabel: "description",
                      optionValue: "id",
                      showClear: "",
                      class: "flex-grow-1"
                    }, null, 8 /* PROPS */, ["modelValue", "options"]))
                  : _createCommentVNode("v-if", true),
                (_ctx.inputType == _ctx.ControlTypeConst.CTINPUTSWITCH)
                  ? (_openBlock(), _createBlock(_component_InputSwitch, {
                      key: 3,
                      modelValue: _ctx.vmodel,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.vmodel) = $event)),
                      class: "mr-2",
                      trueValue: '1',
                      falseValue: '0'
                    }, null, 8 /* PROPS */, ["modelValue"]))
                  : _createCommentVNode("v-if", true),
                (_ctx.inputType == _ctx.ControlTypeConst.CTTRISTATECHECKBOX)
                  ? (_openBlock(), _createBlock(_component_TriStateCheckbox, {
                      key: 4,
                      modelValue: _ctx.vmodel,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.vmodel) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"]))
                  : _createCommentVNode("v-if", true),
                (_ctx.inputType == _ctx.ControlTypeConst.CTINPUTTEXT)
                  ? (_openBlock(), _createBlock(_component_InputText, {
                      key: 5,
                      modelValue: _ctx.vmodel,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.vmodel) = $event)),
                      class: "flex-grow-1"
                    }, null, 8 /* PROPS */, ["modelValue"]))
                  : _createCommentVNode("v-if", true),
                (_ctx.inputType == _ctx.ControlTypeConst.CTCALENDAR && _ctx.vmodelFilter != _ctx.FiltroBusquedaConst.FILTROBUSQUEDA_DAYSAGO)
                  ? (_openBlock(), _createBlock(_component_Calendar, {
                      key: 6,
                      ref: "calendarComponentRef",
                      showButtonBar: true,
                      modelValue: _ctx.vmodel,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.vmodel) = $event)),
                      manualInput: true,
                      selectionMode: _ctx.selectionMode,
                      class: "flex-grow-1"
                    }, null, 8 /* PROPS */, ["modelValue", "selectionMode"]))
                  : _createCommentVNode("v-if", true),
                (_ctx.inputType == _ctx.ControlTypeConst.CTCALENDAR && _ctx.vmodelFilter == _ctx.FiltroBusquedaConst.FILTROBUSQUEDA_DAYSAGO)
                  ? (_openBlock(), _createBlock(_component_InputNumber, {
                      key: 7,
                      modelValue: _ctx.vmodelInputDate,
                      "onUpdate:modelValue": [
                        _cache[10] || (_cache[10] = ($event: any) => ((_ctx.vmodelInputDate) = $event)),
                        _cache[11] || (_cache[11] = ($event: any) => (_ctx.handleEvt({ value: $event }, _ctx.ControlTypeConst.CTINPUTNUMBER)))
                      ],
                      showButtons: "",
                      inputStyle: 'width: 60px',
                      placeholder: "Días..."
                    }, null, 8 /* PROPS */, ["modelValue"]))
                  : _createCommentVNode("v-if", true),
                (_ctx.inputType == _ctx.ControlTypeConst.CTINPUTNUMBER)
                  ? (_openBlock(), _createBlock(_component_InputNumber, {
                      key: 8,
                      modelValue: _ctx.vmodel,
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.vmodel) = $event)),
                      class: "flex-grow-1"
                    }, null, 8 /* PROPS */, ["modelValue"]))
                  : _createCommentVNode("v-if", true),
                _createCommentVNode("icon"),
                (_ctx.showFilter && _ctx.filterVisible)
                  ? (_openBlock(), _createElementBlock("i", {
                      key: 9,
                      class: _normalizeClass([_ctx.iconClass, "ml-1 flex align-items-center"]),
                      style: {"font-size":"1rem"},
                      onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.showFilterIcon = !_ctx.showFilterIcon))
                    }, null, 2 /* CLASS */))
                  : _createCommentVNode("v-if", true)
              ]))
            : _createCommentVNode("v-if", true)
        ])
      ], 2 /* CLASS */))
    : _createCommentVNode("v-if", true)
}