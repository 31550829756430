<template>
    <div v-if="dataInput.length > 0" class="col-12">
        <div class="card">
            <div class="p-fluid formgrid grid">
                <div v-for="(item, index) of dataInput" :key="item.fieldId + index.toString()" class="field col-12 md:col-3">
                    <div>
                        <Checkbox v-model="checked" :inputId="item.fieldId + index" :name="getColumnName(item.fieldId)"
                            :value="item.fieldId" />
                        <label for="question"
                            :class="{ 'p-invalid': v$.items.$each.$response.$errors[index].value.length > 0 && submitted, 'customrequired': item.required }">
                            {{ getColumnName(item.fieldId) }}
                        </label>

                        <InputSwitch style="display: block;" v-if="isColumnType(item.fieldId, SqlTypesConst.BIT)"
                            v-model="item.value" :trueValue="true" :falseValue="false"
                            :disabled="checkDisabledControl(item.field)" />
                        <Calendar
                            v-else-if="isColumnType(item.fieldId, SqlTypesConst.DATE) || isColumnType(item.fieldId, SqlTypesConst.DATETIME)"
                            v-model="item.value" :disabled="checkDisabledControl(item.fieldId)" />
                        <InputNumber v-else-if="isColumnType(item.fieldId, SqlTypesConst.INT)" v-model="item.value"
                            :disabled="checkDisabledControl(item.fieldId)" />
                        <InputNumber v-else-if="isColumnType(item.fieldId, SqlTypesConst.DECIMAL)" v-model="item.value"
                            mode="currency" :disabled="checkDisabledControl(item.fieldId)" />
                        <Dropdown v-else-if="isCatalogTypeValue(item.fieldId)" v-model="item.value"
                            :options="getCatalogData(item.fieldId)" optionLabel="description" optionValue="id"
                            :disabled="checkDisabledControl(item.fieldId)" filter/>

                        <!--
                        {}
                     
-->
                        <!--<crudTabla v-else-if="isTableTypeValue(item.field)" :container="container" :baseDatos="baseDatos" :tablas="tablas" :mostrarBotonCerrarPestañas="false"  />-->
                        <InputText v-else v-model="item.value" :disabled="checkDisabledControl(item.fieldId)" />

                        <span v-if="submitted">
                            <span v-for="error in v$.items.$each.$response.$errors[index].value" :key="error">
                                <small class="p-error">{{ error.$message }}</small>
                            </span>
                        </span>

                    </div>


                </div>

            </div>
            <div>
                <Toolbar v-if="checked.length > 0 && selectedItems.length > 0">
                    <template #end>
                        <Button id="update" label="Actualizar" icon="pi pi-save"
                            class="mr-2  p-button-rounded p-button-primary p-button-outlined" @click="saveData" />
                    </template>
                </Toolbar>
            </div>
        </div>
    </div>
    <Message v-else severity="warn" :closable="false">El origen de datos no tiene ninguna configuración para actualizar
        registros masivamente</Message>
</template>
<script lang="ts">

import { Container } from 'inversify';
import { defineComponent, ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { messages } from './localization/MessagesBusqueda';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { IapCatalog } from '../../../catalog/domain/iapCatalog';
import { clave, claveNew } from '../../../dataupdate/domain/clave';
import DataSourceComp from '../functions/dataSourceComp';
import HelperCommon from '../../../../../common/infrastructure/funciones/HelperCommon';
import OperationDataTypeConst from '../../../../../common/domain/constantes/OperationDataTypeConst';
import { IServiceDataUpdate } from '../../../dataupdate/application/IServiceDataUpdate';
import { TYPES } from '../../../../../common/domain/types';
import HelperLoading from '../../../../../common/infrastructure/funciones/HelperLoading';
import { MessageService } from '../../../../../common/infrastructure/servicios';
import { MessageType } from '../../../../../common/infrastructure/servicios/MessageService';
import SqlTypesConst from '../../../../../common/domain/constantes/SqlTypesConst';
import { dataUpdate } from '../../../dataupdate/domain/dataUpdate';
import { IapDataSourceField } from '../../../datasource/domain/iapDataSourceField';
import { IapComponentDataSource } from '../../../component/domain/iapComponentDataSource';
import DataSourceConst from '../functions/dataSourceConst';
import { IapComponent } from '../../../component/domain/iapComponent';
import HelperUtils from '../../../../../common/infrastructure/funciones/HelperUtils';
import { useStore } from 'vuex';
import HelperDataUpdate from '../../../dataupdate/infrastructure/helper/HelperDataUpdate';
import { DataUpdateOperation } from '../../../dataupdate/domain/dataUpdateOperation';

export default defineComponent({
    name: 'masive_update',
    props: {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => IapComponent,
            default: () => ({})
        },

        catalogosData: {
            type: Object as () => IapCatalog[],
            default: () => ([])
        },

        compDataSource: {
            type: Object as () => IapComponentDataSource,
            default: () => ({})
        },
        selectedItems: {
            type: Object,
            default: () => ([])
        },
        /*formKey:{
        type: String,
        default: ''
        }
        */
    },
    emits: ['refreshData'],
    setup(props, { emit }) {
        const store = useStore();
        const { t, locale } = useI18n(messages)
        const { isType, isCatalogType, isTableType,  tablasData, getDefaultvalue,getFieldId,getFieldFromKeyCol,getFieldKeyColumn } = DataSourceComp(props.container as any, props, emit, t, props.compDataSource,[] as any,store)
        const submitted = ref(false);
        const dataInput = ref<claveNew[]>([]);
        const checked = ref<Array<String>>([]);
        const reglas = {
            items: {
                $each: helpers.forEach({
                    value: {
                        required: helpers.withMessage('El campo es obligatorio', requiredIf((...args) => {
                            var datos = args as any;
                            return !checkDisabledControl(datos[args.length - 1].field) && datos[args.length - 1].required
                                && !(datos[args.length - 1].value)

                        }
                        )),
                    },
                })
            }
        }

        //requiredIf(prop => prop.type === 'input')


        const v$ = useVuelidate(reglas, { items: dataInput });



        const isColumnType = (columna: string, tipo: string) => {

            var item = getFieldFromKeyCol(columna,false,tablasData.value);
            if (item) {
                return isType(item, tipo);
            }

            return false;
        }


        const isCatalogTypeValue = (columna: string) => {
            var item = getFieldFromKeyCol(columna,false,tablasData.value);
            if (item) {
                const resu = isCatalogType(item)
                return resu
            }
            else {
                return false;
            }
        }


        const isTableTypeValue = (columna: string) => {
            var item = getFieldFromKeyCol(columna,false,tablasData.value);
            if (item) {
                const resu = isTableType(item)
                return resu
            }
            else {
                return false;
            }
        }




        const getCatalogData = (columna: string) => {
            var item = getFieldFromKeyCol(columna,false,tablasData.value);
            if (item) {
                const tipoCatalogoid = item.catalogTypeId
                return props.catalogosData.filter(x => x.typeId == tipoCatalogoid).sort((a, b) => { return a.order - b.order; })
            }
            return []
        }

        const getColumnName = (columna: string) => {
            var item = getFieldFromKeyCol(columna,false,tablasData.value);
            return item?.field ?? columna
        }


        const isValidData = (): boolean => {
            submitted.value = true;
            v$.value.$reset();
            v$.value.$touch();
            if (v$.value.$invalid) {
                HelperCommon.setFocusOnFirstError();
                return false;
            }

            return true;
        }

        const getPrimaryKeys = (data: any, columna: string): clave[] => {
            const _outputData: clave[] = [];
            //var _data = columna.split(DELIMITERFIELDS);
            const tableName = getFieldFromKeyCol(columna,false,tablasData.value)?.tableName;

            const keys = tablasData.value.filter(x => x.tableName == tableName && x.primaryKey).map(x =>
                getFieldKeyColumn(x)
            );
            keys.forEach(k => {
                _outputData.push({
                    fieldId: getFieldId(k,tablasData.value) as any,
                    value: data[k]
                })
            })

            return _outputData;
        }

        const saveData = () => {
            if (isValidData() && props.container) {
                
                const dataToUpdate = props.selectedItems.map((item: any) =>
                ({
                    valores: dataInput.value.filter(c => checked.value.includes(c.fieldId)).map(x => ({
                        fieldId: getFieldId(x.fieldId,tablasData.value),
                        value: isColumnType(x.fieldId, SqlTypesConst.BIT) ? JSON.stringify(x.value) : x.value
                    })) as any,
                    claves: getPrimaryKeys(item, Object.keys(item)[0]),
                    tipoOperacion: OperationDataTypeConst.UPDATE,
                    id:HelperUtils.newGuid()
                } as dataUpdate)) as dataUpdate[]

                const _srv = props.container.get<IServiceDataUpdate>(TYPES.DATAUPDATE_REPOSITORY)
                HelperLoading.showLoading()
                const requestData = JSON.parse(JSON.stringify(dataToUpdate))
                const dataInputRequest:DataUpdateOperation={
                        componentId:props.Component.id,
                        componentDataSourceId: props.compDataSource.id,
                        dataSourceId: props.compDataSource.dataSourceId,
                        parameters:props.compDataSource.dataSource.iapDataSourceServiceConfigurations??[],
                        data:requestData
                    }

                _srv.update(props.Component.applicationId, props.Component.applicationVersion, [dataInputRequest],true).then(response => {
                    if (HelperDataUpdate.hasErrorResponse(response))
                    {
                        HelperDataUpdate.formatErrorResponse(response);
                    }
                    else
                    {
                        MessageService.showToast(MessageType.Correcto, "", 'Registros modificados correctamente.')
                        emit('refreshData')
                    }
                }).finally(() => {
                    HelperLoading.hideLoading()
                })


            }
        }



        const checkDisabledControl = (key: any) => {
            return !checked.value?.includes(key)
        }

        onMounted(() => {
            dataInput.value = tablasData.value.filter(x => !((x.identityColumn) || (x.primaryKey))
                && props.compDataSource.iapComponentDataSourceFieldConfigurations.find(c => c.dataSourceFieldId == x.id)?.editMassive
            )
                .sort((a, b) => { return (a.position ?? 0) - (b.position ?? 0); })
                .map(x => ({
                    fieldId: getFieldKeyColumn(x),
                    value: getDefaultvalue(x,props.compDataSource.iapComponentDataSourceFieldConfigurations.find(c => c.dataSourceFieldId == x.id)?.defaultValue),
                    required: !x.nullable || props.compDataSource.iapComponentDataSourceFieldConfigurations.find(c => c.dataSourceFieldId == x.id)?.mandatory
                })) as any

        })

        return {
            t,
            v$,
            submitted,
            SqlTypesConst,
            dataInput,
            isColumnType,
            isCatalogTypeValue,
            isTableTypeValue,
            getCatalogData,
            getColumnName,
            saveData,
            checked,
            checkDisabledControl
        };
    },
});
</script>
<style scoped></style>

