<SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
:data="Component"
:vmodel="vmodel"
/>
<Button v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE,Component) && canDoOperation(ObjectGroupConst.VIEW,Component)" 
:key="componentKey" :id="getPropertyValue(ButtonTypeConst.ID,Component)" v-tooltip="getPropertyValue(ButtonTypeConst.TOOLTIP,Component)"
:style="getPropertyValue(ButtonTypeConst.STYLE,Component)" :class="getCompClass(Component)" :name="getPropertyValue(ButtonTypeConst.NAME,Component)" :disabled="getPropertyBooleanValue(ButtonTypeConst.DISABLED,Component)"
:label="getPropertyValue(ButtonTypeConst.LABEL,Component)" :icon="getPropertyValue(ButtonTypeConst.ICON,Component)" :iconPos="getCatalogValue(ButtonTypeConst.ICONPOS,Component)"
:iconClass="getPropertyValue(ButtonTypeConst.ICONCLASS,Component)" :badge="getPropertyValue(ButtonTypeConst.BADGE,Component)" :link="getPropertyBooleanValue(ButtonTypeConst.LINK,Component)" :severity="getCatalogValue(ButtonTypeConst.SEVERITY,Component)"
:raised="getPropertyBooleanValue(ButtonTypeConst.RAISED,Component)" :rounded="getPropertyBooleanValue(ButtonTypeConst.ROUNDED,Component)" :outlined="getPropertyBooleanValue(ButtonTypeConst.OUTLINED,Component)"
:plain="getPropertyBooleanValue(ButtonTypeConst.PLAIN,Component)" :size="getCatalogValue(ButtonTypeConst.SIZE,Component)"
v-model="vmodel" 
@click="processEventComponent(CatalogEventConst.CLICK,Component)">
</Button>