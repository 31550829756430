import { IServiceError } from './IServiceError'
import type { IErrorRepository } from '../domain/IErrorRepository'
import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'
import { errorInput } from '../domain/errorInput'



@injectable()
export class ErrorService implements IServiceError {
  private readonly ErrorRepo: IErrorRepository

  public constructor (@inject(TYPES.ERROR_REPOSITORY) _ErrorRepo: IErrorRepository) {
    this.ErrorRepo = _ErrorRepo
  }

  async logError (request: errorInput): (Promise<boolean>) {
    return await this.ErrorRepo.logError(request)
  }

}
