import gql from "graphql-tag";

export const GET_ALL_BY_COMPONENTID = gql(`
  query($componentId: Int!) {
  componentDataSourceQuery {
        getAllByComponentId(componentId:$componentId) {
  id,          
  dataSourceId,
  componentId,
  name,
  autoSave
  autoLoad
  addEmptyRow
  maxRowsReturned
  queryWhere
  queryOrderBy
  queryGroupBy
  queryHaving
  filterDistinct
  order
  applicationId
  applicationVersion
  fcr
  ucr  
  iapComponentDataSourceFieldConfigurations{
      componentDataSourceId
      dataSourceFieldId
      defaultFilterSearch      
      defaultFilterSearchOrder
      autoUpdate
      defaultValue
      defaultValidation
      edit
      add
      editMassive
      import
      fcr
      filterResult
      filterResultOrder
      filterSearch
      filterSearchOrder
      fum
      list
      view
      mandatory
      sortOrder
      idSortType
      aggregateOperation
      idFilterType
      idFilterControlType
      idDefaultMatchMode
      defaultFilterValue
      cellStyle
      displayContentMaxlength            
      dataSourceField{
          tableName
          field
          tableNameAlias,
          fieldAlias,
          dataSourceLookUpId,
         

      }
      
  }
  iapComponentDataSourceServiceConfigurations {
    componentDataSourceId
    dataSourceServiceConfigurationId
    idServiceConfigurationType
    key
    value
    description
    fcr
    ucr
    uum
    fum
  }
  dataSource{
      id,
      name,
      description,
      idDataSourceType  
      ,
        iapDataSourceDataBase{
          id
          dataSourceId
          idBaseDatos
          idDataBaseType
          querySelect
          queryFrom
          queryWhere
          queryOrderBy
          queryGroupBy
          queryHaving
          filterDistinct
          fcr
          ucr
          uum
          fum
        },
        iapDataSourceService{
          dataSourceId
          idHttpMethodType
          methodName
          type
          idAuthType
          url
          fileJson
          host
          fcr
          ucr
          uum
          fum         
        },
        iapDataSourceServiceConfigurations {
          id,
          dataSourceId,
          idServiceConfigurationType,
          key,            
          value,
          description,
          fcr,
          ucr
        },
       iapDataSourceFields{
          id,
          dataSourceId,
          dataSourceTableAliasId
          tableName,
          field,
          tableNameAlias,
          fieldAlias,
          shortDescription,
          description,
          dataSourceLookUpId,
          iapObjectDataSourceFieldRelationDataSourceFieldIdFromNavigations{
            objetoId,
            idObjeto,
            dataSourceFieldIdFrom,
            dataSourceFieldIdTo
          },
          iapObjectDataSourceFieldRelationDataSourceFieldIdToNavigations{
            objetoId,
            idObjeto,
            dataSourceFieldIdFrom,
            dataSourceFieldIdTo
          }
       
  }
  }
            
      }
    }
  }
`);

export const GET_ALL_COMPONENTDATASOURCE = gql(`
query($maxregs: Int) {
    componentDataSourceQuery {
      getAll(maxregs:$maxregs) {
  id,          
  dataSourceId,
  componentId,
  name,
  dataSource{
      id,
      name,
      
        iapDataSourceDataBase{
          id
          dataSourceId
          idBaseDatos
          idDataBaseType
          querySelect
          queryFrom
          queryWhere
          queryOrderBy
          queryGroupBy
          queryHaving
          filterDistinct
          fcr
          ucr
          uum
          fum
        },
        iapDataSourceService{
          dataSourceId
          idHttpMethodType
          idAuthType
          url
          fileJson
          fcr
          ucr
          uum
          fum         
        },
        iapDataSourceServiceConfigurations {
          id,
          dataSourceId,
          idServiceConfigurationType,
          key,            
          value,
          description,
          fcr,
          ucr
        },
       iapDataSourceFields{
     id,
     dataSourceId,
     dataSourceTableAliasId
      tableName,
      field,
      tableNameAlias,
        fieldAlias,
      shortDescription,
      description,
      dataSourceLookUpId
  }
     
  }
            
      }
    }
}
`);



export const GET_ALL_ASCENDANCYFROMCOMPONENT = gql(`
query($componentId: Int!) {
    componentDataSourceQuery {
      getAllAscendancyFromComponent(componentId:$componentId) {
  id,          
  dataSourceId,
  componentId,
  name,
  dataSource{
      id,
      name,
      iapDataSourceServiceConfigurations {
          id,
          dataSourceId,
          idServiceConfigurationType,
          key,            
          value,
          description,
          fcr,
          ucr
        },
       iapDataSourceFields{
          id,
          dataSourceId,
          tableName,
          field,
          dataSourceLookUpId,
          tableNameAlias,
        fieldAlias,
      
  }
     
  }
            
      }
    }
}
`);