import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex justify-content-center" }
const _hoisted_2 = { class: "font-bold" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "card m-2" }
const _hoisted_10 = { class: "grid" }
const _hoisted_11 = { class: "p-float-label mt-3" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "p-error" }
const _hoisted_14 = {
  key: 0,
  class: "col-12 lg:col-6"
}
const _hoisted_15 = { class: "p-float-label mt-3" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { class: "p-error" }
const _hoisted_18 = {
  key: 1,
  class: "col-12"
}
const _hoisted_19 = { class: "p-float-label mt-5" }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = { class: "p-error" }
const _hoisted_22 = { class: "flex justify-content-end mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_SaveSearch_UserProfile = _resolveComponent("SaveSearch_UserProfile")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_card = _resolveComponent("card")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DataTable, {
      value: _ctx.dataSearch,
      paginator: true,
      rows: 5,
      resizableColumns: "",
      columnResizeMode: "fit",
      style: {"width":"100%"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "name",
          header: "Nombre",
          sortable: ""
        }, {
          body: _withCtx((slotProps) => [
            _createVNode(_component_Button, {
              label: _ctx.getDesc(slotProps.data),
              link: "",
              onClick: ($event: any) => (_ctx.setSearch(slotProps.data))
            }, null, 8 /* PROPS */, ["label", "onClick"])
          ]),
          _: 1 /* STABLE */
        }),
        (_ctx.searchType==_ctx.SearchConfigTypeConst.SEARCHCONFIGTYPE_LIST)
          ? (_openBlock(), _createBlock(_component_Column, {
              key: 0,
              field: "data",
              header: "Diferencias",
              style: { 'text-align': 'center' }
            }, {
              body: _withCtx((slotProps) => [
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.getDesc(slotProps.data,true)), 1 /* TEXT */),
                (!_ctx.HelperCommon.isNullOrWhitespace(_ctx.getItemsDifference(slotProps.data)))
                  ? _withDirectives((_openBlock(), _createElementBlock("i", {
                      key: 0,
                      class: "pi pi-exclamation-circle text-red-500 ml-2",
                      onClick: ($event: any) => (_ctx.openDifferenceOverLayPanel($event, slotProps.data))
                    }, null, 8 /* PROPS */, _hoisted_3)), [
                      [_directive_tooltip, 'Ver diferencias']
                    ])
                  : _createCommentVNode("v-if", true),
                _createVNode(_component_OverlayPanel, {
                  ref: "differenceOverLayPanel",
                  dismissable: false,
                  showCloseIcon: true
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", {
                      innerHTML: _ctx.getItemsDifference(_ctx.dataDifOverLayPanel)
                    }, null, 8 /* PROPS */, _hoisted_4)
                  ]),
                  _: 1 /* STABLE */
                }, 512 /* NEED_PATCH */)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        _createVNode(_component_Column, {
          field: "data",
          header: "Criterios",
          style: { 'text-align': 'center' }
        }, {
          body: _withCtx((slotProps) => [
            _withDirectives(_createElementVNode("i", {
              class: "pi pi-question-circle text-color-secondary",
              onClick: ($event: any) => (_ctx.openColOverLayPanel($event, slotProps.data))
            }, null, 8 /* PROPS */, _hoisted_5), [
              [_directive_tooltip, 'Ver criterios']
            ]),
            _createVNode(_component_OverlayPanel, {
              ref: "colOverLayPanel",
              dismissable: false,
              showCloseIcon: true
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", {
                  innerHTML: _ctx.formatData(_ctx.dataOverLayPanel)
                }, null, 8 /* PROPS */, _hoisted_6)
              ]),
              _: 1 /* STABLE */
            }, 512 /* NEED_PATCH */)
          ]),
          _: 1 /* STABLE */
        }),
        (_ctx.searchType==_ctx.SearchConfigTypeConst.SEARCHCONFIGTYPE_LIST)
          ? (_openBlock(), _createBlock(_component_Column, { key: 1 }, {
              body: _withCtx(({ data }) => [
                (data.userId==data.ucr)
                  ? _withDirectives((_openBlock(), _createElementBlock("i", {
                      key: 0,
                      class: "pi pi-file-edit",
                      onClick: ($event: any) => (_ctx.openSaveSearch($event,data,true))
                    }, null, 8 /* PROPS */, _hoisted_7)), [
                      [_directive_tooltip, 'Editar']
                    ])
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        _createVNode(_component_Column, null, {
          body: _withCtx(({ data }) => [
            (data.userId==data.ucr)
              ? _withDirectives((_openBlock(), _createElementBlock("i", {
                  key: 0,
                  class: "pi pi-trash",
                  style: {"color":"#D32F2F"},
                  onClick: ($event: any) => (_ctx.deleteSearch(data))
                }, null, 8 /* PROPS */, _hoisted_8)), [
                  [_directive_tooltip, 'Eliminar registro']
                ])
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }),
        (_ctx.searchType!=_ctx.SearchConfigTypeConst.SEARCHCONFIGTYPE_NCUS)
          ? (_openBlock(), _createBlock(_component_Column, { key: 2 }, {
              header: _withCtx(() => [
                (_ctx.addVisible)
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 0,
                      icon: "pi pi-plus",
                      rounded: "",
                      raised: "",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openSaveSearch($event,_ctx.data,false)))
                    }))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["value"]),
    _createVNode(_component_OverlayPanel, {
      ref: "opSaveSearch",
      dismissable: false,
      showCloseIcon: true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_card, null, {
          title: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.searchType!=_ctx.SearchConfigTypeConst.SEARCHCONFIGTYPE_LIST?'Nueva búsqueda':'Nueva Lista'), 1 /* TEXT */)
          ]),
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", {
                  class: _normalizeClass({'col-12 lg:col-6':_ctx.searchType==_ctx.SearchConfigTypeConst.SEARCHCONFIGTYPE_LIST,'col-12':_ctx.searchType!=_ctx.SearchConfigTypeConst.SEARCHCONFIGTYPE_LIST})
                }, [
                  _createElementVNode("span", _hoisted_11, [
                    _createVNode(_component_InputText, {
                      id: "name",
                      modelValue: _ctx.data.name,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.name) = $event)),
                      type: "text",
                      class: _normalizeClass({ 'p-invalid': _ctx.v$.name.$error && _ctx.submitted,'w-full':true }),
                      "aria-describedby": "text-error"
                    }, null, 8 /* PROPS */, ["modelValue", "class"]),
                    _createElementVNode("label", {
                      for: "name",
                      class: _normalizeClass({ 'p-invalid': _ctx.v$.name.$error && _ctx.submitted, 'customrequired': true })
                    }, "Nombre", 2 /* CLASS */)
                  ]),
                  (_ctx.submitted)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.name.$errors, (error) => {
                          return (_openBlock(), _createElementBlock("span", {
                            key: error.$uid
                          }, [
                            _createElementVNode("small", _hoisted_13, _toDisplayString(error.$message), 1 /* TEXT */)
                          ]))
                        }), 128 /* KEYED_FRAGMENT */))
                      ]))
                    : _createCommentVNode("v-if", true)
                ], 2 /* CLASS */),
                (_ctx.searchType==_ctx.SearchConfigTypeConst.SEARCHCONFIGTYPE_LIST)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createElementVNode("span", _hoisted_15, [
                        _createVNode(_component_Dropdown, {
                          id: "fieldlist",
                          modelValue: _ctx.fieldList,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.fieldList) = $event)),
                          options: _ctx.fieldsListOptions,
                          optionLabel: "name",
                          optionValue: "id",
                          class: _normalizeClass({ 'p-invalid': _ctx.v$.fieldList.$error && _ctx.submitted }),
                          placeholder: "Seleccione campo",
                          filter: "",
                          filterInputProps: _ctx.filterInputProperties,
                          onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.stopPropagateEventDropDown($event)))
                        }, null, 8 /* PROPS */, ["modelValue", "options", "class", "filterInputProps"]),
                        _createElementVNode("span", null, [
                          _withDirectives(_createElementVNode("i", {
                            class: "pi pi-download ml-1 text-primary",
                            style: {"cursor":"pointer"},
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.doRecoverValues()))
                          }, null, 512 /* NEED_PATCH */), [
                            [_directive_tooltip, 'Descargar valores de la búsqueda']
                          ])
                        ]),
                        _createElementVNode("label", {
                          for: "fieldlist",
                          class: _normalizeClass({ 'p-invalid': _ctx.v$.fieldList.$error && _ctx.submitted, 'customrequired': true })
                        }, "Campo", 2 /* CLASS */)
                      ]),
                      (_ctx.submitted)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_16, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.fieldList.$errors, (error) => {
                              return (_openBlock(), _createElementBlock("span", {
                                key: error.$uid
                              }, [
                                _createElementVNode("small", _hoisted_17, _toDisplayString(error.$message), 1 /* TEXT */)
                              ]))
                            }), 128 /* KEYED_FRAGMENT */))
                          ]))
                        : _createCommentVNode("v-if", true)
                    ]))
                  : _createCommentVNode("v-if", true),
                _createVNode(_component_SaveSearch_UserProfile, {
                  value: _ctx.userProfileData,
                  catalogosData: _ctx.catalogosData,
                  searchConfigAll: _ctx.searchConfigAll,
                  searchConfig: _ctx.data,
                  isUpdate: _ctx.formUpdate,
                  "onUpdate:selectionKeys": _ctx.onUpdateSelectionKeys
                }, null, 8 /* PROPS */, ["value", "catalogosData", "searchConfigAll", "searchConfig", "isUpdate", "onUpdate:selectionKeys"]),
                (_ctx.searchType==_ctx.SearchConfigTypeConst.SEARCHCONFIGTYPE_LIST)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                      _createElementVNode("span", _hoisted_19, [
                        _createVNode(_component_Textarea, {
                          id: "values",
                          modelValue: _ctx.valuesList,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.valuesList) = $event)),
                          rows: "5",
                          class: _normalizeClass({ 'p-invalid': _ctx.v$.valuesList.$error && _ctx.submitted }),
                          "aria-describedby": "text-error",
                          style: {"width":"95%"}
                        }, null, 8 /* PROPS */, ["modelValue", "class"]),
                        _createElementVNode("label", {
                          for: "values",
                          class: _normalizeClass({ 'p-invalid': _ctx.v$.valuesList.$error && _ctx.submitted, 'customrequired': true })
                        }, "Valores", 2 /* CLASS */)
                      ]),
                      (_ctx.submitted)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_20, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.valuesList.$errors, (error) => {
                              return (_openBlock(), _createElementBlock("span", {
                                key: error.$uid
                              }, [
                                _createElementVNode("small", _hoisted_21, _toDisplayString(error.$message), 1 /* TEXT */)
                              ]))
                            }), 128 /* KEYED_FRAGMENT */))
                          ]))
                        : _createCommentVNode("v-if", true)
                    ]))
                  : _createCommentVNode("v-if", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_22, [
              _createVNode(_component_Button, {
                label: _ctx.formUpdate?'Actualizar':'Añadir',
                class: "mr-1",
                onClick: _ctx.executeOperation
              }, null, 8 /* PROPS */, ["label", "onClick"]),
              _createVNode(_component_Button, {
                label: "Cancelar",
                severity: "danger",
                onClick: _ctx.hideSaveSearch
              }, null, 8 /* PROPS */, ["onClick"])
            ])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 512 /* NEED_PATCH */)
  ]))
}