import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';
import { ISearchConfigRepository } from '../domain/ISearchConfigRepository';


import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import { IampSearchConfig } from '../domain/iapmSearchConfig';
import { GETBYUSERID } from './query/searchConfigQuery';
import { ADD, DELETE, UPDATE } from './mutation/searchConfigMutation';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';



@injectable()
export class SearchConfigApolloClientRepository implements ISearchConfigRepository {
  private apolloClient : MyApolloClient
  private clientId: String
  constructor (apolloInstance: MyApolloClient, clientId = ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }
     
 

  async update (dataInput: IampSearchConfig,dataToUpdate:IampSearchConfig[]):(Promise<IampSearchConfig[]>) {
    return new Promise<IampSearchConfig[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(UPDATE,{data:dataInput,dataToUpdate:dataToUpdate}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.searchConfigMutation.updateSearchConfig)
        }
        catch(e) {
          reject()
        }
      });

    });
  }

  async add (dataInput: IampSearchConfig): (Promise<IampSearchConfig>) {
    return new Promise<IampSearchConfig>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(ADD,{data:dataInput}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.searchConfigMutation.addSearchConfig)
        }
        catch(e) {
          reject()
        }
      });

    });
  }


  async delete (id: number): (Promise<boolean>) {
    return new Promise<boolean>(async (resolve, reject) => {
     
        const result = this.apolloClient.executeQuery(DELETE,{id: id}, this.clientId)    

      watch(result, (value: any) => {
        
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.searchConfigMutation.deleteSearchConfig)
        }
        catch(e) {
          reject()
        }
      });
    
    });
  }


  async getByAllByUserId (userId: number, applicationId: number, applicationVersion: number, componentId: number,searchType?:string): (Promise<IampSearchConfig[]>) {
    return new Promise<IampSearchConfig[]>(async (resolve, reject) => {
      const result = this.apolloClient.executeQuery(GETBYUSERID, { userId: userId, applicationId: applicationId, applicationVersion: applicationVersion, componentId: componentId,searchType:searchType },this.clientId)
      
      watch(result, (value: any) => {
        try {
          HelperUtils.handleDatesApollo(value) 
          resolve(value.searchConfigQuery.getByAllByUserId)
        }
        catch(e) {
          reject()
        }
      });
    });
  }




}


