import HelperUtils from "@ilinium/shared/src/common/infrastructure/funciones/HelperUtils";
import ComponentDataForm from "../../../designer/domain/ComponentDataForm";
import ControlTypeConst from "../../../form/domain/Constants/ControlTypeConst";
import ComponentHelperRender from "../../../form/domain/Functions/ComponentHelperRender";
import BaseControlTypeConst from "../../../form/domain/Constants/BaseControlTypeConst";
import { IapDataSourceField } from "../../../datasource/domain/iapDataSourceField";
import DataSourceUtilComp from "./dataSourceUtilComp";
import DropDownTypeConst from "../../../form/domain/Constants/DropDownTypeConst";
import FiltroBusquedaConst from "@ilinium/shared/src/common/domain/constantes/FiltroBusquedaConst";
import HelperCommon from "@ilinium/shared/src/common/infrastructure/funciones/HelperCommon";
import SqlTypesConst from "@ilinium/shared/src/common/domain/constantes/SqlTypesConst";
import { InteractionEvent } from "../../../interaction/domain/interactionEvent";
import InteractionConst from "../../../interaction/domain/interactionConst";
import EventConst from "@ilinium/shared/src/common/domain/constantes/EventConst";
import EventBusCustom from '@ilinium/shared/src/common/infrastructure/event-bus-custom';
import SearchControlTypeConst from "../../../form/domain/Constants/SearchControlTypeConst";

export default function searchFormHelper(

    fieldsData: IapDataSourceField[],
    store: any,
    dataFilter: any,
    refSearchForm?: any,
    comps?: ComponentDataForm[],


) {

    const { getPropertyValue } = ComponentHelperRender({} as any, undefined, store as any);
    const { initFilterField, isType } = DataSourceUtilComp({} as any, {} as any, {} as any)

    const getFormDataFilter = (comps: ComponentDataForm[]): void => {

        comps.filter(c => (ControlTypeConst.VALIDABLE_CONTROLS.includes(c.idType) || c.idType== ControlTypeConst.CTSEARCHCONTROL) && getFieldIdFromModel(c)).forEach(comp => {

            const fieldId = getFieldIdFromModel(comp) as any;

            if (!isFilterIncluded(fieldId)) {
                //@ts-ignore:disable-next-line
                dataFilter.fields.push(
                    {                        
                        //@ts-ignore:disable-next-line
                        id: HelperUtils.newGuid().toString().replaceAll('-', ''),
                        fieldId: fieldId,
                        rangeDateTime: {
                            from: null,
                            to: null
                        },
                        rangeValue: {
                            from: null,
                            to: null
                        },
                        filter: initialFilter(getFieldIdFromModel(comp) as any),
                        value: undefined,
                        valueList: null
                    }
                )
            }


        })

    };

    const isFilterIncluded = (fieldId: string): boolean => {
        return dataFilter.fields.some((x:any) => x.fieldId == fieldId)
    }

    const getFieldIdFromModel = (comp: ComponentDataForm): string | undefined => {
        const mv = getPropertyValue(BaseControlTypeConst.MODELVALUE, comp);
        if (!mv) return
        const arrMv = mv?.split('#');
        return arrMv && arrMv.length > 1 ? arrMv[0] : undefined;
    };

    const initialFilter = (fieldId: string): string => {
        const dsField = fieldsData.find(x => x.id == fieldId);
        return initFilterField(dsField as any);
    }

    const updateFilter = (vmodel: any, component: ComponentDataForm) => {
        const fieldId = getFieldId(component)
        const index = dataFilter.fields.findIndex((x:any) => x.fieldId == fieldId);

        if (index > -1) {
            if (HelperCommon.isNullUndefinedOrEmpty(vmodel)) {
                dataFilter.fields[index].value = null;
                dataFilter.fields[index].valueList = null;
                dataFilter.fields[index].rangeDateTime.from=null
                dataFilter.fields[index].rangeDateTime.to=null
            }
            else {
                switch (getCompType(component)) {
                    case ControlTypeConst.CTINPUTTEXT:
                        dataFilter.fields[index].value = vmodel;
                        break;
                    case ControlTypeConst.CTINPUTSWITCH:
                        dataFilter.fields[index].value = vmodel ? '1' : '0';
                        break;
                    case ControlTypeConst.CTTRISTATECHECKBOX:
                        dataFilter.fields[index].value = vmodel ? '1' : '0';
                        break;
                    case ControlTypeConst.CTLOOKUPEDITOR:
                        if (Array.isArray(vmodel) && vmodel[0]?.value) dataFilter.fields[index].value = vmodel
                        break;
                    case ControlTypeConst.CTMULTISELECT:
                        dataFilter.fields[index].valueList = (!Array.isArray(vmodel))? vmodel.split(','):vmodel;
                        break;
                    case ControlTypeConst.CTINPUTNUMBER:
                        if (!isDateAgoInput(component)) {
                            dataFilter.fields[index].value = vmodel?.toString();
                        }
                        else {
                            dataFilter.fields[index].valueNumber = vmodel;
                            handleDates(vmodel, index, dataFilter.fields[index].filter, true);
                        }
                        break;
                    case ControlTypeConst.CTDROPDOWN:
                        if (!isFilter(component)) {
                            dataFilter.fields[index].valueList = [vmodel];
                        } else {
                            if (vmodel) dataFilter.fields[index].filter = vmodel;
                            if (isDateFilter(component)) {
                                handleDates(vmodel, index, dataFilter.fields[index].filter, true);
                            }
                        }
                        break;
                    case ControlTypeConst.CTCALENDAR:
                        handleDates(vmodel, index, dataFilter.fields[index].filter);
                        break;
                    default:
                        break;
                }
            }


        }


    }

    const getCompType=(component:ComponentDataForm):string | null | undefined=>{
        if(component.idType!=ControlTypeConst.CTSEARCHCONTROL) return component.idType;
        return  getPropertyValue(SearchControlTypeConst.INPUTTYPE, component);
    }

    const handleDates = (vmodel: any, index: number, filter: string, autogenerate: boolean = false) => {

        if (!autogenerate) {
            const fromDates: string[] = [FiltroBusquedaConst.FILTROBUSQUEDA_IGUAL, FiltroBusquedaConst.FILTROBUSQUEDA_DISTINTO, FiltroBusquedaConst.FILTROBUSQUEDA_GREATTHAN, FiltroBusquedaConst.FILTROBUSQUEDA_GREATTHANEQTO,
            FiltroBusquedaConst.FILTROBUSQUEDA_LESSTHAN, FiltroBusquedaConst.FILTROBUSQUEDA_LESSTHANEQTO
            ]

            if (fromDates.includes(filter)) {
                dataFilter.fields[index].rangeDateTime.from = vmodel;
            }
            else if (filter == FiltroBusquedaConst.FILTROBUSQUEDA_ENTRE) {
                if (vmodel && Array.isArray(vmodel)) {
                    dataFilter.fields[index].rangeDateTime.from = vmodel[0]
                    dataFilter.fields[index].rangeDateTime.to = vmodel[1]
                }
            }

            return;
        }

        let dateFrom: Date | null = null;
        let dateTo: Date | null = null;
        let changeDate: boolean = false;

        if (filter == FiltroBusquedaConst.FILTROBUSQUEDA_CURRENTMONTH) {
            const currMonthDates = HelperCommon.currentMonthDates();
            dateFrom = currMonthDates.first;
            dateTo = currMonthDates.last;
            changeDate = true;
        }

        if (filter == FiltroBusquedaConst.FILTROBUSQUEDA_PREVIOUSMONTH) {
            const prevMonthDates = HelperCommon.previousMonthDates();
            dateFrom = prevMonthDates.first;
            dateTo = prevMonthDates.last;
            changeDate = true;
        }

        if (filter == FiltroBusquedaConst.FILTROBUSQUEDA_CURRENTYEAR) {

            const currYearDates = HelperCommon.currentYearDates();
            dateFrom = currYearDates.first;
            dateTo = currYearDates.last;
            changeDate = true;
        }

        if (filter == FiltroBusquedaConst.FILTROBUSQUEDA_PREVIOUSYEAR) {
            const prevYearDates = HelperCommon.previousYearDates();
            dateFrom = prevYearDates.first;
            dateTo = prevYearDates.last;
            changeDate = true;
        }

        if (filter == FiltroBusquedaConst.FILTROBUSQUEDA_CURRENTWEEK) {
            const currWeekDates = HelperCommon.currentWeekDates();
            dateFrom = currWeekDates.first;
            dateTo = currWeekDates.last;
            changeDate = true;
        }

        if (filter == FiltroBusquedaConst.FILTROBUSQUEDA_PREVIOUSWEEK) {
            const prevWeekDates = HelperCommon.previousWeekDates();
            dateFrom = prevWeekDates.first;
            dateTo = prevWeekDates.last;
            changeDate = true;
        }


        if (filter == FiltroBusquedaConst.FILTROBUSQUEDA_DAYSAGO) {
            if (dataFilter.fields[index]?.valueNumber) {
                const daysAgo = HelperCommon.daysAgoDates(dataFilter.fields[index]?.valueNumber);
                dateFrom = daysAgo.first;
                dateTo = daysAgo.last;
            }
            changeDate = true;
        }


        if (changeDate) {
            dataFilter.fields[index].rangeDateTime.from = dateFrom;
            dataFilter.fields[index].rangeDateTime.to = dateTo;
        }


    }


    const getFieldId = (component: ComponentDataForm | undefined): string | undefined => {
        if (!component) return;
        return isFilter(component) || isDateAgoInput(component) ? getFilterFieldId(component) : getFieldIdFromModel(component);

    }


    const isFilter = (component: ComponentDataForm): boolean => {
        if (component.idType !== ControlTypeConst.CTDROPDOWN) return false;

        const dataVal = getPropertyValue(DropDownTypeConst.DATAVALUE, component);

        return fieldsData.some(fieldData => fieldData.field.toLowerCase() === dataVal?.toLocaleLowerCase());
    };


    const isDateAgoInput = (component: ComponentDataForm): boolean => {
        if (component.idType !== ControlTypeConst.CTINPUTNUMBER) return false;
        return isDateFilter(component);
    };


    const isDateFilter = (component: ComponentDataForm): boolean => {
        const dataVal = getPropertyValue(DropDownTypeConst.DATAVALUE, component);

        const field = fieldsData.find(({ field }) => field.toLowerCase() === dataVal?.toLocaleLowerCase());
        if (!field) return false;

        return isType(field, SqlTypesConst.DATETIME) || isType(field, SqlTypesConst.DATE);
    };

    const getFilterFieldId = (component: ComponentDataForm): string | undefined => {

        const dataValue = getPropertyValue(DropDownTypeConst.DATAVALUE, component);

        if (!dataValue) return;

        const matchingField = fieldsData.find(field => field.field.toLowerCase() === dataValue.toLowerCase());

        return matchingField?.id;
    };

    const loadSavedSearch = (): void => {
        const _comps = comps?.filter(c => ((ControlTypeConst.VALIDABLE_CONTROLS.includes(c.idType) || c.idType== ControlTypeConst.CTSEARCHCONTROL) && getFieldId(c)))
        _comps?.forEach(comp => {
            notifyChildrenSearchForm(comp, _comps)
        })
    };



    const notifyChildrenSearchForm = (component: ComponentDataForm, comps?: ComponentDataForm[]): void => {
        if (!component || !comps) return;

        const data: Partial<InteractionEvent> = {
            objectId: component.id.toString(),
            typeId: InteractionConst.SET_VMODEL,
            objectValue: getObjectValue(component),
            objectName: null
        };

        const doCallbackOk = (response: InteractionEvent) => {

        };

        const keyComponentEventBus = `${refSearchForm.formKey}${refSearchForm.componentId?.toString()}${component.id}${EventConst.INTERACTION}`;

        EventBusCustom.emit(keyComponentEventBus, { data, callBackResponse: doCallbackOk });

    };


    const getObjectValue = (comp: ComponentDataForm | undefined): any => {
        if (!comp) return;
        const searchData = getSDFromComp(comp)
        if(!searchData) return null;
        if (isFilter(comp)) {
            return searchData.filter;
        }
        else {

            if(comp.idType ==ControlTypeConst.CTSEARCHCONTROL){
                return searchData;
            }
            if (comp.idType == ControlTypeConst.CTMULTISELECT) {
                return searchData.valueList;
            } else if (comp.idType == ControlTypeConst.CTDROPDOWN) {

                return Array.isArray(searchData.valueList) ? searchData.valueList[0] : searchData.valueList;
            }

            else if (comp.idType == ControlTypeConst.CTINPUTSWITCH || comp.idType == ControlTypeConst.CTTRISTATECHECKBOX) {
                if (searchData.value === '1') return true;
                if (searchData.value === '0') return false;
                return searchData.value;
            }
            else if (comp.idType == ControlTypeConst.CTINPUTNUMBER && searchData.filter == FiltroBusquedaConst.FILTROBUSQUEDA_DAYSAGO) {
                return searchData?.valueNumber;
            } else if (comp.idType == ControlTypeConst.CTCALENDAR) {
                if (searchData.filter == FiltroBusquedaConst.FILTROBUSQUEDA_ENTRE) {
                    return [searchData?.rangeDateTime.from, searchData?.rangeDateTime.to].filter(x => !HelperCommon.isNullOrNotDefined(x))
                }
                else {
                    return searchData?.rangeDateTime.from;
                }
            }

            return searchData.value;
        }

    }

    const getSDFromComp = (comp: ComponentDataForm | undefined): any => {
        const fieldId = getFieldId(comp);
        return dataFilter.fields.find((x:any) => x.fieldId == fieldId)
    }


    return {

        getFormDataFilter,
        updateFilter,
        isFilter,
        loadSavedSearch,
    };
}


