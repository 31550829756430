import { authRequest } from '../domain/authRequest'
import { authResponse } from '../domain/authResponse'
import { IAuthRepository } from '../domain/IAuthRepository'
import { injectable } from 'inversify'
import 'reflect-metadata'
import { http } from '../../../../common/infrastructure/funciones/HelperHttp'
import { IapmUser } from '../../user/domain/iapmUser'
import { appConfigResponse } from '../domain/appConfigResponse'
import { autoAuthRequest } from '../domain/autoAuthRequest'
import { UsuarioDobleAutenticacion } from '../../user/domain/usuarioDobleAutenticacion'
import { dobleAuthenticationRequest } from '../domain/dobleAuthenticationRequest'


@injectable()
export class AuthAxiosRepository implements IAuthRepository {
  constructor (urlBase: string) {
    http.initHttp(urlBase)
  }
  async authMicrosoft (request: authRequest): Promise<authResponse> {
    return (await http.post('/user/authMicrosoft', request))
  }

  async authGoogle (request: authRequest): Promise<authResponse> {
    return (await http.post('/user/authGoogle', request))
  }
  async auth (request: authRequest): Promise<authResponse> {
    return (await http.post('/token/auth', request))
  }
  async autoAuth (request: autoAuthRequest): Promise<authResponse> {
    return (await http.post('/token/autoAuth', request))
  }
  async logout () : Promise<boolean> {
    return (await http.post('/user/logout/'))
  }
  async getVersion () : Promise<string> {
    return (await http.get('/version/getVersion/'))
  }
  async whoAmI (): (Promise<IapmUser>) {
    return (await http.post('/user/whoAmI/'))
  }
  async whoAmIProfiles (): (Promise<IapmUser>) {
    return (await http.post('/user/whoAmIProfiles/'))
  }
  async resetPass (request: authRequest): (Promise<IapmUser>) {
    return (await http.post('/user/resetPass/', request))
  }

  async setPassword (request: authRequest): (Promise<boolean>) {
    return (await http.post('/user/setPassword/', request))
  }

  async setMicrosoftAccount (request: authRequest): (Promise<boolean>) {
    return (await http.post('/user/setMicrosoftAccount/', request))
  }

  async setGoogleAccount (request: authRequest): (Promise<boolean>) {
    return (await http.post('/user/setGoogleAccount/', request))
  }

  async setPasswordManual (request: authRequest): (Promise<boolean>) {
    return (await http.post('/user/setPasswordManual/', request))
  }
  async getAppConfig (applicationId: number, applicationVersion: number): (Promise<appConfigResponse>) {
    return (await http.get('/version/getAppConfig/'+ applicationId + '/' + applicationVersion))
  }
  
  async decryptApiAuth (request: authRequest): (Promise<string>) {
    return (await http.post('/user/decryptApiAuth/', request))
  }


  //Métodos doble autenticación
  async getDobleFactorUserCode (request: authRequest): (Promise<UsuarioDobleAutenticacion>) {
    return (await http.post('/userdobleauthentication/getDobleFactorUserCode/', request))
  }

  async setDobleFactorUserCode (request: dobleAuthenticationRequest): (Promise<UsuarioDobleAutenticacion>) {
    return (await http.post('/userdobleauthentication/setDobleFactorUserCode/', request))
  }

  async confirmDobleFactorUser (request: dobleAuthenticationRequest): (Promise<UsuarioDobleAutenticacion>) {
    return (await http.post('/userdobleauthentication/confirmDobleFactorUser/', request))
  }
  
  // Métodos de Error
  async logError (request: any): (Promise<boolean>) {
    return (await http.post('/error/logError/', request))
  }

}
