<script setup>
defineProps({
    items: {
        type: Array,
        default: () => []
    },
    activeMailItem: {
        type: String,
        default: () => []
    }
});

const emit = defineEmits(['navigate']);

const navigate = (item) => {
    const targetSection = item.routerLink || 'compose';
    const folderId = item.folderId;
    const groupId = item.groupId;
    const staticFolderId = item.staticFolderId;
    emit('navigate', { targetSection, folderId, groupId, staticFolderId }); // Emitimos el evento
};
</script>

<template>
    <div>
        <Button @click="navigate({ routerLink: 'compose' })" label="Redactar" class="mb-5 w-full" outlined></Button>
        <div class="overflow-auto">
            <ul class="flex flex-row md:flex-column gap-1 md:gap-2 list-none m-0 p-0 overflow-auto">
                <li
                    v-for="item in items"
                    :key="item.id"
                    :style="{ 'margin-left': (15 * item.childLevel) + 'px' }"
                    class="cursor-pointer select-none p-3 transition-duration-150 border-round flex align-items-center justify-content-center md:justify-content-start md:flex-1 flex-auto"
                    :class="{ 'bg-primary': activeMailItem === item.routerLink || activeMailItem === item.folderId || activeMailItem === item.groupId, 'hover:surface-hover': activeMailItem !== item.routerLink && activeMailItem !== item.folderId && activeMailItem !== item.groupId }"
                    @click="navigate(item)"
                >
                    <i v-if="item.childFolderCount > 0" :class="item.displayed == 0 ? 'pi pi-angle-right' : 'pi pi-angle-down'" style="margin-left: -10px;"></i>
                    <i class="md:mr-3 text-600 transition-duration-150 text-lg" :class="[item.icon, { 'text-primary-50': activeMailItem === item.routerLink || activeMailItem === item.folderId || activeMailItem === item.groupId }]"></i>
                    <span class="text-900 font-medium hidden md:inline" :class="{ 'text-primary-50': activeMailItem === item.routerLink || activeMailItem === item.folderId || activeMailItem === item.groupId }">{{ item.label }}</span>
                    <!--<span v-if="item.badge" class="ml-auto text-sm font-semibold bg-primary-50 text-primary-900 px-2 py-1 hidden md:inline text-center" style="border-radius: 2rem; width: 23px; height: 23px">{{ item.badge }}</span>-->
                </li>
            </ul>
        </div>
    </div>
</template>