<SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG, Component)" :data="Component"
    :vmodel="vmodel" />
<div :class="{ 'p-inputgroup flex-1': hasGroupControls }" :key="componentKey">
    <InputText
        v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE,Component) && canDoOperation(ObjectGroupConst.VIEW,Component)"
        :id="getPropertyValue(InputTextTypeConst.ID,Component)" v-tooltip="getPropertyValue(InputTextTypeConst.TOOLTIP,Component)"
        :style="getPropertyValue(InputTextTypeConst.STYLE,Component)"
        :class="{ ...getCompClass(Component), ...{'p-invalid': v$.vmodel.$error && submitted, 'p-readonly': getPropertyBooleanValue(InputTextTypeConst.READONLY,Component) } }"
        :name="getPropertyValue(InputTextTypeConst.NAME,Component)"
        :disabled="getPropertyBooleanValue(InputTextTypeConst.DISABLED,Component) || (!canEdit)"
        :placeholder="getPropertyValue(InputTextTypeConst.PLACEHOLDER,Component)"
        :readonly="getPropertyBooleanValue(InputTextTypeConst.READONLY,Component) || !canDoOperation(ObjectGroupConst.EDIT,Component)"
         v-model="vmodel"/>
    <span v-if="hasGroupControls && getPropertyBooleanValue(InputTextTypeConst.MIC,Component)"
        :class="{ 'p-inputgroup-addon': hasGroupControls }">
        <Mic v-if="getPropertyBooleanValue(InputTextTypeConst.MIC,Component)" v-model="vmodel"></Mic>
    </span>
    <span v-if="getItems.length > 1" class="p-inputgroup-addon" @click="toggle">
        <i class="pi pi-ellipsis-h"></i>
    </span>

</div>
<CustomValidate
    v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE,Component) && canDoOperation(ObjectGroupConst.VIEW,Component)"
    v-model:submitted="submitted" v-model:vObject="v$" />

<ContextMenu ref="menu" id="overlay_menu" :model="getItems" :popup="true">

</ContextMenu>