import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["id", "name", "for"]

export function render(_ctx, _cache) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE,_ctx.Component) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW,_ctx.Component))
      ? _withDirectives((_openBlock(), _createElementBlock("label", {
          key: _ctx.componentKey,
          id: _ctx.getPropertyValue(_ctx.LabelTypeConst.ID,_ctx.Component),
          style: _normalizeStyle(_ctx.getPropertyValue(_ctx.LabelTypeConst.STYLE,_ctx.Component)),
          class: _normalizeClass(_ctx.getCompClass(_ctx.Component)),
          name: _ctx.getPropertyValue(_ctx.LabelTypeConst.NAME,_ctx.Component),
          for: _ctx.getPropertyValue(_ctx.LabelTypeConst.FOR,_ctx.Component)
        }, [
          _createTextVNode(_toDisplayString(_ctx.getPropertyValue(_ctx.LabelTypeConst.TEXT,_ctx.Component)), 1 /* TEXT */)
        ], 14 /* CLASS, STYLE, PROPS */, _hoisted_1)), [
          [_directive_tooltip, _ctx.getPropertyValue(_ctx.LabelTypeConst.TOOLTIP,_ctx.Component)]
        ])
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}