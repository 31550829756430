import { MessageService } from '@ilinium/shared/src/common/infrastructure/servicios';
import { IapComponent } from '../../../component/domain/iapComponent';
import ComponentRenderAux from '../ComponentRenderAux'
import { MessageType } from '@ilinium/shared/src/common/infrastructure/servicios/MessageService';
import PrefixControlConst from '../Constants/PrefixControlConst';
import ControlTypeConst from '../Constants/ControlTypeConst';
import CatalogTypeConst from '@ilinium/shared/src/entidades/builder/catalog/domain/const/CatalogTypeConst';
import HelperCommon from '@ilinium/shared/src/common/infrastructure/funciones/HelperCommon';
import CrudTableTypeConst from '../Constants/CrudTableTypeConst';
import ComponentHelperRender from './ComponentHelperRender';
import ComponentDataForm from '../../../designer/domain/ComponentDataForm';
import FunctionNameConst from '../Constants/FunctionNameConst';
import { IapComponentAttribute } from '../../../component/domain/iapComponentAttribute';
import CatalogObjectTypeConst from '../../../catalog/domain/const/CatalogObjectTypeConst';
import { IapComponentAttributeEvent } from '../../../component/domain/iapComponentAttributeEvent';
import HtmlTypeConst from '../Constants/HtmlTypeConst';
import BaseControlTypeConst from '../Constants/BaseControlTypeConst';
import HelperUtils from '@ilinium/shared/src/common/infrastructure/funciones/HelperUtils';


export default function ComponentRender(store:any) {

  const { slotData,
    controlContent,
    getValueName,getVueFileImports,getVueFileCode,crudTablCond,specialControlContent,controlsNotDynamic } = ComponentRenderAux(store); 

    const { hasExpressions,isInExpression,getPropertyValue,getPropertyBooleanValue,getCompClass,isTemplate,getCatalogValue } = ComponentHelperRender({} as any,undefined,store); 

    let allComps:IapComponent[]=[];

    

  const getComponentCode = (component: IapComponent,index:number, close: boolean = false, prefix: string[] = [],isChildTempData:boolean=false,tabPanelProps?: { tabId: number, tabViewParentId: number },onlyDynamic:boolean=true): string => {
    const template = isTemplate(component as any);
    const controlName = !template ? getControlDynamicName(component) : undefined;
    const nativeName= getValueName(CatalogTypeConst.TIPOCONTROL,component.idType,false);

      if (close) {
        // if(component.idType==ControlTypeConst.CTINPUTTEXT){
        //   return ``;
        // }
        // else{
          return template ? `</template>` : controlsNotDynamic.includes(component.idType) && !onlyDynamic?`</${nativeName}>`:`</${controlName}>`;
        // }
       
      }

    if (template) {
      const slotName = getSlotName(component);
      return `<template ${getSlotConditions(component)} v-slot:${slotData.includes(component.idType) ? slotName + '="data"' : slotName}>`;
    }

    if(controlsNotDynamic.includes(component.idType) && !onlyDynamic){
      return getNotDynCompCode(component,index,controlName??'',nativeName??'');
    }
    else{
      return `<${controlName} ${getIfCondition(component,index,tabPanelProps)}  ${getPrefix(prefix,false)}container="container" ${getPrefix(prefix,true)}Component="comps[${index}]" ${getPrefix(prefix,false)}slotProps="${isChildTempData?'data':'slotProps'}" ${getCustomProps(component,prefix)}>`;
    }
     
  }

  const getIfCondition=(component:any,index:number,tabPanelProps?: { tabId: number,tabViewParentId: number })=>{

      if(tabPanelProps){
        // return `v-if="${tabPanelProps?.tabId}==getPropertyNumberValue(TabViewTypeConst.ACTIVEINDEX,  comps.find(c=>c.id==${tabPanelProps?.tabViewParentId}))"`
        return `v-if="${tabPanelProps?.tabId}==compIdIndexTab(getPropertyNumberValue(TabViewTypeConst.ACTIVEINDEX,  comps.find(c=>c.id==${tabPanelProps?.tabViewParentId})),comps.filter(c=>c.parentId==${tabPanelProps?.tabViewParentId}))"`
      }
      if(component.idType == ControlTypeConst.CTTABPANEL){
        return `v-if="isVisibleTabPanel(comps[${index}])"`;
      }
      return ``;
  }  

  const getPrefix=(prefix: string[] = [],vModelProp:boolean)=>{
        if(vModelProp){
          return `${prefix.includes(PrefixControlConst.PREFIX_V_MODEL) ? PrefixControlConst.PREFIX_V_MODEL : ""}${prefix.includes(PrefixControlConst.PREFIX_PROP) ? PrefixControlConst.PREFIX_PROP : ""}`;
        }
        return `${prefix.includes(PrefixControlConst.PREFIX_PROP) ? PrefixControlConst.PREFIX_PROP : ""}`;
  }

  const getCustomProps=(component:IapComponent,prefix:string[]=[]):string=>{
        if(component.idType==ControlTypeConst.CTLOOKUPEDITOR ){
          return ` ${getPrefix(prefix,false)}lookUpComps="lookUpComps"  ${getPrefix(prefix,false)}hasDetail="getLayoutComponent( comps.find(c=>c.id==${component.id}), LookUpEditorTypeConst.LAYOUT_DETAIL) != undefined"`
        }
        else if(component.idType==ControlTypeConst.CTCRUD ){
          return ` ${getPrefix(prefix,false)}customAddNew="getLayoutComponent( comps.find(c=>c.id==${component.id}), CrudTableTypeConst.LAYOUT_ADDNEW) != undefined"`
        }
        return '';
  }

  const getSlotConditions = (component:IapComponent) => {
      if(component.idType==ControlTypeConst.CTCRUD && crudTablCond.includes(component.idType)){
        return ` v-if="!getPropertyBooleanValue(${CrudTableTypeConst.SHOWTABMODE}, comps.find(c=>c.id==${component.id}))"`;
      }
      return '';

  };

  const getControlDynamicName = (component: IapComponent): string | void => {
    const ctrlName = getValueName(CatalogTypeConst.TIPOCONTROL,component.idType);
    if ( ctrlName && !HelperCommon.isNullOrWhitespace(ctrlName)) {
      return ctrlName;
    }
    else {
      MessageService.showMessage(MessageType.Error, 'Error', `Conversión componente id:${component.id} no encontrado`);
    }
  }

  const getSlotName = (component: IapComponent): string | void => {
    const slotName =  getValueName(CatalogTypeConst.LAYOUTTEMP,component.idType);
    if (slotName && !HelperCommon.isNullOrWhitespace(slotName)) {
      return slotName;
    }
    else {
      MessageService.showMessage(MessageType.Error, 'Error', `Conversión slot id:${component.id} no encontrado`);
    }
  }

 
  const printCode = (component: IapComponent,index:number, close: boolean = false, prefix: string[] = [],isChildTempData:boolean=false,tabPanelProps?: { tabId: number, tabViewParentId: number },onlyDynamic:boolean=true): string => {
    return getComponentCode(component,index, close,prefix,isChildTempData,tabPanelProps,onlyDynamic);
  }

  const printSlotContentCode = (component: IapComponent, close: boolean,forceContentSlot:boolean=false,onlyDynamic:boolean=true): string => {
    
    if ((controlContent.includes(component?.idType) && !(controlsNotDynamic.includes(component?.idType) && !onlyDynamic)) || forceContentSlot) {
      return !close ? `<template v-slot:content>` : `</template>`
    }
    return '';
  }

  //En el id hay que pasar inicialmente el del padre absoluto
  const printDynamicCode = (id: number, comps: IapComponent[],result: string, prefix: string[] = [],isChildTempData:boolean=false,tabPanelProps?: { tabId: number, tabViewParentId: number },onlyDynamic:boolean=true): string => {

    if(allComps.length==0){
      allComps=comps;
    }

    const comp = comps.find(c => c.id === id);
    const index = comps.findIndex(c => c.id === id);
    if (comp) {
      //etiqueta inicio        
      result += printCode(comp, index,false, prefix,isChildTempData,tabPanelProps,onlyDynamic);


      const childs = comps.filter(x => x.parentId === comp.id);

      if (childs.length > 0) {
        result += printSlotContentCode(comp, false,false,onlyDynamic);
        childs.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
        result = getChildCode(childs, comp, comps, result, prefix, isChildTempData,onlyDynamic);
        result += printSlotContentCode(comp, true,false,onlyDynamic);
      }
      //etiqueta de cierre
      result += printCode(comp,index, true, prefix,false,undefined,onlyDynamic);
    }
    return result;
  }

  const getChildCode = (compsChilds: IapComponent[], compParent: IapComponent, compsAll: IapComponent[], result: string, prefix: string[] = [], isChildTempData: boolean = false,onlyDynamic:boolean=true) => {

    if (specialControlContent.includes(compParent.idType)) {
      const tempChild = compsChilds.filter(c => isTemplate(c  as any));
      const notTempChild = compsChilds.filter(c => !isTemplate(c as any));
      tempChild.forEach(x => {
        result = printDynamicCode(x.id, compsAll, result, prefix, (slotData.includes(compParent.idType) || isChildTempData),undefined,onlyDynamic);
      });
      result += printSlotContentCode({} as IapComponent, false, true);
      notTempChild.forEach(x => {
        result = printDynamicCode(x.id, compsAll, result, prefix, (slotData.includes(compParent.idType) || isChildTempData),undefined,onlyDynamic);
      });
      result += printSlotContentCode({} as IapComponent, true, true);

    }
    else if(compParent.idType==ControlTypeConst.CTTABPANEL){
      const {isVisibleTabPanel} = ComponentHelperRender({} as ComponentDataForm,null,store);

      // if(isVisibleTabPanel(compParent as any)){
          const tempChild = compsChilds.filter(c => isTemplate(c as any));
          const notTempChild = compsChilds.filter(c => !isTemplate(c as any));
          tempChild.forEach(x => {
            result = printDynamicCode(x.id, compsAll, result, prefix, (slotData.includes(compParent.idType) || isChildTempData),undefined,onlyDynamic);
          });

          // const allTabs=compsAll.filter(c=>c.parentId==compParent.parentId).filter(c=>isVisibleTabPanel(c as any)).sort((a, b) => (a.order ?? 0) - (b.order ?? 0)); //.sort((a, b) => (a.id ?? 0) - (b.id ?? 0))

          notTempChild.forEach(x => {
              result = printDynamicCode(x.id, compsAll, result, prefix, (slotData.includes(compParent.idType) || isChildTempData),{ tabId: compParent.id, tabViewParentId: compParent.parentId??-1 },onlyDynamic);
          });
        // }

    }
    else {
      compsChilds.forEach(x => {
        result = printDynamicCode(x.id, compsAll, result, prefix, (slotData.includes(compParent.idType) || isChildTempData),undefined,onlyDynamic);
      });
    }
    return result;
  };

  const generateVueFile=(id: number, comps: IapComponent[],result: string, prefix: string[] = [],onlyDynamic:boolean=true):string=>{
    return `<template>${printDynamicCode(id,comps,result,prefix,false,undefined,onlyDynamic)}</template>
            ${getVueFileImports(comps)}
            ${getVueFileCode(comps.find(c=>c.parentId==null)?.id??0,comps)}
     `;
}

const getNotDynCompCode=(component: IapComponent,index:number,dynamicName:string,nativeName:string):string=>{
  
    let codeString=require(`raw-loader!../../infrastructure/controls/editorTemplates/prime/${dynamicName}/${dynamicName}.html`).default;

      // Realiza una serie de transformaciones como quitar el debug, etc.
    codeString = initialHtmlTransform(component,codeString, index,nativeName);

    // Coge todos los patrones que son funciones y los sustituye por el valor o deja la funcion
    codeString =handlePropsAndValues(codeString, component);

    //Resolvemos la funciones que no estan dentro de propiedades. 
    codeString=functionTransform(codeString,undefined,component);

    //transformaciones finales
    codeString=finalHtmlTransform(codeString);
    

    return codeString;

  };


   const initialHtmlTransform  = (component: IapComponent,codeString:string,compIndex:number,nativeName:string) => {

          //@ts-ignore:disable-next-line
          codeString=codeString.replaceAll(',Component',`,comps[${compIndex}]`);
              //@ts-ignore:disable-next-line
          codeString=codeString.replaceAll('getCompClass(Component)',`getCompClass(comps[${compIndex}])`);
          //@ts-ignore:disable-next-line
          codeString=codeString.replaceAll('loaded',`isParentLoaded(comps[${compIndex}]?.parentId??-1,${compIndex}) && loaded[${compIndex}]`);
          //@ts-ignore:disable-next-line
          codeString=codeString.replaceAll('vmodel',`comps[${compIndex}].vmodel`);
          //@ts-ignore:disable-next-line
          // codeString=codeString.replaceAll('v$.vmodel.$error',`compsStaticFunctionContext[${compIndex}].v$.value.vmodel.$error`);
          // //@ts-ignore:disable-next-line
          // codeString=codeString.replaceAll('submitted',`compsStaticFunctionContext[${compIndex}].submitted.value`);
          // //@ts-ignore:disable-next-line
          // codeString = codeString.replaceAll(
          //   /(['"])vmodel\1/g, 
          //   `$1compsStaticFunctionContext[${compIndex}].vmodel.value$1` 
          // );
          //@ts-ignore:disable-next-line
          codeString=codeString.replaceAll(':key="componentKey"',`key="${HelperUtils.newGuid()}"`);
          //@ts-ignore:disable-next-line
          // codeString=codeString.replaceAll('canEdit',`compsStaticFunctionContext?.find(x=> x?.componentId==${component.id})?.canEdit`);
        

          // if(component.idType==ControlTypeConst.CTINPUTTEXT){
          //     //@ts-ignore:disable-next-line
          //     codeString=codeString.replaceAll('hasGroupControls',`compsStaticFunctionContext[${compIndex}]?.hasGroupControls?.value`);
          // }
          
          
          //quitamos la parte de debug no necesarias en aplicaciones estaticas
          const sumDebugRegex = /<SummaryDebug[^>]*\/>/g;
          codeString=codeString.replace(sumDebugRegex, '');

          //quitamos la etiqueta final. En este punto estamos pintando solo la etiqueta inicial.
          codeString=codeString.replace(`</${nativeName}>`,'')

          //quitamos los slots,ya que estos se generan cuando se pinten sus hijos
          codeString=codeString.replace(/<slot name="[^"]*"><\/slot>/g, '');

          return codeString;
   };

    const finalHtmlTransform  = (codeString:string) => {

      // quitar las llaves vacias
        const regex = /\{\{\s*\}\}/g;
        codeString=codeString.replace(regex, '');

        //quitar los espacios vacios
        codeString=cleanSpaces(codeString);

        return codeString;
    };

    const cleanSpaces=(htmlString: string): string =>{
      return htmlString
        .replace(/\s*\n\s*/g, ' ') // Reemplaza saltos de línea y espacios alrededor con un solo espacio
        .replace(/\s{2,}/g, ' ')  // Reemplaza multiples espacios consecutivos con uno solo
        .trim();                  // Elimina espacios al principio y al final
    }
    

    const handlePropsAndValues = (codeString: string, component: IapComponent) => {
    
      // const propValuePattern = /:([a-zA-Z0-9_-]+)="([^"]*)"|v-tooltip="([^"]*)"/g;
      const propValuePattern = /(?<!v-model):([a-zA-Z0-9_-]+)="([^"]*)"|v-tooltip="([^"]*)"/g;

    
      codeString = codeString.replace(
        propValuePattern,
        (match: any, nameProp: any, propValue: any, tooltipValue: any) => {
          if (nameProp) {
            return resolveProp(nameProp, match, propValue, component);
          } else if (tooltipValue) {
            return resolveProp(BaseControlTypeConst.TOOLTIP, match, tooltipValue, component);
          }
    
          return match;
        }
      );
    
      return codeString;
    };
   const resolveProp = (nameProp:string,match:string,funcCode:string,component: IapComponent):string => {
    const propName=nameProp.toLowerCase().replace(/\bv-/g, "");
    const attr=component.iapComponentAttributes.find(attr=>attr.name.toLowerCase()==propName);

    return attr?resolvePropVal(match,funcCode,attr,component):handleExceptions(nameProp, match, funcCode, component)

   };

   const exceptions = (nameProp:string,component: IapComponent):number => {
        let result:number=0;
        if(nameProp.toLowerCase()==HtmlTypeConst.CLASS && component?.iapComponentAttributes?.find(attr=>attr.name.toLowerCase()==HtmlTypeConst.REQUIRED)!=undefined) result=1
        else if(nameProp==BaseControlTypeConst.KEY) return 2;
        return result;
   };

   const handleExceptions = (nameProp:string,match:string,funcCode:string,component:IapComponent) => {

      const exception:number=exceptions(nameProp,component);

      switch (exception) {
        case 1:
          const reqAttr=component?.iapComponentAttributes?.find(attr=>attr.name.toLowerCase()==HtmlTypeConst.REQUIRED);
          return reqAttr? resolvePropVal(match,funcCode,reqAttr as any,component):match;

        case 2:
            return match;

        default:
          return '';
    }

   };

   const resolvePropVal = (match:string,funcCode:string,attr:IapComponentAttribute,component:IapComponent) => {
    
     let result=match.replace(funcCode,functionTransform(funcCode,attr,component));

     //quitamos dos puntos del atributo cuando se de cierto patron
      if(!matchesPattern(result,attr)){
          result=result.replace(':','');
      }
      return result;
   };

   const matchesPattern = (text: string, attr: IapComponentAttribute): boolean => {
    
    const FUNCTION_CALL_REGEX = /(get[a-zA-Z0-9_]+|canDo[a-zA-Z0-9_]*)\(([^)]*)\)(?![^;]*;)/g;
    const BRACES_REGEX = /[\{\}]/;
    const QUOTED_FALSE = /"false"/; 
    const FIND_ARR =  /\.find\(\s*.*\s*\)/;
  
    if (FUNCTION_CALL_REGEX.test(text)) return true;

    if (FIND_ARR.test(text)) return true;
    
    if (QUOTED_FALSE.test(text)) return true;
    
    const isClassOrRequired = attr.name === HtmlTypeConst.CLASS || attr.name === HtmlTypeConst.REQUIRED;
  
    if (BRACES_REGEX.test(text) && isClassOrRequired) return true;

    return false;
  };
  

   const functionTransform = (codeString:string,attr:IapComponentAttribute | undefined,component: IapComponent) => {

    const functionPattern = /([a-zA-Z0-9_]+)\(([^)]*)\)/g;

    codeString= codeString.replace(functionPattern, (match:any, functionName:any, params:any) => {
          
          if(attr){
              return resolveFunction(match,attr,component);
          }
          else{
              const _attr=getAttrFromParam(params,component);
              return _attr?resolveFunction(match,_attr,component):resolveReplaceFunc(params,match);
              
          }
      
       });

      return codeString;
   };

   const getAttrFromParam=( params:any,component: IapComponent):IapComponentAttribute | undefined=>{

        if (!params || !component) return undefined;

        const propName=getPropNameFromParam(params)

        if (!propName) return undefined;

        return component.iapComponentAttributes?.find(attr => attr.name.toLowerCase() === propName.toLowerCase());

     }

    const getPropNameFromParam=(params:any)=>{

      if (!params) return undefined;

      const propNameParam = params.split(',')[0];
      const propName = propNameParam.includes('.') ? propNameParam.split('.')[1] : undefined;

      if (propName) return propName;

    }

    //cuando no hay atributo
    const resolveReplaceFunc=(params:any,match:any)=>{
      const propName=getPropNameFromParam(params);
      return propName && propName.toLowerCase()==HtmlTypeConst.TEXT.toLowerCase()?'':match;
      
    }

   
  const resolveFunction=(funcCode:string,attr:IapComponentAttribute,component: IapComponent):string=>{

    if (!funcCode) return funcCode;
    
    const funcName = funcCode.match(/^([a-zA-Z_$][a-zA-Z0-9_$]*)\(/)?.[1];

    switch (funcName) {

     case FunctionNameConst.GETPROPERTYVALUE:
     case  FunctionNameConst.GETPROPERTYBOOLEANVALUE:
     case  FunctionNameConst.GETCOMPCLASS:
     case  FunctionNameConst.GETCATALOGVALUE:
      
       return  resolvePropValue(component,funcCode,funcName,attr);

     default:
         return funcCode;
       }

    }



    const resolvePropValue=(component: IapComponent,funcCode:string,funcName:string,attr:IapComponentAttribute)=> {

       if(attr.name==HtmlTypeConst.CLASS && !relateWithExpr(component, attr)){
          const reqAttr=component?.iapComponentAttributes?.find(attr=>attr.name.toLowerCase()==HtmlTypeConst.REQUIRED);
          if(reqAttr && relateWithExpr(component, reqAttr)) {return funcCode}
       }

       return relateWithExpr(component, attr)? funcCode: getFuncValue(component,funcCode, funcName, attr.name);

      }

    const relateWithExpr = (component: IapComponent,attr:IapComponentAttribute):boolean => {
       return (isInExpression(CatalogObjectTypeConst.ATTRCOMP,attr.id,allComps as any) || hasExpressions(CatalogObjectTypeConst.ATTRCOMP,attr.id,undefined,component as any));
    };


    const getFuncValue=(component: IapComponent,funcCode:string,funcName:string,attrName:string):any=>{
      switch (funcName) {
        case FunctionNameConst.GETPROPERTYVALUE:
            const propsResult = getPropertyValue(attrName, component as any); 
            if(isValidData(funcName,propsResult)){
              return attrName === HtmlTypeConst.TEXT || attrName === BaseControlTypeConst.TOOLTIP ? `'${propsResult??''}'` : propsResult;
            }else{
              return funcCode;
            }
            
        case FunctionNameConst.GETPROPERTYBOOLEANVALUE:
            return getPropertyBooleanValue(attrName, component as any);

        case FunctionNameConst.GETCOMPCLASS:  
            const classResult = getCompClass(component as any, true);
            //@ts-ignore:disable-next-line
            return typeof classResult === 'string' ? classResult.replaceAll('"', "'") : classResult;
            
        case FunctionNameConst.GETCATALOGVALUE: 
             return  getCatalogValue(attrName, component as any); 

        default:
            return null;
    }
    }

    const isValidData=(funcName:string,data:any):boolean=>{
      switch (funcName) {
        case FunctionNameConst.GETPROPERTYVALUE:
        return !(typeof data === 'string' && (data.includes("'") || data.includes('"') || data.includes("\n")))
        default:
            return true;
      }
    }


  return {
    printDynamicCode,
    generateVueFile
  };

}

