import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")
  const _component_Button = _resolveComponent("Button")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE,_ctx.Component) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW,_ctx.Component))
      ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
          key: _ctx.componentKey,
          id: _ctx.getPropertyValue(_ctx.ButtonTypeConst.ID,_ctx.Component),
          style: _normalizeStyle(_ctx.getPropertyValue(_ctx.ButtonTypeConst.STYLE,_ctx.Component)),
          class: _normalizeClass(_ctx.getCompClass(_ctx.Component)),
          name: _ctx.getPropertyValue(_ctx.ButtonTypeConst.NAME,_ctx.Component),
          disabled: _ctx.getPropertyBooleanValue(_ctx.ButtonTypeConst.DISABLED,_ctx.Component),
          label: _ctx.getPropertyValue(_ctx.ButtonTypeConst.LABEL,_ctx.Component),
          icon: _ctx.getPropertyValue(_ctx.ButtonTypeConst.ICON,_ctx.Component),
          iconPos: _ctx.getCatalogValue(_ctx.ButtonTypeConst.ICONPOS,_ctx.Component),
          iconClass: _ctx.getPropertyValue(_ctx.ButtonTypeConst.ICONCLASS,_ctx.Component),
          badge: _ctx.getPropertyValue(_ctx.ButtonTypeConst.BADGE,_ctx.Component),
          link: _ctx.getPropertyBooleanValue(_ctx.ButtonTypeConst.LINK,_ctx.Component),
          severity: _ctx.getCatalogValue(_ctx.ButtonTypeConst.SEVERITY,_ctx.Component),
          raised: _ctx.getPropertyBooleanValue(_ctx.ButtonTypeConst.RAISED,_ctx.Component),
          rounded: _ctx.getPropertyBooleanValue(_ctx.ButtonTypeConst.ROUNDED,_ctx.Component),
          outlined: _ctx.getPropertyBooleanValue(_ctx.ButtonTypeConst.OUTLINED,_ctx.Component),
          plain: _ctx.getPropertyBooleanValue(_ctx.ButtonTypeConst.PLAIN,_ctx.Component),
          size: _ctx.getCatalogValue(_ctx.ButtonTypeConst.SIZE,_ctx.Component),
          modelValue: _ctx.vmodel,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.vmodel) = $event)),
          onClick: _cache[1] || (_cache[1] = $event => (_ctx.processEventComponent(_ctx.CatalogEventConst.CLICK,_ctx.Component)))
        }, null, 8 /* PROPS */, ["id", "style", "class", "name", "disabled", "label", "icon", "iconPos", "iconClass", "badge", "link", "severity", "raised", "rounded", "outlined", "plain", "size", "modelValue"])), [
          [_directive_tooltip, _ctx.getPropertyValue(_ctx.ButtonTypeConst.TOOLTIP,_ctx.Component)]
        ])
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}