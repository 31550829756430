export default class ErrorCodesConst {
    static NEED_MULTIFACTOR_CODE = "418";
    static ERROR_LIST_CONTROLS =['Cannot read properties of null (reading \'style\')',
                                'Cannot read properties of null (reading \'$_ptooltipModifiers\')',
                                'Cannot set properties of null (setting \'$_ptooltipModifiers\')',
                                'Cannot read properties of null (reading \'querySelector\')',
                                'this.$slots.default is not a function',
                                "Cannot read properties of undefined (reading 'style')"
                                ]
  }
  