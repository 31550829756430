<template>
    <SkeletonCrudTable v-if="!hasData"></SkeletonCrudTable>
    <div v-else>
        <div class="grid">
            <div v-if="!showTabMode" class="col-12 lg:col-12">
                <Search ref="searchComp" v-if="showSearch" v-model="groupSearch" v-model:catalogosData="catalogos"
                    :applicationId="Component.applicationId" :applicationVersion="Component.applicationVersion"
                    :Component="Component" v-model:dataSearch="dataSearchResult" :container="container"
                    :showSaveSearch="showSaveSearch" :showTabMode="showTabMode" :formKey="Component.formKey" :tabIndex="tabIndex"
                    :columnFilters="columnFilters" :sortData="sortData" :advancedSearch="advancedSearch" :collapsible="collapsible" 
                    :compDataSource="datasource" :searchComponentId="searchComponentId" @buscarDatos="doRefreshData($event)"
                    @closealltabs="$emit('closealltabs')" @update:colFilters="setNewFilters($event)"
                    @update:sortData="setNewSortData($event)">

                    <template #buttonssearch>
                        <slot name="buttonssearch"></slot>
                    </template>
                </Search>

                <SearchDetail v-if="dataSearchFilter.length > 0 || showSearch == false || showList"
                    v-model="dataSearchResult" v-model:catalogosData="catalogos" :Component="Component"
                    :compDataSource="datasource" :container="container" 
                    :showActionMenu="showActionMenu" :filterTablas="filterTables" :canAddNewItem="canAddNewItem"
                    :canImport="canImport" :canExport="canExport" :canSelectMultiple="canSelectMultiple"
                    :canUpdateMassive="canUpdateMassive" :canDeleteItem="canDeleteItem"
                    :showActionDetail="showActionDetail" :showSearch="showSearch" :showPaginator="showPaginator"
                    :showHeaderDetail="showHeaderDetail" :customAddNew="customAddNew" :newFilters="newFilters"
                    :newSortData="newSortData" @click:action="$emit('click:action', $event)"
                    :filterDisplay="filterDisplay" :canClearAllFilters="canClearAllFilters" :setupImport="setupImport"
                    :lazy="lazy" :totalRecords="totalRecords" :lazyScroll="lazyScroll" :lazyData="lazyData"
                    :groupSearch="groupSearch" :lazyLoading="lazyLoading" :rowGroupMode="rowGroupMode"
                    :groupRowsBy="groupRowsBy" :expandableRowGroups="expandableRowGroups"
                    @click:viewDetail="$emit('click:viewDetail', $event)"
                    @click:SelectRow="$emit('click:SelectRow', $event)"
                    @update:SelectMultipleRow="$emit('update:SelectMultipleRow', $event)"
                    @click:addCustom="$emit('click:addCustom', $event)" @selectAll="onSelectAllRows"
                    @lazyScroll="onLazyScroll" @click:refreshData="doRefreshData($event)"
                    @filters="getFiltersFromDetail($event)" @sort="getSortDataFromDetail($event)"
                    @end:import="$emit('end:import')" @begin:import="$emit('begin:import', $event)">

                    <template #buttonsresult>
                        <slot name="buttonsresult"></slot>
                    </template>
                </SearchDetail>


            </div>
            <div v-else class="col-12 lg:col-12">
                <CustomTabView v-model:activeIndex="selectedTab" :onTabChange="doChangeTab"
                    :scrollable="itemTabs.length > 7" :verticalTabs="false" :prevIcon="'pi pi-chevron-left'"
                    :nextIcon="'pi pi-chevron-right'">



                    <TabPanel v-for="(tab, index) in itemTabs" :key="tab.key">
                        <template #header>
                            <div>
                                <span v-if="tab.key.includes(SEARCH_KEY)" v-tooltip="'Búsqueda'"><i
                                        class="pi pi-search "></i> {{ tab.header }}</span>
                                <span v-else-if="tab.key == RESULT_KEY"><i class="pi pi-table "
                                        v-tooltip="'Resultados'"></i> {{ tab.header + getTotalRows }}</span>
                                <span v-else v-html="tab.header ?? 'Cabecera por definir'"> </span>
                            </div>
                        </template>
                        <div v-if="tab.key == (SEARCH_KEY + RESULT_KEY)">
                            <Search ref="searchComp" v-if="showSearch" v-model="groupSearch"
                                v-model:catalogosData="catalogos" :applicationId="Component.applicationId"
                                :applicationVersion="Component.applicationVersion" :Component="Component"
                                :showBtnCloseTabs="showBtnCloseTabs" :showSaveSearch="showSaveSearch" :showTabMode="showTabMode"
                                v-model:dataSearch="dataSearchResult" :formKey="Component.formKey" :tabIndex="tabIndex"
                                :columnFilters="columnFilters" :sortData="sortData" :advancedSearch="advancedSearch" :collapsible="collapsible"
                                :container="container" :compDataSource="datasource" :searchComponentId="searchComponentId" @buscarDatos="doRefreshData($event)"
                                @closealltabs="closeAllTabs" @update:colFilters="setNewFilters($event)"
                                @update:sortData="setNewSortData($event)">
                                <template #buttonssearch>
                                    <slot name="buttonssearch"></slot>
                                </template>
                            </Search>

                            <SearchDetail v-if="dataSearchFilter.length > 0 || showSearch == false"
                                v-model="dataSearchResult" v-model:catalogosData="catalogos" :Component="Component"
                                :compDataSource="datasource" :container="container"
                                :showActionMenu="showActionMenu"
                                :filterTablas="filterTables" :canAddNewItem="canAddNewItem" :canImport="canImport"
                                :canExport="canExport" :canSelectMultiple="canSelectMultiple"
                                :canUpdateMassive="canUpdateMassive" :canDeleteItem="canDeleteItem"
                                :showActionDetail="showActionDetail" :newFilters="newFilters" :newSortData="newSortData"
                                :showSearch="showSearch" :showPaginator="showPaginator"
                                :showHeaderDetail="showHeaderDetail" :filterDisplay="filterDisplay"
                                :canClearAllFilters="canClearAllFilters" :customAddNew="customAddNew"
                                :setupImport="setupImport" :lazy="lazy" :totalRecords="totalRecords"
                                :lazyScroll="lazyScroll" :lazyData="lazyData" :groupSearch="groupSearch"
                                :lazyLoading="lazyLoading" :rowGroupMode="rowGroupMode" :groupRowsBy="groupRowsBy"
                                :expandableRowGroups="expandableRowGroups"
                                @click:action="catchEvent('click:action', $event)"
                                @click:viewDetail="catchEvent('click:viewDetail', $event)"
                                @click:SelectRow="catchEvent('click:SelectRow', $event)"
                                @update:SelectMultipleRow="$emit('update:SelectMultipleRow', $event)"
                                @click:addCustom="catchEvent('click:addCustom', $event)" @selectAll="onSelectAllRows"
                                @lazyScroll="onLazyScroll" @click:refreshData="doRefreshData($event)"
                                @filters="getFiltersFromDetail($event)" @sort="getSortDataFromDetail($event)"
                                @end:import="$emit('end:import')" @begin:import="$emit('begin:import', $event)">
                                <template #buttonsresult>
                                    <slot name="buttonsresult"></slot>
                                </template>
                            </SearchDetail>
                        </div>
                        <div v-else-if="tab.key == SEARCH_KEY">
                            <Search ref="searchComp" v-if="showSearch" v-model="groupSearch"
                                v-model:catalogosData="catalogos" :applicationId="Component.applicationId"
                                :applicationVersion="Component.applicationVersion" :Component="Component"
                                :showBtnCloseTabs="showBtnCloseTabs" :showSaveSearch="showSaveSearch" :showTabMode="showTabMode"
                                v-model:dataSearch="dataSearchResult" :formKey="Component.formKey" :tabIndex="tabIndex"
                                :columnFilters="columnFilters" :sortData="sortData" :advancedSearch="advancedSearch" :collapsible="collapsible"
                                :container="container" :compDataSource="datasource" :searchComponentId="searchComponentId" @buscarDatos="doRefreshData($event)"
                                @closealltabs="closeAllTabs" @update:colFilters="setNewFilters($event)"
                                @update:sortData="setNewSortData($event)">
                                <template #buttonssearch>
                                    <slot name="buttonssearch"></slot>
                                </template>
                            </Search>
                        </div>
                        <div v-else-if="tab.key == RESULT_KEY">
                            <SearchDetail v-if="dataSearchFilter.length > 0 || showSearch == false"
                                v-model="dataSearchResult" v-model:catalogosData="catalogos" :Component="Component"
                                :compDataSource="datasource" :container="container"
                                 :showActionMenu="showActionMenu"
                                :filterTablas="filterTables" :canAddNewItem="canAddNewItem" :canImport="canImport"
                                :canExport="canExport" :canSelectMultiple="canSelectMultiple"
                                :canUpdateMassive="canUpdateMassive" :canDeleteItem="canDeleteItem"
                                :showActionDetail="showActionDetail" :newFilters="newFilters" :newSortData="newSortData"
                                :showSearch="showSearch" :showPaginator="showPaginator"
                                :showHeaderDetail="showHeaderDetail" :customAddNew="customAddNew"
                                :filterDisplay="filterDisplay" :canClearAllFilters="canClearAllFilters"
                                :setupImport="setupImport" :lazy="lazy" :totalRecords="totalRecords"
                                :lazyScroll="lazyScroll" :lazyData="lazyData" :groupSearch="groupSearch"
                                :lazyLoading="lazyLoading" :rowGroupMode="rowGroupMode" :groupRowsBy="groupRowsBy"
                                :expandableRowGroups="expandableRowGroups"
                                @click:action="catchEvent('click:action', $event)"
                                @click:viewDetail="catchEvent('click:viewDetail', $event)"
                                @click:SelectRow="catchEvent('click:SelectRow', $event)"
                                @update:SelectMultipleRow="$emit('update:SelectMultipleRow', $event)"
                                @click:addCustom="catchEvent('click:addCustom', $event)" @selectAll="onSelectAllRows"
                                @lazyScroll="onLazyScroll" @click:refreshData="doRefreshData($event)"
                                @filters="getFiltersFromDetail($event)" @sort="getSortDataFromDetail($event)"
                                @end:import="$emit('end:import')" @begin:import="$emit('begin:import', $event)">
                                <template #buttonsresult>
                                    <slot name="buttonsresult"></slot>
                                </template>
                            </SearchDetail>
                        </div>
                        <!--Los detalles de lo que se va abriendo -->
                        <div v-else>
                            <DetailCrud v-show="selectedTab == index" :container="container"
                                :componentId="tab.componentId" :key="tab.keyInternal"
                                :applicationId="Component.applicationId"
                                :applicationVersion="Component.applicationVersion" :attrsInput="(tab.attrs)"
                                :tabMode="showTabMode" :detailComponentId="tab.detailComponentId"
                                :detailApplicationId="tab.detailApplicationId"
                                :detailApplicationVersion="tab.detailApplicationVersion"
                                :detailAttrsInput="tab.detailAttrs"
                                @click:closeTab="customCloseTab(tab.key, (tab.detailComponentId > 0))"
                                @click:reloadTab="reloadTab(tab.key)">


                                <template v-if="itemTabs[index].layoutHeaderData && selectedTab == index" #header>
                                    <component :is="getDynamicLayoutComponent(itemTabs[index].layoutHeaderData)"
                                        :comps="getDynamicLayoutComponentData(itemTabs[index].layoutHeaderData)"
                                        v-model:slotProps="slotProps" :container="container"
                                        :rootParentId="Component.rootParentId" :formKey="Component.formKey"
                                        :key="tab.keyInternal + '_header'" />
                                </template>
                                <template v-if="itemTabs[index].layoutButtonsData && selectedTab == index" #buttons>
                                    <component :is="getDynamicLayoutComponent(itemTabs[index].layoutButtonsData)"
                                        :comps="getDynamicLayoutComponentData(itemTabs[index].layoutButtonsData)"
                                        v-model:slotProps="slotProps" :container="container"
                                        :rootParentId="Component.rootParentId" :formKey="Component.formKey"
                                        :key="tab.keyInternal + '_btns'" />
                                </template>
                                <template v-if="itemTabs[index].layoutDetailData && selectedTab == index" #detail>
                                    <component :is="getDynamicLayoutComponent(itemTabs[index].layoutDetailData)"
                                        v-model:comps="itemTabs[index].layoutDetailData" v-model:slotProps="slotProps"
                                        :container="container" :rootParentId="Component.rootParentId"
                                        :formKey="Component.formKey" :key="tab.keyInternal + '_detail'" />
                                </template>


                            </DetailCrud>
                        </div>

                    </TabPanel>

                </CustomTabView>
            </div>

        </div>
    </div>
</template>
<script lang="ts">

import { Container } from 'inversify';
import { computed, defineComponent, onMounted, onBeforeUnmount, onActivated, ref, shallowRef, triggerRef } from 'vue';
import { clave } from '../../dataupdate/domain/clave';
import Search from './search/Search.vue'
import SearchDetail from './search/SearchDetail.vue'
import SkeletonCrudTable from '../../../../common/infrastructure/componentes/base/common/skeleton/SkeletonCrudTable.vue'
import { IapCatalog } from '../../catalog/domain/iapCatalog';
import { IapCatalogType } from '../../catalog/domain/iapCatalogType';
import { SearchData, GroupSearch, LazyParams } from '../../search/domain/search';
import OperatorLogicConst from '../../search/domain/Const/OperatorLogicConst';
import DataSourceComp from './functions/dataSourceComp';
import { IapComponentDataSource } from '../../component/domain/iapComponentDataSource';
import { IapComponent } from '../../component/domain/iapComponent';
import FiltroBusquedaConst from '../../../../common/domain/constantes/FiltroBusquedaConst';
import { IServiceSearch } from '../../search/application/IServiceSearch';
import { TYPES } from '../../../../common/domain/types';
import HelperLoading from '../../../../common/infrastructure/funciones/HelperLoading';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';
import { useStore } from 'vuex';
import helperCatalog from '../../catalog/infrastructure/helper/helperCatalog';
import CustomTabView from '../../../../common/infrastructure/componentes/base/common/controles/CustomTabView.vue'
import multiTab from '../../form/domain/Functions/MultiTab';
import { IapComponentAttribute } from '../../component/domain/iapComponentAttribute';
import EventBusCustom from '../../../../common/infrastructure/event-bus-custom';
import EventConst from '../../../../common/domain/constantes/EventConst';
import { json } from 'stream/consumers';
import DetailCrud from './search/DetailCrud.vue';
import DataSourceConst from './functions/dataSourceConst';
import CrudTableTypeConst from '../../form/domain/Constants/CrudTableTypeConst';
import ComponentDataForm from '../../designer/domain/ComponentDataForm';
import CatalogCompConst from '../../catalog/domain/const/CatalogCompConst';
import TabData from '../../form/domain/tabData';
import ComponentUtil from '../../designer/infrastructure/component/util/componentUtil';
import ControlTypeConst from '../../form/domain/Constants/ControlTypeConst';
import MenuItemTypeConst from '../../form/domain/Constants/MenuItemTypeConst';
import CatalogObjectTypeConst from '../../catalog/domain/const/CatalogObjectTypeConst';
import CatalogCommandConst from '../../catalog/domain/const/CatalogCommandConst';
import CrudTableComp from './functions/crudTableComp';
import SqlTypesConst from '../../../../common/domain/constantes/SqlTypesConst';
import { useRouter } from 'vue-router';
import { LazyDataProvider } from '../../search/domain/lazyDataProvider';
import OperationLazyDataProviderConst from '../../search/domain/Const/OperationLazyDataProviderConst';
import ComponentRenderHelper from '../../form/domain/Functions/ComponentRenderHelper';
import HelperCommon from '@ilinium/shared/src/common/infrastructure/funciones/HelperCommon';
import { InteractionEvent } from '../../interaction/domain/interactionEvent';
import HelperNavigation from '../../../../common/infrastructure/funciones/HelperNavigation';
import { Dictionary } from '../../expression/domain/dictionary';
import CatalogTypeConst from '@ilinium/shared/src/entidades/builder/catalog/domain/const/CatalogTypeConst';
import { Tuple } from '../../expression/domain/tuple';


export default defineComponent({
    name: 'crud_table_builder',

    props: {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
        showBtnSearch: {
            type: Boolean,
            default: true
        },
        showBtnClear: {
            type: Boolean,
            default: true
        },
        showBtnCloseTabs: {
            type: Boolean,
            default: false
        },

        maxRegs: {
            type: Number,
            default: 100
        },
        canAddNewItem: {
            type: Boolean,
            default: false
        },
        canImport: {
            type: Boolean,
            default: false
        },
        canExport: {
            type: Boolean,
            default: false
        },
        canDeleteItem: {
            type: Boolean,
            default: false
        },
        canUpdateMassive: {
            type: Boolean,
            default: true
        },
        canSelectMultiple: {
            type: Boolean,
            default: false
        },
        canClearAllFilters: {
            type: Boolean,
            default: false
        },
        showActionMenu: {
            type: Boolean,
            default: false
        },
        showSearch: {
            type: Boolean,
            default: true
        },

        showList: {
            type: Boolean,
            default: true
        },

        collapsible: {
            type: Boolean,
            default: true
        },
        showResultFromCustomfilter: {
            type: Object as () => clave[],
            default: () => ([])
        },
        showActionDetail: {
            type: Boolean,
            default: false
        },
        showPaginate: {
            type: Boolean,
            default: true
        },
        showPaginator: {
            type: Boolean,
            default: true
        },
        showHeaderDetail: {
            type: Boolean,
            default: true
        },
        customAddNew: {
            type: Boolean,
            default: false
        },
        showTabMode: {
            type: Boolean,
            default: false
        },
        showResultTabMode: {
            type: Boolean,
            default: false
        },
        showSaveSearch: {
            type: Boolean,
            default: true
        },

        layoutDetailComponentId: {
            type: Number,
            default: -1
        },

        searchComponentId: {
            type: Number,
            default: 0
        },
        tabHeader: {
            type: String,
            default: 'Cabecera Tab'
        },

        filterDisplay: {
            type: String,
            default: 'menu'
        },
        slotProps: {
            type: Object,
            default: () => ({})
        },
        setupImport: {
            type: String,
            default: ''
        },
        advancedSearch: {
            type: Boolean,
            default: true
        },
        lazy: {
            type: Boolean,
            default: false
        },
        lazyScroll: {
            type: Boolean,
            default: false
        },

        expandableRowGroups: {
            type: Boolean,
            default: false
        },


        rowGroupMode: {
            type: String,
            default: null
        },


        groupRowsBy: {
            type: String,
            default: null
        },


    },
    emits: ['click:action', 'closealltabs', 'click:viewDetail', 'click:SelectRow', 'click:addCustom', 'update:SelectMultipleRow'],
    components: {
        SkeletonCrudTable,
        Search,
        SearchDetail,
        CustomTabView,
        DetailCrud

    },

    setup(props, { emit }) {
        const store = useStore();
        const router = useRouter();
        const SEARCH_KEY = 'search';
        const RESULT_KEY = 'result';
        const datasource = ref<IapComponentDataSource>();
        const searchComp = ref();
        const catalogos = ref<IapCatalog[]>([]);
        const dataSearchFilter = ref<Partial<SearchData>[]>([]);
        const groupSearch = ref<GroupSearch>();
        const dataSearchResult = ref();
        const hasLoadCustomSearchData = shallowRef(false)
        const keyData = ref();
        const tabIndex = ref();
        const keyDataObject = ref();
        const newFilters = ref();
        const newSortData = ref();
        const columnFilters = ref();
        const sortData = shallowRef();
        const totalRecords = shallowRef(0);
        const first = shallowRef(0);
        const rows = shallowRef(25);
        const last = ref();
        const originalFirst = ref();
        const lazyData = ref<LazyDataProvider[]>([]);
        const lazyLoading = shallowRef(false);
        const virtualData = ref(Array.from({ length: 100000 }));
        const selectAll = shallowRef(false);
        const keyEventComponent = 'current_' + props.Component.formKey + (props.Component.rootParentId ?? -1).toString() + '_' + props.layoutDetailComponentId.toString() + '_' + EventConst.SHOWCOMPONENT;
        const keyEventComponentNavigation = 'current_' + props.Component.formKey + (props.Component.rootParentId ?? -1).toString() + (props.Component.id ?? -1).toString() + '_' + EventConst.NAVIGATION;
        const keyMenuItemsEventComponent: string[] = [];// 'current_' + props.formKey + (props?.rootParentId ?? -1).toString() + '_' + props.layoutDetailComponentId.toString() + '_' + EventConst.SHOWCOMPONENT;
        const { convertTreeToFlat } = ComponentUtil();
        const {
            itemTabs,
            activeId,
            selectedTab,
            changeTab,
            closeAllTabs,
            closeTab,
            addTab,
            currentTab,
            clearAllTabData,
            findTab,
            navigateTab
        } = multiTab(store);

        const { getLayoutComponent,
            hasLayoutComponent,
            hasLayoutWorkFlow } = CrudTableComp(props);

        const customCloseTab = (key: string, closeDetail: Boolean) => {
            if (closeDetail) {
                const tabData = itemTabs.value.find(x => x.key == key);
                if (tabData) {
                    tabData.detailComponentId = -1;
                    tabData.detailApplicationId = -1;
                    tabData.detailApplicationVersion = -1;
                    tabData.detailAttrs = [];
                    triggerRef(itemTabs);
                }

            }
            else {
                closeTab(key);
            }
        }

        const buildkeyMenuItemsEventComponent = () => {
            if (props.showTabMode) {
                const layoutMenus = getLayoutComponent(CrudTableTypeConst.LAYOUT_ACTIONS_MENU);
                if (layoutMenus) {
                    const comps = convertTreeToFlat(layoutMenus)
                    comps?.filter(x => x.idType == ControlTypeConst.CTMENUITEM).forEach((compMenuItem: ComponentDataForm) => {
                        const propsIdCmd = compMenuItem.iapComponentAttributes.find(x => x.name == MenuItemTypeConst.COMMAND)?.id ?? '';
                        const hasWf = compMenuItem.workFlows?.find(x => x.idObjeto == CatalogObjectTypeConst.ATTRCOMP && x.objetoId == propsIdCmd && x.active == true && x.iapWorkFlowActivities.find(a => a.typeWorkFlowId == CatalogCommandConst.EXEC_SHOWFORM) !== undefined)
                        if (hasWf) {
                            keyMenuItemsEventComponent.push(
                                'current_' + props.Component.formKey + (props.Component.rootParentId ?? -1).toString() + '_' + compMenuItem.id.toString() + '_' + EventConst.SHOWCOMPONENT
                            )
                        }
                    })

                }
            }
        }

        const getDynamicLayoutComponentData = (component: ComponentDataForm) => {
            var compHeader = Object.assign({}, component);
            if (compHeader) {
                compHeader.idType = ControlTypeConst.CTDIV;
                var comps = props.Component.get(compHeader.id, 'allchildren');
                comps.unshift(compHeader);
                if (comps?.length > 1) {
                    return comps;
                }
                else {
                    return [];
                }

            }
            else {
                return []
            }
        }

        const getDynamicLayoutComponent = (component: ComponentDataForm) => {
            var data = getDynamicLayoutComponentData(component);
            if (data?.length > 1) {

                return ComponentRenderHelper.BuildComponent(data[0].id, data);
            }
            else {
                return null;
            }
        }


        const hasData = computed(() => {
            return datasource.value && datasource.value.dataSource.iapDataSourceFields.length > 0 && datasource.value?.dataSource?.iapDataSourceDataBase?.idBaseDatos && catalogos.value.length > 0 && (hasLoadCustomSearchData.value == true);
        })


        const filterTables = computed(() => {
            const { getFieldData } = DataSourceComp(props.container as any, props, emit, {}, datasource.value as any, [] as any, store)
            const aux = dataSearchFilter.value.map(x => getFieldData(x.fieldId)?.tableName)
            const uniqueIds = [...new Set(aux)];
            return (uniqueIds ?? []) as any
        })

        const fillDataSearchFilters = () => {

            const { getDefaultvalue, getFilterValueData, isStrictFilter } = DataSourceComp(props.container as any, props, emit, {}, datasource.value as any,[]as any, store)
            const claves = datasource.value?.dataSource.iapDataSourceFields.filter(x => (x.filterValue ? x.filterValue : getDefaultvalue(x, datasource.value?.iapComponentDataSourceFieldConfigurations?.find(cfg => cfg.dataSourceFieldId == x.id)?.defaultValue, null, true)) || isStrictFilter(x, datasource.value)).map(x =>
            ({
                //@ts-ignore:disable-next-line
                id: HelperUtils.newGuid().toString().replaceAll('-', ''),
                fieldId: x.id,
                filter: getFilterValueData(x.filterValue, 0) ?? FiltroBusquedaConst.FILTROBUSQUEDA_IGUAL,
                value: x.filterValue ? getFilterValueData(x.filterValue, 1) : getDefaultvalue(x, datasource.value?.iapComponentDataSourceFieldConfigurations?.find(cfg => cfg.dataSourceFieldId == x.id)?.defaultValue, null, true),
                valueList: (x.catalogTypeId ? [x.filterValue] : null),
                valueBool: null,
                valueNumber: null,
                valueDateTime: null,
                rangeValue: null,
                rangeNumber: null,
                rangeDateTime: null,
                required: isStrictFilter(x, datasource.value)

            }));


            if (claves) {
                /*try{
                    dataSearchFilter.value = dataSearchFilter.value.concat(claves as any)
                }catch{}
                */

                claves.forEach((clave: any) => {
                    if (dataSearchFilter.value.find((r: any) => r.fieldId == clave.fieldId) == undefined) {
                        dataSearchFilter.value.push(clave as any)
                    }
                })



            }
        }
        const showResultTab = () => {
            // lo movemos al tab que le toque
            if (props.showTabMode) {

                if (props.showResultTabMode) {
                    changeTab({ index: 1 })
                }
                else {
                    changeTab({ index: 0 })
                }

            }
        }

        const getTotalRows = computed(() => {
            if (props.lazy) {
                return ' (' + totalRecords.value + ')';
            }
            if (dataSearchResult.value && !props.lazy) {
                return ' (' + dataSearchResult.value.length + ')';
            }
            return '';
        })

        const doRefreshData = (event: any, rowToFind: SearchData[] = [], callbakOk: any = undefined) => {
            if (props.container) {

                const { adjustCdsAndClauses, clearEmptyFiltersList, adjustAllTreeSearchRequest, reorderSearchTree, filterCatalogsFields } = DataSourceComp(props.container as any, props, emit, {}, datasource.value as any, [] as any, store)

                const searchData: GroupSearch = {
                    operatorLogic: OperatorLogicConst.AND,
                    fields: rowToFind,
                    children: []
                }

                let data = JSON.parse(JSON.stringify(rowToFind.length > 0 ? searchData : groupSearch.value));

                //sacar los searchData del primer nivel que tienen filtros de expresiones y no son campos de búsqueda por defecto, estos son AND estrictos. Seria los campos ocultos de busqueda.
                reorderSearchTree(data);

                clearEmptyFiltersList(data)

                adjustAllTreeSearchRequest(data);

                const cdsCopy = adjustCdsAndClauses(datasource.value);

                let lazyParams: LazyParams = {} as any;


                if (props.lazy) {
                    lazyParams = {
                        first: getLazyFirstIndex(event),
                        rows: getLazyRows(event),
                        filters: event?.event?.filters ? JSON.stringify(event?.event?.filters) : JSON.stringify(columnFilters.value),
                        multiSortMeta: event?.event?.multiSortMeta ?? sortData.value,
                        fieldsConfig: datasource.value?.iapComponentDataSourceFieldConfigurations,
                        catalogs: filterCatalogsFields(datasource?.value, catalogos.value),
                        provideData: (props.lazyScroll && (originalFirst.value > 0)) ? [{ data: OperationLazyDataProviderConst.ITEMS, columnRequest: [] }] : event?.provideData ?? [{ data: OperationLazyDataProviderConst.AGGREGATE, columnRequest: [] }, { data: OperationLazyDataProviderConst.COUNT, columnRequest: [] }, { data: OperationLazyDataProviderConst.ITEMS, columnRequest: [] }]
                    }
                }


                if (!callbakOk) {
                    HelperLoading.showLoading();

                    if (props.lazyScroll && !selectAll.value) {
                        lazyLoading.value = true
                    }
                }


                const _srv = props.container.get<IServiceSearch>(
                    TYPES.SEARCH_REPOSITORY
                );


                _srv.search(props.Component.applicationId, props.Component.applicationVersion, props.Component.id,
                    data as any, [], datasource.value?.maxRowsReturned ?? 100, cdsCopy, cdsCopy?.dataSourceId, false, Object.keys(lazyParams).length > 0 ? lazyParams : undefined)
                    .then((response) => {

                        const { generateAllFormatColCrud } = DataSourceComp(props.container as any, props, emit, {}, datasource.value as any, catalogos.value, store)

                        if (callbakOk) {

                            const callBackResponse = JSON.parse(response?.items ?? '[]');
                            generateAllFormatColCrud(callBackResponse);
                            callbakOk({ data: callBackResponse });
                        }
                        else {

                            dataSearchResult.value = JSON.parse(response?.items ?? '[]');
                            generateAllFormatColCrud(dataSearchResult.value)
                            totalRecords.value = HelperCommon.isNullOrNotDefined(response?.totalRecords) ? totalRecords.value : response.totalRecords ?? 0;


                            if (props.lazy && lazyParams && (lazyParams?.provideData?.filter(x => x.data == OperationLazyDataProviderConst.AGGREGATE).length ?? 0) > 0) {
                                lazyData.value = response?.lazyData ?? [];
                            }


                            if (props.lazyScroll && !selectAll.value && !(dataSearchResult.value.length >= totalRecords.value)) {

                                virtualData.value.length = totalRecords.value ?? 100000;

                                Array.prototype.splice.apply(virtualData.value, [
                                    first.value,
                                    (last.value - first.value),
                                    ...dataSearchResult.value,
                                ]);

                                dataSearchResult.value = virtualData.value;
                            }

                            selectAll.value = false;

                        }


                    })
                    .finally(() => {

                        if (!callbakOk) {
                            HelperLoading.hideLoading();
                            if (props.lazyScroll && lazyLoading.value && !selectAll.value) {
                                lazyLoading.value = false;
                            }

                            data = null;
                            showResultTab();
                        }



                    });

            }

        }

        const getLazyFirstIndex = (event: any) => {

            if (selectAll.value) {
                return 0;
            }
            else if (props.lazyScroll) {
                //AQUI HAY QUE TENER EN CUENTA CUANDO VIENE DEL EVENTO SCROLL EN ESE CASO NO TIEN QUE IRSE A LA PRIMER...
                if (!event || (event && event?.event?.hasOwnProperty("originalEvent"))) {
                    first.value = 0;
                    originalFirst.value = 0;
                    //SE RESETEA EL ARRAY
                    virtualData.value = Array.from({ length: 100000 });
                    return first.value;
                }
                else {
                    return first.value;
                }
            }
            else {
                return event?.event?.first ?? 0;
            }
        }

        const getLazyRows = (event: any) => {

            if (selectAll.value) {
                return -1;
            }
            else if (props.lazyScroll) {
                if (!event || (event && event?.event?.hasOwnProperty("originalEvent"))) {
                    last.value = 25;

                    return last.value;
                }
                else {
                    return rows.value;
                }
            }
            else {
                return event?.event?.rows ?? 10;
            }

        }


        const doSearch = () => {

            const { generateAllFormatColCrud,getRequiredFilterFields,initFilterField } = DataSourceComp(props.container as any, props, emit, {}, datasource.value as any, catalogos.value, store)
            hasLoadCustomSearchData.value = true

            fillDataSearchFilters();
            dataSearchResult.value = datasource.value?.dataSource?.items ?? [];
            generateAllFormatColCrud(dataSearchResult.value, false)

            if(props.searchComponentId==0){

                const dataSearchFilterActualsIds = dataSearchFilter.value.flatMap(x => x?.fieldId);

                datasource.value?.dataSource.iapDataSourceFields.filter(x => !dataSearchFilterActualsIds.includes(x.id)).filter(x => datasource.value?.iapComponentDataSourceFieldConfigurations.find(z => z.dataSourceFieldId == x.id)?.defaultFilterSearch)
                    .sort((a, b) => { return (datasource.value?.iapComponentDataSourceFieldConfigurations.find(z => z.dataSourceFieldId == a.id)?.defaultFilterSearchOrder ?? 0) - (datasource.value?.iapComponentDataSourceFieldConfigurations.find(z => z.dataSourceFieldId == b.id)?.defaultFilterSearchOrder ?? 0); })
                    .forEach(c => {
                        dataSearchFilter.value.push({
                            id: HelperUtils.newGuid().toString().replaceAll('-', ''),
                            fieldId: c.id as any,
                            filter:initFilterField(c) ,
                            rangeDateTime: {
                                from: null,
                                to: null
                            },
                            rangeValue: {
                                from: null,
                                to: null
                            },

                        } as any)
                    })

                dataSearchFilter.value.sort((a, b) => { return (datasource.value?.iapComponentDataSourceFieldConfigurations.find(z => z.dataSourceFieldId == a.fieldId)?.defaultFilterSearchOrder ?? 0) - (datasource.value?.iapComponentDataSourceFieldConfigurations.find(z => z.dataSourceFieldId == b.fieldId)?.defaultFilterSearchOrder ?? 0); });


            }

            

            groupSearch.value = {
                operatorLogic: OperatorLogicConst.AND,
                fields: !props.advancedSearch? dataSearchFilter.value : getRequiredFilterFields( { operatorLogic: OperatorLogicConst.AND,fields:dataSearchFilter.value as any,children:[]}) as any,
                children: []
            }

            if (datasource.value?.autoLoad) {
                showResultTab();
            }

        }

        const initTabs = () => {

            if (props.showTabMode) {
                itemTabs.value = [
                    {
                        key: SEARCH_KEY + (!props.showResultTabMode ? RESULT_KEY : ''),
                        keyData: null,
                        applicationId: -1,
                        applicationVersion: -1,
                        componentId: -1,
                        closable: false,
                        header: '',
                        keyInternal: '',
                        layoutHeaderData: undefined,
                        layoutButtonsData: undefined,
                        layoutDetailData: undefined,
                        attrs: [],
                        detailComponentId: -1,
                        detailApplicationId: -1,
                        detailApplicationVersion: -1,
                        detailAttrs: []
                    }]

                if (props.showResultTabMode) {
                    itemTabs.value.push({
                        key: RESULT_KEY,
                        keyData: null,
                        applicationId: -1,
                        applicationVersion: -1,
                        componentId: -1,
                        closable: false,
                        header: '',
                        keyInternal: '',
                        layoutHeaderData: undefined,
                        layoutButtonsData: undefined,
                        layoutDetailData: undefined,
                        attrs: [],
                        detailComponentId: -1,
                        detailApplicationId: -1,
                        detailApplicationVersion: -1,
                        detailAttrs: []


                    })
                }

            }
        }

        const setKeyData = (data: any) => {
            if (data?.keys?.length > 0) {
                keyData.value = data?.keys;
                keyDataObject.value = data?.item;

            }
            else {
                keyData.value = null;
                keyDataObject.value = null;
            }
        }
        const doChangeTab = (event: any) => {
            tabIndex.value = event.index;
            const tabData = itemTabs.value[event.index];
            if (tabData && tabData?.closable) {
                const callBackEvent = (response: boolean) => {
                    if (response) {
                        changeTab(event)
                    }

                }


                emit('click:SelectRow', {
                    keys: JSON.parse(tabData.key),
                    item: tabData.keyData,
                    callBackOk: callBackEvent
                });
            }
            else {
                changeTab(event);
            }


        }



        const catchEvent = (eventname: string, event: any) => {
            switch (eventname) {
                case 'click:action':
                    setKeyData(event);
                    emit('click:action', event);
                    break;
                case 'click:viewDetail':
                    setKeyData(event);
                    const doCallBack = (response: boolean) => {
                        // esto es para los detalles definidos sin accion de wf
                        if (response && hasLayoutComponent(CrudTableTypeConst.LAYOUT_DETAIL) && !hasLayoutWorkFlow(CrudTableTypeConst.LAYOUT_DETAIL, CrudTableTypeConst.LAYOUT_DETAIL_COMMAND_DETAIL)) {
                            addCustomTab(-1, [])
                        }
                    }
                    event.callBackOk = doCallBack;

                    emit('click:viewDetail', event);
                    break;
                case 'click:SelectRow':
                    setKeyData(event);
                    emit('click:SelectRow', event);
                    break;
                case 'click:addCustom':
                    setKeyData(event);
                    emit('click:addCustom', event);
                    break;
            }


        }

        const buildHeader = () => {
            if (keyDataObject.value && props.tabHeader) {
                let headerText = props.tabHeader;
                const keys = Object.keys(keyDataObject.value);
                const keysHeader = props.tabHeader.split('#')
                keysHeader.forEach((keyheader: string) => {
                    let keyData = keys.find(y => y.toLowerCase().endsWith(DataSourceConst.DELIMITERFIELDS + keyheader.toLowerCase() + DataSourceConst.DELIMITERFIELDFORMAT.toLowerCase()));
                    if (keyData == undefined) {
                        keyData = keys.find(y => y.toLowerCase().endsWith(DataSourceConst.DELIMITERFIELDS + keyheader.toLowerCase()));
                    }
                    if (keyData) {
                        //@ts-ignore:disable-next-line
                        headerText = headerText.replaceAll('#' + keyheader + '#', keyDataObject.value[keyData])
                    }

                })

                return headerText;


            }


            return props.tabHeader;
        }

        const reloadTab = (key: string) => {

            const tabData = itemTabs.value.find(x => x.key == key);
            if (tabData) {

                const callBackEvent = (response: boolean) => {
                    if (response) {
                        tabData.layoutHeaderData = getLayoutComponent(CrudTableTypeConst.LAYOUT_HEADER);
                        tabData.layoutButtonsData = getLayoutComponent(CrudTableTypeConst.LAYOUT_HEADER_BUTTONS);
                        tabData.layoutDetailData = getLayoutComponent(CrudTableTypeConst.LAYOUT_DETAIL);
                        tabData.keyInternal = HelperUtils.newGuid();
                        triggerRef(itemTabs);
                    }

                }

                const tabDataKey = JSON.parse(tabData.key);

                let filter: Partial<SearchData>[] = getFilterRow(tabDataKey);



                var doCallback = (response: any): void => {

                    if (response) {
                        tabData.keyData = response?.data[0];
                    }

                    const evt: any = {
                        keys: tabDataKey,
                        item: tabData.keyData,
                        callBackOk: callBackEvent
                    }

                    //actualizamos tab header
                    refreshTabHeader(tabData, evt);

                    //actualizamos fila del crud
                    updateCrudRow(tabDataKey, evt?.item);

                    //finalmente emitimos evento
                    emit('click:SelectRow', evt);

                }

                doRefreshData(undefined, filter as any, doCallback)

            }
        }

        const getFilterRow = (tabDataKey: any): Partial<SearchData>[] => {

            let filters: Partial<SearchData>[] = [];

            tabDataKey?.forEach(tabKey => {

                const fieldId = tabKey?.fieldId.split('_')[0];
                const value = tabKey?.value?.toString().split('_')[0];

                const rowToFind: Partial<SearchData> = ({
                    //@ts-ignore:disable-next-line
                    id: HelperUtils.newGuid().toString().replaceAll('-', ''),
                    fieldId: fieldId,
                    filter: FiltroBusquedaConst.FILTROBUSQUEDA_IGUAL,
                    value: value,

                });

                filters.push(rowToFind);


            })

            return filters;
        }

        const refreshTabHeader = (tabData: any, evt: any) => {
            if (tabData && evt) {
                setKeyData(evt);
                tabData.header = buildHeader();
            }
        }

        const updateCrudRow = (keys: any, data: any) => {
            const crudIndex = dataSearchResult.value.findIndex(row => {
                return keys.every(k => row[k.fieldId] === k.value)
            })

            if (crudIndex > -1 && data) {
                dataSearchResult.value[crudIndex] = data;
            }

        }

        const addNavTab = (componentId: Number, tab: TabData) => {
            const caption = router.currentRoute.value.meta?.caption as any + (' <b>' + tab.header + '</b>');
            const rootId = router.currentRoute.value.meta?.componentId ?? -1;

            const _parameters = props.Component.rootParentId == rootId ? {
                        componentId: props.Component.rootParentId,
                        operation: "addtab",
                        row: JSON.parse(JSON.stringify(keyDataObject.value))
                    }: null;

            HelperNavigation.addPathToBrowsing(store, router.currentRoute.value.path, caption,
                {
                    menuId: Number(router.currentRoute.value.name ?? '-1'),
                    comps: [props.Component.rootParentId, componentId, props.Component.id],
                    //key:keyData.value,
                    parameters: _parameters,
                });
        }

        const addCustomTab = (componentId: number, attrs: IapComponentAttribute[]) => {
            const keyTab = JSON.stringify(keyData.value);
            const tabPost = findTab(keyTab);

            if (tabPost != -1) {
                navigateTab(tabPost);
                const tabData = itemTabs.value.find(x => x.key == keyTab);
                if (tabData) {
                    addNavTab(componentId, tabData);
                }

            }
            else {
                const data: TabData = {
                    key: keyTab,
                    keyData: keyDataObject.value,
                    applicationId: props.Component.applicationId,
                    applicationVersion: props.Component.applicationVersion,
                    componentId: componentId,
                    closable: true,
                    header: buildHeader(),
                    keyInternal: '',
                    layoutHeaderData: getLayoutComponent(CrudTableTypeConst.LAYOUT_HEADER),
                    layoutButtonsData: getLayoutComponent(CrudTableTypeConst.LAYOUT_HEADER_BUTTONS),
                    layoutDetailData: getLayoutComponent(CrudTableTypeConst.LAYOUT_DETAIL),
                    attrs: attrs,
                    detailComponentId: -1,
                    detailApplicationId: -1,
                    detailApplicationVersion: -1,
                    detailAttrs: []

                }

                addTab(data);
                addNavTab(componentId, data);
            }

        }


        const doMenuItemEvent = (evt: { componentId: number, header: string, modal: boolean, attrs: IapComponentAttribute[], callBackResponse: any }) => {
            const callBackEvent = (response: boolean) => {

                if (response) {


                    var intervalID = setInterval(
                        () => {

                            const key = JSON.stringify(keyData.value)
                            const tabData = itemTabs.value.find(x => x.key == key);
                            if (tabData) {
                                clearInterval(intervalID);
                                tabData.detailApplicationId = props.Component.applicationId;
                                tabData.detailApplicationVersion = props.Component.applicationVersion;
                                tabData.detailComponentId = evt.componentId;
                                tabData.detailAttrs = evt.attrs;
                            }
                        }, 400);

                }

            }


            emit('click:viewDetail', {
                keys: keyData.value,
                item: keyDataObject.value,
                callBackOk: callBackEvent
            },
                true
            );

            if (evt.callBackResponse) {
                evt.callBackResponse(true)
            }
        }



        const doNavigateEvent = (evt: { data: InteractionEvent, callBackResponse: any }) => {
            let data = (_ => { try { return JSON.parse(evt.data.objectValue as string); } catch (err) { return evt.data?.objectValue ??  undefined; } })();
            let _operation ='';            
            let _row :any ;

            if (Array.isArray(data)){
                _operation = (data as Tuple<String,any,any>[]).find( x=> x.key.trim() == 'operation' && ( (x.value.value1 ?? '').toString().trim()  == props.Component.id.toString() || (x.value.value1 ?? '').toString().trim()  == ''))?.value;
                _row = (data as Tuple<String,any,any>[]).find( x=> x.key.trim() == 'row' && ( (x.value.value1 ?? '').toString().trim()  == props.Component.id.toString() || (x.value.value1 ?? '').toString().trim()  == ''))?.value;
            }
            else{
                _operation = data?.operation.toLowerCase();
                _row = data?.row
            }

            if (_operation?.trim() == 'addtab' && _row) {
                const { generateAllFormatColCrud, tablasData, getDataKeys } = DataSourceComp(props.container as any, props, emit, {}, datasource.value as any, catalogos.value, store)

                if (Array.isArray(_row)) {
                    generateAllFormatColCrud(_row, false)

                    _row.forEach((obj: any) => {
                        tryToNavigate(obj, tablasData, getDataKeys)
                    })
                }
                else {
                    generateAllFormatColCrud([_row], false)
                    tryToNavigate(_row, tablasData, getDataKeys)
                }

                router.currentRoute.value.meta.tag = null;
                const _route = router.getRoutes().find(x => x.path == router.currentRoute.value.fullPath);
                if (_route) {
                    _route.meta.tag = null;
                }
            }
            if (evt.callBackResponse) {
                evt.callBackResponse(true)
            }
        }

        const doComponentEvent = (evt: { componentId: number, header: string, modal: boolean, attrs: IapComponentAttribute[], callBackResponse: any }) => {
            addCustomTab(evt.componentId, evt.attrs);

            if (evt.callBackResponse) {
                evt.callBackResponse(true)
            }
        }


        const bindEvents = () => {
            if (props.showTabMode) {

                // resto es para mostrar las acciones de los menuitems de las acciones que muestren componentes dentro de cada tab
                keyMenuItemsEventComponent.forEach((keyMenuItem: string) => {
                    EventBusCustom.on(keyMenuItem, doMenuItemEvent)
                })


                // esto es para mostrar los detalles
                EventBusCustom.on(keyEventComponent, doComponentEvent)

            }
            // esto es para navegar
            EventBusCustom.on(keyEventComponentNavigation, doNavigateEvent)

        }

        const unBindEvents = () => {
            if (props.showTabMode) {
                EventBusCustom.off(keyEventComponent, doComponentEvent)

                keyMenuItemsEventComponent.forEach((keyMenuItem: string) => {
                    EventBusCustom.off(keyMenuItem, doMenuItemEvent)
                })
            }

            EventBusCustom.off(keyEventComponentNavigation, doNavigateEvent)
        }


        const clearFilter = () => {
            if (props.showSearch && searchComp.value) {
                if (Array.isArray(searchComp.value)) {
                    searchComp.value.find(x => x !== undefined)?.clearFilterData();
                }
                else {
                    searchComp.value.clearFilterData();
                }

            }
        }

        const tryToNavigate = (obj: any, tablasData: any, getDataKeys: any) => {
            let datakeys: clave[] = []
            tablasData.value.map(x => x.tableName).filter((value: any, index: any, self: any) => self.indexOf(value) === index).forEach(tableName => {
                const _keys = getDataKeys(obj, tableName)
                _keys.forEach(_k => {
                    datakeys.push(_k)
                })
            })

            catchEvent('click:viewDetail', { keys: datakeys, item: obj })
        }
     
        const getFiltersFromDetail = (event: any) => {
            columnFilters.value = event;
        }
        const getSortDataFromDetail = (event: any) => {
            sortData.value = event;
        }
        const setNewFilters = (event: any) => {
            newFilters.value = event;
            columnFilters.value = event;
        }

        const setNewSortData = (event: any) => {
            newSortData.value = event;
            sortData.value = event;
        }

        const onSelectAllRows = () => {
            selectAll.value = true;
            doRefreshData({ event: undefined, dataProvider: [{ data: OperationLazyDataProviderConst.COUNT, columnRequest: [] }, { data: OperationLazyDataProviderConst.ITEMS, columnRequest: [] }] });
        }


        const onLazyScroll = (event: any) => {
            const index = virtualData.value.filter(x => x != undefined).length;
            if (event?.event?.last > index) {
                first.value = index;
                originalFirst.value = event?.event?.first;
                rows.value = (event?.event?.last - index) < 0 ? 25 : (event?.event?.last - index);
                last.value = event?.event?.last;
                doRefreshData(event);
            }

        }

        onMounted(() => {
            if (props.Component?.id) {
                datasource.value = props.Component.iapComponentDataSources.find(x => x !== undefined)
                var catalogoTipoIds = datasource.value?.dataSource.iapDataSourceFields.filter(x => x.catalogTypeId).map(x => x.catalogTypeId) ?? [];
                catalogoTipoIds.push(CatalogTypeConst.FILTERBUSQUEDA);
                catalogoTipoIds.push(CatalogTypeConst.MATCHMODE);
                const data = helperCatalog.getAllCatalogApp();
                catalogos.value = data.filter(x => x.iapCatalogs).filter(x=>catalogoTipoIds.includes(x.id)).flatMap(x => x.iapCatalogs)
                doSearch()
                initTabs();
                buildkeyMenuItemsEventComponent();
                bindEvents();
                //checkNavigateToTab();
            }
            tabIndex.value = selectedTab.value;

        });


        const clearAllVars = () => {
            datasource.value = null as any;
            searchComp.value = null as any;
            catalogos.value = [] as any;
            dataSearchFilter.value = [];
            groupSearch.value = null as any;
            dataSearchResult.value = null as any;
            keyData.value = null as any;
            keyDataObject.value = null as any;
            newFilters.value = null as any;
            newSortData.value = null as any;
            columnFilters.value = null as any;
            sortData.value = null as any;

            last.value = null as any;
            originalFirst.value = null as any;
            lazyData.value = [];
            virtualData.value = [];
        }


        onBeforeUnmount(() => {
            clearAllTabData();
            unBindEvents();
            clearAllVars();

        })


        onActivated(() => {
            //checkNavigateToTab();
        });

        return {
            hasData,
            datasource,

            dataSearchFilter,
            dataSearchResult,
            catalogos,
            filterTables,
            hasLoadCustomSearchData
            , searchComp
            , doRefreshData
            , doSearch
            , selectedTab
            , changeTab
            , itemTabs
            , SEARCH_KEY
            , RESULT_KEY
            , getTotalRows
            , catchEvent
            , customCloseTab
            , doChangeTab
            , closeAllTabs
            , CrudTableTypeConst
            , getLayoutComponent
            , reloadTab
            , currentTab
            , clearFilter
            , tabIndex
            , getFiltersFromDetail
            , columnFilters
            , setNewFilters
            , newFilters
            , groupSearch
            , getSortDataFromDetail
            , sortData
            , newSortData
            , setNewSortData
            , totalRecords
            , onSelectAllRows
            , onLazyScroll
            , lazyData
            , virtualData
            , lazyLoading
            , getDynamicLayoutComponentData
            , getDynamicLayoutComponent

        };
    },
});
</script>
<style lang="scss" scoped>
::v-deep(.p-tabview-nav li.p-highlight .p-tabview-nav-link) {
    outline: 0 none !important;
    outline-offset: 0 !important;
    box-shadow: inset 0 0 0 0.2rem #bfdbfe !important;
}
</style>
