<template>
<div  v-if="loaded" :key="componentKey" :class="containerClass" >
      <!--label-->
    <label for="" :class="labelClass">{{ labelDesc }}</label>
    <div class="mt-1 flex flex-grow-1">
        <!--filtro-->
        <div v-if="showFilter && filterVisible && showFilterIcon" @mouseover="setTooltip()"  >
            <Dropdown v-model="vmodelFilter" :options="getCatalogFilterByFieldType(field)" optionLabel="value"  
                 optionValue="id" :title="toolTipDD" :class="filterClass" class="mr-1"
                @change="handleEvt($event, ControlTypeConst.CTDROPDOWN)">
                <template #option="slotProps">
                    <div v-tooltip="getToolTipFilter(slotProps.option)" style="width: 100%; height: 100%;"> {{
                        slotProps.option.value }}</div>
                </template>
            </Dropdown>
        </div>

        <!--input-->
        <div v-if="!notShowControl.includes(vmodelFilter)"  class="flex flex-grow-1">
            <LookUpEditor v-if="inputType == ControlTypeConst.CTLOOKUPEDITOR" v-model="vmodel"
                :dataSourceLookUp="getDsLookUp()" :container="container" :filterConditions="[]" :showSearch="true"
                :showClear="true"></LookUpEditor>

            <MultiSelect v-if="inputType == ControlTypeConst.CTMULTISELECT" v-model="vmodel" :options="getOptions()"
                :filter="true" optionLabel="description" optionValue="id"  class="flex-grow-1" />

            <Dropdown v-if="inputType == ControlTypeConst.CTDROPDOWN" v-model="vmodel" :options="getOptions()"
                :filter="true" optionLabel="description" optionValue="id"  showClear  class="flex-grow-1"/>

            <InputSwitch v-if="inputType == ControlTypeConst.CTINPUTSWITCH" v-model="vmodel" class="mr-2"
                :trueValue="'1'" :falseValue="'0'" />

            <TriStateCheckbox v-if="inputType == ControlTypeConst.CTTRISTATECHECKBOX" v-model="vmodel" />

            <InputText v-if="inputType == ControlTypeConst.CTINPUTTEXT" v-model="vmodel" class="flex-grow-1"/>

            <Calendar
                v-if="inputType == ControlTypeConst.CTCALENDAR && vmodelFilter != FiltroBusquedaConst.FILTROBUSQUEDA_DAYSAGO"
                ref="calendarComponentRef" :showButtonBar="true" v-model="vmodel" :manualInput="true"
                :selectionMode="selectionMode"   class="flex-grow-1"/>

            <InputNumber
                v-if="inputType == ControlTypeConst.CTCALENDAR && vmodelFilter == FiltroBusquedaConst.FILTROBUSQUEDA_DAYSAGO"
                v-model="vmodelInputDate" showButtons :inputStyle="'width: 60px'" placeholder="Días..."
                @update:modelValue="handleEvt({ value: $event }, ControlTypeConst.CTINPUTNUMBER)"  />

            <InputNumber v-if="inputType == ControlTypeConst.CTINPUTNUMBER" v-model="vmodel"  class="flex-grow-1" />

              <!--icon-->
        <i v-if="showFilter && filterVisible" :class="iconClass" style="font-size: 1rem" class="ml-1 flex align-items-center"
                    @click="showFilterIcon = !showFilterIcon"></i>

        </div>


     
    </div>

</div>





</template>
<script lang="ts">

import { Container } from 'inversify';
import { defineComponent, onBeforeUnmount, ref, shallowRef, watch, onMounted, computed } from 'vue';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import SummaryDebug from '../../shared/SummaryDebug.vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { useStore } from 'vuex';
import SearchControlTypeConst from '../../../../domain/Constants/SearchControlTypeConst';
import ControlTypeConst from '../../../../domain/Constants/ControlTypeConst';
import DataSourceComp from '../../../../../crud/infrastructure/functions/dataSourceComp';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import SearchHelper from '@ilinium/shared/src/entidades/builder/crud/infrastructure/functions/searchHelper';
import HelperCommon from '../../../../../../../common/infrastructure/funciones/HelperCommon';
import InteractionConst from '@ilinium/shared/src/entidades/builder/interaction/domain/interactionConst';
import FiltroBusquedaConst from '../../../../../../../common/domain/constantes/FiltroBusquedaConst';
import CatalogSearchControlLayoutConst from '../../../../../catalog/domain/const/CatalogSearchControlLayoutConst';





export default defineComponent({
    name: 'dynamic_search_control',
    components: {
        SummaryDebug,
        TriStateCheckbox
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
        slotProps: {
            type: Object,
            default: () => ({})
        },

    },
    setup(props, context) {



       
        const store = useStore();
        const { vmodel, baseOnMounted, baseOnBeforeUnmount, componentKey, getPropertyValue, getPropertyBooleanValue, getDsLookUp, getModelValueKeys, getComponentDataSourceFromModelValue, catalogTypes, notifySearchForm,loaded,isSearchFormChildren} = ComponentCommonRender(props.Component, props.slotProps, props.container, store)
        const { getCatalogData, isFilterVisible, initFilterField } = DataSourceComp(props.container as any, props, context.emit, {}, {} as any, catalogTypes().flatMap(x => x.iapCatalogs), store, {} as any)
        const { getCatalogFilterByFieldType, notShowControl, dateFilter } = SearchHelper(props.container as any, {} as any, catalogTypes().flatMap(x => x.iapCatalogs), store)
        const { INPUTTYPE, INPUTCLASS, LABELDESC, LABELCLASS, CLASS, ICONCLASS, REQUIRED, ENABLEFILTERICON, DISABLEFILTERICON, SHOWFILTER, DATAVALUE,LAYOUT,FILTERCLASS } = SearchControlTypeConst;
        const {INLINE}=CatalogSearchControlLayoutConst;


        const getOrDefault = (propertyConst: string, defaultValue: any) => {
            const value = getPropertyValue(propertyConst);
            return !HelperCommon.isNullOrWhitespace(value ?? '') ? value : defaultValue;
        };

        const layout = getPropertyValue(LAYOUT)
        const inputType = getPropertyValue(INPUTTYPE)
        const inputClassProp = getPropertyValue(INPUTCLASS)
        const labelDesc = getPropertyValue(LABELDESC)
        const labelClassProp = getPropertyValue(LABELCLASS)
        const filterClassProp = getPropertyValue(FILTERCLASS)
        const searchCtrlClass = getPropertyValue(CLASS)
        const iconClassProp = getOrDefault(ICONCLASS, 'text-gray-500');
     
        const customrequired = getPropertyBooleanValue(REQUIRED)
        const enableFilterIcon = getOrDefault(ENABLEFILTERICON, 'pi pi-filter');
        const disableFilterIcon = getOrDefault(DISABLEFILTERICON, 'pi pi-filter-slash');
        const vmodelFilter = shallowRef()
        const vmodelInputDate = shallowRef()
        const showFilterIcon = shallowRef(false)
        const calendarComponentRef = ref();
        const showFilter = getPropertyBooleanValue(SHOWFILTER)
        const filterVisible = shallowRef(false)
        const toolTipDD = shallowRef('');
        const selectionMode = shallowRef('single');
        let oldFilter: string | undefined = '';

        const containerLayoutClass =layout==INLINE?'md:flex':'';
        const labelLayoutClass = layout==INLINE?'md:flex md:align-items-center md:justify-content-end md:mr-1':'';
 

        const containerClass = computed(() => ({
            [containerLayoutClass || '']: true,
            [searchCtrlClass || '']: true,
            customrequired: customrequired,
            }));


        const labelClass = computed(() => ({
        [labelLayoutClass || '']: true,
        [labelClassProp || '']: true,
        }));

        
        const filterClass = computed(() => ({
        [filterClassProp || '']: true,
        }))

          
        const inputClass = computed(() => ({
        [inputClassProp || '']: true,
        
        }))

        const iconClass = computed(() => ({
        [iconClassProp || '']: true,
        [disableFilterIcon ?? '']: showFilterIcon.value,
        [enableFilterIcon ?? '']: !showFilterIcon.value

        }))



        watch(() => oldFilter, (newValue, oldValue) => {
            oldFilter = oldValue;
        }, { immediate: true, deep: true });
        


        watch(() => vmodel.value, (newValue, oldValue) => {
            
            if (typeof newValue === 'object' && 'fieldId' in newValue) {
                setvmodel(newValue)
                vmodelFilter.value = newValue?.filter;
                if (inputType == ControlTypeConst.CTCALENDAR && newValue.filter == FiltroBusquedaConst.FILTROBUSQUEDA_DAYSAGO) {
                    vmodelInputDate.value = newValue?.valueNumber;
                }
            } else {
                if (loaded.value && isSearchFormChildren()) {
                    notifySearchForm({ value: newValue, component: props.Component }, InteractionConst.SEARCH_FORM_VMODEL_CHANGE);
                }
            }
        }, { immediate: true, deep: true });


        const setvmodel=(searchData:any)=>{
            vmodel.value=getvmodelValue(searchData);
        }

        const getvmodelValue = (searchData: any) => {
            if (inputType == ControlTypeConst.CTMULTISELECT) {
                return searchData.valueList;
            } else if (inputType == ControlTypeConst.CTDROPDOWN) {
                return Array.isArray(searchData.valueList) ? searchData.valueList[0] : searchData.valueList;
            }
            else if (inputType == ControlTypeConst.CTINPUTSWITCH || inputType == ControlTypeConst.CTTRISTATECHECKBOX) {
                if (searchData.value === '1') return true;
                if (searchData.value === '0') return false;
                return searchData.value;
            }
            else if (inputType == ControlTypeConst.CTCALENDAR) {
                if (searchData.filter == FiltroBusquedaConst.FILTROBUSQUEDA_ENTRE) {
                    return [searchData?.rangeDateTime.from, searchData?.rangeDateTime.to].filter(x => !HelperCommon.isNullOrNotDefined(x))
                }
                else {
                    return searchData?.rangeDateTime.from;
                }
            }
            else {
                return searchData?.value;
            }
        }

        const field = computed(() => {
            const modelValueKeys = getModelValueKeys();
            if (!modelValueKeys) return undefined;

            if ('dataSourceFieldId' in modelValueKeys) {
                const { dataSourceFieldId } = modelValueKeys;
                const componentDataSource = getComponentDataSourceFromModelValue();
                return componentDataSource?.dataSource?.iapDataSourceFields?.find(f => f.id === dataSourceFieldId);
            }

        });

        const getOptions = () => {

            if (!field?.value?.catalogTypeId) return [];

            return getCatalogData(field.value.catalogTypeId) ?? [];

        }

        const fixCalendarFocusTrap = () => {
            const componentExists = !!calendarComponentRef.value;
            if (!componentExists) return;

            const alreadyMonkeyPatched =
                !!calendarComponentRef.value.originalUpdateFocusFunc;
            if (alreadyMonkeyPatched) return;

            calendarComponentRef.value.originalUpdateFocusFunc =
                calendarComponentRef.value.updateFocus;

            calendarComponentRef.value.updateFocus = () => {
                const inputEl = calendarComponentRef.value.input;
                const inputElHasFocus = inputEl && inputEl == document.activeElement;
                if (!inputElHasFocus) {
                    calendarComponentRef.value.originalUpdateFocusFunc();
                }
            };
        };


        watch(calendarComponentRef, fixCalendarFocusTrap, { immediate: true });


        const setTooltip = () => {
            const cat = getCatalogFilterByFieldType(field.value)
            toolTipDD.value = cat.find(x => x.id == vmodelFilter.value)?.description ?? '';
        };

        const getToolTipFilter = (option: any) => {
            return option?.description;
        }

        const setItemEmpty = (event: any) => {

            const filterDel = dateFilter.filter(x => x !== FiltroBusquedaConst.FILTROBUSQUEDA_ENTRE);

            if (notShowControl.includes(event?.value ?? event) || filterDel.includes(oldFilter ?? '')/*o se ha cambiado de un filtro calculado fecha a otro*/) {
                notifySearchForm({ value: null, component: props.Component }, InteractionConst.SEARCH_FORM_VMODEL_CHANGE);
            }


        }


        const handleEvt = (evt: any, controlType: string) => {
            if (controlType == ControlTypeConst.CTDROPDOWN) { 
                setItemEmpty(evt.value) 
                selectionMode.value = evt.value === FiltroBusquedaConst.FILTROBUSQUEDA_ENTRE ? 'range' : 'single';
            }

            const component = JSON.parse(JSON.stringify(props.Component))
            setValues(component, controlType)
            notifySearchForm({ value: evt.value, component: component }, InteractionConst.SEARCH_FORM_VMODEL_CHANGE);
        }

        const setValues = (component: ComponentDataForm, controlType: string) => {
            component.idType = controlType;
            const attr = component.iapComponentAttributes.find(attr => attr.name == DATAVALUE);
            if (attr && field.value) {
                attr.value = field.value.field;
            } else {
                component.iapComponentAttributes.push({ name: DATAVALUE, value: field?.value?.field } as any)
            }
        }



        onMounted(() => {
            baseOnMounted();
            if (field.value) {
                filterVisible.value = isFilterVisible(field.value) ?? false
                vmodelFilter.value = initFilterField(field.value)
            }

        });

        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
        })


        return {
            vmodel,
            componentKey,
            getPropertyValue,
            getPropertyBooleanValue,
            SearchControlTypeConst,
            inputType,
            ControlTypeConst,
            getDsLookUp,
            labelDesc,
            customrequired,
            getOptions,
            inputClass,
            showFilter,
            vmodelFilter,
            getCatalogFilterByFieldType,
            field,
            showFilterIcon,
            iconClass,
            calendarComponentRef,
            enableFilterIcon,
            disableFilterIcon,
            filterVisible,
            toolTipDD,
            setTooltip,
            getToolTipFilter,
            notShowControl,
            handleEvt,
            vmodelInputDate,
            FiltroBusquedaConst,
            selectionMode,
            loaded,
            containerClass,
            labelClass,
            filterClass,

            layout,
            CatalogSearchControlLayoutConst,
            

        };
    },
});
</script>
<style lang="scss" scoped>


</style>
<style scoped></style>
