import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-9c870fb8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_FullCalendar = _resolveComponent("FullCalendar")!
  const _component_NewRegister = _resolveComponent("NewRegister")!
  const _component_FormBuilder = _resolveComponent("FormBuilder")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW))
      ? _withDirectives((_openBlock(), _createBlock(_component_FullCalendar, {
          key: _ctx.componentKey,
          ref: "fullCalendar",
          id: _ctx.getPropertyValue(_ctx.FullCalendarTypeConst.ID),
          style: _normalizeStyle(_ctx.getPropertyValue(_ctx.FullCalendarTypeConst.STYLE)),
          class: _normalizeClass({ [_ctx.getPropertyValue(_ctx.FullCalendarTypeConst.CLASS) ?? '']: true }),
          name: _ctx.getPropertyValue(_ctx.FullCalendarTypeConst.NAME),
          disabled: _ctx.getPropertyBooleanValue(_ctx.FullCalendarTypeConst.DISABLED) || (!_ctx.canEdit),
          options: _ctx.options
        }, null, 8 /* PROPS */, ["id", "style", "class", "name", "disabled", "options"])), [
          [_directive_tooltip, _ctx.getPropertyValue(_ctx.FullCalendarTypeConst.TOOLTIP)]
        ])
      : _createCommentVNode("v-if", true),
    _createVNode(_component_Dialog, {
      visible: _ctx.eventDialog,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.eventDialog) = $event)),
      styleClass: "mx-3 sm:mx-0 sm:w-full md:w-8 lg:w-6",
      modal: true,
      closable: true,
      maximizable: true
    }, {
      header: _withCtx(() => [
        _createElementVNode("span", {
          innerHTML: _ctx.clickedEvent.title,
          class: "text-900 font-semibold text-xl"
        }, null, 8 /* PROPS */, _hoisted_1)
      ]),
      default: _withCtx(() => [
        (!_ctx.hasCustomViewComponent && !_ctx.hasCustomEditComponent)
          ? (_openBlock(), _createBlock(_component_NewRegister, {
              key: 0,
              container: _ctx.container,
              compDataSource: _ctx.datasource,
              catalogosData: _ctx.catalogos,
              rootParentId: _ctx.Component.rootParentId,
              Component: _ctx.Component,
              formKey: _ctx.Component.formKey,
              canUpdate: _ctx.canUpdateRow,
              canDelete: _ctx.canUpdateRow,
              recordToUpdate: _ctx.recordToUpdate,
              onRefreshData: _cache[0] || (_cache[0] = ($event: any) => {_ctx.eventDialog = false; _ctx.loadEventsData()})
            }, null, 8 /* PROPS */, ["container", "compDataSource", "catalogosData", "rootParentId", "Component", "formKey", "canUpdate", "canDelete", "recordToUpdate"]))
          : (_ctx.componentInputId>0)
            ? (_openBlock(), _createBlock(_component_FormBuilder, {
                key: 1,
                container: _ctx.container,
                componentId: _ctx.componentInputId,
                applicationId: _ctx.Component.applicationId,
                applicationVersion: _ctx.Component.applicationVersion,
                attrsInput: _ctx.componentAttributesList,
                canDestroy: true,
                onClose: _cache[1] || (_cache[1] = ($event: any) => {_ctx.eventDialog = false; _ctx.loadEventsData()})
              }, null, 8 /* PROPS */, ["container", "componentId", "applicationId", "applicationVersion", "attrsInput"]))
            : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["visible"])
  ], 64 /* STABLE_FRAGMENT */))
}