<template>
    <div class="flex justify-content-center">
        <DataTable
            :value="dataSearch"  :paginator="true" :rows="5" resizableColumns columnResizeMode="fit" style="width:100%">

            <Column field="name" header="Nombre" sortable>
                <template #body="slotProps">
                    <Button :label="getDesc(slotProps.data)" link @click="setSearch(slotProps.data)" />
                </template>
            </Column>

            <Column  v-if="searchType==SearchConfigTypeConst.SEARCHCONFIGTYPE_LIST" field="data" header="Diferencias" :style="{ 'text-align': 'center' }">
                <template #body="slotProps">
                    <span class="font-bold">{{getDesc(slotProps.data,true)}}</span>
                    <i v-if="!HelperCommon.isNullOrWhitespace(getItemsDifference(slotProps.data))" class="pi pi-exclamation-circle text-red-500 ml-2" v-tooltip="'Ver diferencias'"  @click="openDifferenceOverLayPanel($event, slotProps.data)"></i>
                    <OverlayPanel ref="differenceOverLayPanel" :dismissable="false" :showCloseIcon="true">
                        <p v-html="getItemsDifference(dataDifOverLayPanel)"></p>
                    </OverlayPanel>
                </template>
            </Column>
            <Column field="data" header="Criterios" :style="{ 'text-align': 'center' }">
                <template #body="slotProps">
                    <i class="pi pi-question-circle text-color-secondary" v-tooltip="'Ver criterios'"
                        @click="openColOverLayPanel($event, slotProps.data)"></i>
                    <OverlayPanel ref="colOverLayPanel" :dismissable="false" :showCloseIcon="true">
                        <p v-html="formatData(dataOverLayPanel)"></p>
                    </OverlayPanel>
                </template>
            </Column>
            <Column v-if="searchType==SearchConfigTypeConst.SEARCHCONFIGTYPE_LIST">
                <template #body="{ data }">
                    <i v-if="data.userId==data.ucr" class="pi pi-file-edit" v-tooltip="'Editar'" 
                        @click="openSaveSearch($event,data,true)"></i>
                </template>
            </Column>
            <Column>
                <template #body="{ data }">
                    <i v-if="data.userId==data.ucr" class="pi pi-trash" v-tooltip="'Eliminar registro'" style="color:#D32F2F;"
                        @click="deleteSearch(data)"></i>
                </template>
            </Column>
            <Column v-if="searchType!=SearchConfigTypeConst.SEARCHCONFIGTYPE_NCUS">
                <template #header>
                    <Button v-if="addVisible" icon="pi pi-plus"  rounded raised  @click="openSaveSearch($event,data,false)" />
                </template>
            </Column>
        </DataTable>
        <OverlayPanel ref="opSaveSearch" :dismissable="false" :showCloseIcon="true">
            <card>
                <template #title>{{searchType!=SearchConfigTypeConst.SEARCHCONFIGTYPE_LIST?'Nueva búsqueda':'Nueva Lista'}} </template>
                <template #content>
                    <div class="card m-2">
                        <div class="grid">
                            <div :class="{'col-12 lg:col-6':searchType==SearchConfigTypeConst.SEARCHCONFIGTYPE_LIST,'col-12':searchType!=SearchConfigTypeConst.SEARCHCONFIGTYPE_LIST}">
                                <span class="p-float-label mt-3">
                            <InputText id="name" v-model="data.name" type="text" 
                                :class="{ 'p-invalid': v$.name.$error && submitted,'w-full':true }" aria-describedby="text-error" />
                            <label for="name"
                                :class="{ 'p-invalid': v$.name.$error && submitted, 'customrequired': true }">Nombre</label>

                        </span>
                        <span v-if="submitted">
                            <span v-for="error in v$.name.$errors" :key="error.$uid">
                                <small class="p-error">{{ error.$message }}</small>
                            </span>
                        </span>
                           </div> 
                           <div  v-if="searchType==SearchConfigTypeConst.SEARCHCONFIGTYPE_LIST" class="col-12 lg:col-6">
                            
                            <span  class="p-float-label mt-3">
                                    <Dropdown id="fieldlist"  v-model="fieldList" :options="fieldsListOptions" optionLabel="name" optionValue="id"  :class="{ 'p-invalid': v$.fieldList.$error && submitted }"  placeholder="Seleccione campo" filter :filterInputProps="filterInputProperties"  @change="stopPropagateEventDropDown($event)" />
                                    <span><i class="pi pi-download ml-1 text-primary" v-tooltip="'Descargar valores de la búsqueda'" style="cursor:pointer" @click="doRecoverValues()"></i></span>
                                    <label for="fieldlist" :class="{ 'p-invalid': v$.fieldList.$error && submitted, 'customrequired': true }"  >Campo</label>
                            
                        </span>
                        <span v-if="submitted">
                                <span v-for=" error  in  v$.fieldList.$errors " :key="error.$uid">
                                    <small class="p-error">{{ error.$message }}</small>
                                </span>
                            </span>
                            
                           </div>

                           <SaveSearch_UserProfile  :value="userProfileData" :catalogosData="catalogosData" :searchConfigAll="searchConfigAll" :searchConfig="data" :isUpdate="formUpdate" @update:selectionKeys="onUpdateSelectionKeys"></SaveSearch_UserProfile>

                           <div v-if="searchType==SearchConfigTypeConst.SEARCHCONFIGTYPE_LIST" class="col-12">
                            <span  class="p-float-label mt-5">
                                <Textarea id="values" v-model="valuesList" rows="5" :class="{ 'p-invalid': v$.valuesList.$error && submitted }"
                                    aria-describedby="text-error"   style="width:95%;"/>
                                <label for="values" :class="{ 'p-invalid': v$.valuesList.$error && submitted, 'customrequired': true }">Valores</label>
                            </span>
                            <span v-if="submitted">
                                <span v-for=" error  in  v$.valuesList.$errors " :key="error.$uid">
                                    <small class="p-error">{{ error.$message }}</small>
                                </span>
                            </span>
                           </div>
                           </div> 
                      
                           </div> 
                           <div class="flex justify-content-end mt-2">
                            <Button :label="formUpdate?'Actualizar':'Añadir'" class="mr-1"  @click="executeOperation" />
                            <Button label="Cancelar" severity="danger" @click="hideSaveSearch"/>
                           </div>
                </template>
            </card>

        </OverlayPanel>

    </div>
</template>
<script lang="ts">

import { Container } from 'inversify';
import { computed, defineComponent,  onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { IampSearchConfig } from '../../../../builderMaster/user/domain/iapmSearchConfig';
import useVuelidate from '@vuelidate/core';
import { helpers, required,requiredIf } from '@vuelidate/validators';
import HelperCommon from '../../../../../common/infrastructure/funciones/HelperCommon';
import HelperLoading from '../../../../../common/infrastructure/funciones/HelperLoading';
import { IServiceSearchConfig } from '../../../../builderMaster/user/application/IServiceSearchConfig';
import { TYPES } from '../../../../../common/domain/types';
import { IapComponentDataSource } from '../../../component/domain/iapComponentDataSource';
import { IapCatalog } from '../../../catalog/domain/iapCatalog';
import { MessageService } from '../../../../../common/infrastructure/servicios';
import { MessageType } from '../../../../../common/infrastructure/servicios/MessageService';
import FiltroBusquedaConst from '../../../../../common/domain/constantes/FiltroBusquedaConst';
import ControlTypeConst from '../../../form/domain/Constants/ControlTypeConst';
import { GroupSearch, LazyParams, SearchData } from '../../../search/domain/search';
import HelperUtils from '../../../../../common/infrastructure/funciones/HelperUtils';
import { IServiceSearch } from '../../../search/application/IServiceSearch';
import SqlTypesConst from '../../../../../common/domain/constantes/SqlTypesConst';
import DataSourceComp from '../functions/dataSourceComp';
import { useI18n } from 'vue-i18n';
import { messages } from './localization/MessagesBusqueda'
import DataSourceConst from '../functions/dataSourceConst';
import CatalogTypeConst from '../../../catalog/domain/const/CatalogTypeConst';
import { FilterMatchMode } from 'primevue/api';
import OperationLazyDataProviderConst from '../../../search/domain/Const/OperationLazyDataProviderConst';
import SearchConfigTypeConst from '../../../../builderMaster/user/domain/const/SearchConfigTypeConst';
import { DropdownChangeEvent } from 'primevue/dropdown';
import OperatorLogicConst from '../../../search/domain/Const/OperatorLogicConst';
import { IServiceUser } from '../../../../builderMaster/user/application/IServiceUser';
import { MultiSelectChangeEvent } from 'primevue/multiselect';
import SaveSearch_UserProfile from './SaveSearch_UserProfile.vue'
import { UserEntityProfile } from '../../../../builderMaster/user/domain/userEntityProfile';
import FilterMatchModeConst from '../functions/filterMatchModeConst';

export default defineComponent({
    name: 'savesearch',
    components:{
        SaveSearch_UserProfile
    },
    props: {
        container: {
            type: Object as () => Container
        },
        applicationId: {
            type: Number,
            default: -1
        },

        applicationVersion: {
            type: Number,
            default: -1
        },
        componentId: {
            type: Number,
            default: -1
        },
        dataFilter: Object,
        compDataSource: {
            type: Object as () => IapComponentDataSource,
            default: () => ({})
        },
        catalogosData: {
            type: Object as () => IapCatalog[],
            default: () => ([])
        },
        searchType: {
            type: String,
            default: ''
        },
        newAutomaticAdd: {
            type: Object as () => IampSearchConfig,
            default: () => ({})
        },
        columnFilters: {
            type: Object as () => any,
            default: {}
        },
        sortData: {
            type: Object as () => any[],
            default: []
        },

    },
    emits: ['click:setSearch'],
    setup(props, { emit }) {
        const store = useStore();
        const { t, locale } = useI18n(messages)
        const {
            adjustCdsAndClauses,
            getFieldFromKeyCol,
            clearEmptyFiltersList,
            adjustAllTreeSearchRequest,
            reorderSearchTree,
            getFieldKeyColumn
        } = DataSourceComp(props.container as any, props, emit, t, props.compDataSource, [] as any, store)
        const submitted = ref(false);
        const opSaveSearch = ref();
        const colOverLayPanel = ref();
        const differenceOverLayPanel = ref();
        const dataOverLayPanel = ref();
        const dataDifOverLayPanel = ref();
        const fieldList = ref();
        const valuesList = ref();
        const formUpdate=ref(false);
        const addVisible=ref(false);
        const userProfile=ref();
        const selectedKeys=ref();
        const userProfileData = ref<UserEntityProfile[]>();
        const data = ref<IampSearchConfig>({
            id:0,
            userId: store.getters.getCurrentUser.id,
            applicationId: props.applicationId,
            applicationVersion: props.applicationVersion,
            componentId: props.componentId,
            profileId: store.getters.getCurrentProfile,
            name: '',
            data:JSON.stringify(
                    {
                        searchFilters:props.dataFilter,
                        columnFilters: props.columnFilters,
                        multiSortMeta:props.sortData

                    }),
            fcr: new Date(),            
            idSearchConfigType:props.searchType,
            ucr:store.getters.getCurrentUser.id,
            group:null
        });

        const dataSearch = ref<IampSearchConfig[]>();
        const searchConfigAll = ref<IampSearchConfig[]>();
        const dateFilter:string[]=[FiltroBusquedaConst.FILTROBUSQUEDA_CURRENTMONTH,FiltroBusquedaConst.FILTROBUSQUEDA_CURRENTYEAR,FiltroBusquedaConst.FILTROBUSQUEDA_DAYSAGO,
            FiltroBusquedaConst.FILTROBUSQUEDA_PREVIOUSMONTH,FiltroBusquedaConst.FILTROBUSQUEDA_PREVIOUSYEAR,FiltroBusquedaConst.FILTROBUSQUEDA_PREVIOUSWEEK,FiltroBusquedaConst.FILTROBUSQUEDA_CURRENTWEEK,
            ];
        const dateFilterMatchMode=[FilterMatchModeConst.CURRENT_MONTH,FilterMatchModeConst.CURRENT_YEAR,FilterMatchModeConst.CURRENT_WEEK,FilterMatchModeConst.PREVIOUS_MONTH,FilterMatchModeConst.PREVIOUS_WEEK,FilterMatchModeConst.PREVIOUS_YEAR];
        // const includeCurrentProfile = ref(false);

        let objCount = ref(new Array());

        const dataSearchFilter = ref<GroupSearch>();

        watch(() => props.newAutomaticAdd, (newValue) => {
            if (dataSearch && dataSearch.value && props.searchType==SearchConfigTypeConst.SEARCHCONFIGTYPE_NCUS) {
                dataSearch.value.push(newValue);
                dataSearch.value = dataSearch.value.sort((a, b) => { return +new Date(b.fcr) - +new Date(a.fcr) })
            }

        }, { immediate: true, deep: true });

        

        const getInvalidValues= () =>{
            let invalidValues:any[]=[];
            
            if(!HelperCommon.isNullOrWhitespace(valuesList.value)){
                const field=props.compDataSource.dataSource.iapDataSourceFields.find(x=>x.id==fieldList.value);
                const valueListArr=getListValues();
                

                if(field){
                    if(intValues(field.sqlType)){
                        valueListArr.forEach(x=>{
                            if(!HelperCommon.evalInteger(x)){
                                invalidValues.push(x);
                            }
                        }) ;   
                    }
                    if(field.sqlType==SqlTypesConst.DECIMAL){
                        valueListArr.forEach(x=>{
                            if(!HelperCommon.evalDecimal(x)){
                                invalidValues.push(x);
                            }
                        }) ;   
                    }
                    if(field.sqlType==SqlTypesConst.NUMERIC){
                        valueListArr.forEach(x=>{
                            if(!HelperCommon.evalNumeric(x)){
                                invalidValues.push(x);
                            }
                        }) ;   
                    }
                    
                }

               
            }
            return invalidValues;
        }

        const intValues=(sqlType:string)=>{
            return (sqlType==SqlTypesConst.INT || sqlType==SqlTypesConst.BIGINT || sqlType==SqlTypesConst.SMALLINT || sqlType==SqlTypesConst.TINYINT);
        }

        const mssag=ref('');
        const reglas  = computed(() => ({
            name: {
                required: helpers.withMessage("El nombre es obligatorio", required),
            },
            fieldList: {
                required: helpers.withMessage('El campo es obligatorio', requiredIf((...args) => {
                    if(props.searchType==SearchConfigTypeConst.SEARCHCONFIGTYPE_LIST){
                        if(!HelperCommon.isNullOrWhitespace(fieldList.value)){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                    return false;
             })),
            },
            valuesList: {
                required: helpers.withMessage('El campo es obligatorio', requiredIf((...args) => {
                    if(props.searchType==SearchConfigTypeConst.SEARCHCONFIGTYPE_LIST){
                        if(!HelperCommon.isNullOrWhitespace(valuesList.value)){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                    return false;
             })),
             validList: helpers.withMessage( mssag.value, requiredIf((...args) => {
                    if(props.searchType==SearchConfigTypeConst.SEARCHCONFIGTYPE_LIST){
                        const invalValues=getInvalidValues();
                        if(invalValues.length==0){
                            return false;
                        }
                        else{
                            mssag.value='Los valores introducidos no son correctos: '+(invalValues.join(','));
                            return true;
                        }
                    }
                    return false;
             })),
            },
           
        }));

  

        const v$ = useVuelidate(reglas, data as any);

        const isValidData = (): boolean => {
            submitted.value = true;
            v$.value.$reset();
            v$.value.$touch();
            if (v$.value.$invalid) {
                HelperCommon.setFocusOnFirstError();
                return false;
            }

            return true;
        }

        
        const fieldsListOptions= computed(() => {
            return props.compDataSource?.iapComponentDataSourceFieldConfigurations.filter(x=>x.defaultFilterSearch && x.list).map(x=>({id:x.dataSourceFieldId,name:!HelperCommon.isNullOrWhitespace(x.dataSourceField?.description??'')?x.dataSourceField?.description:x.dataSourceField?.field}));
        });


        const openSaveSearch = (event: any,searchConfig:any=undefined,fromUpdate:boolean) => {
            if(fromUpdate){
                formUpdate.value=true;
            }
            else
            {
                formUpdate.value=false;
            }

            fillSearchForm(searchConfig);
            
            opSaveSearch.value.toggle(event);

        }
        const hideSaveSearch = () => {
            opSaveSearch.value.hide();

        }

        const fillSearchForm=(searchConfig:any)=>{

            if(searchConfig.idSearchConfigType==SearchConfigTypeConst.SEARCHCONFIGTYPE_LIST){
                if(formUpdate.value && searchConfig){
                    
                data.value=searchConfig;

                const searchFilter = JSON.parse(searchConfig.data)?.searchFilters as any;

                if(searchFilter){
                    fieldList.value=searchFilter.fields[0].fieldId;
                    valuesList.value=searchFilter.fields[0].valueList.join(",");
                }
              } 
              else{
                fieldList.value=null;
                valuesList.value=null;
                data.value={
                        id:0,
                        userId: store.getters.getCurrentUser.id,
                        applicationId: props.applicationId,
                        applicationVersion: props.applicationVersion,
                        componentId: props.componentId,
                        profileId: store.getters.getCurrentProfile,
                        name: '',
                        data:JSON.stringify(
                                {
                                    searchFilters:props.dataFilter,
                                    columnFilters: props.columnFilters,
                                    multiSortMeta:props.sortData

                                }),
                        fcr: new Date(),            
                        idSearchConfigType:props.searchType,
                        ucr:store.getters.getCurrentUser.id,
                        group:null
                        
                    }

              } 
            
            }
        }

        const openColOverLayPanel = (event: any, data: any) => {
            dataOverLayPanel.value = data;
            colOverLayPanel.value.toggle(event);
        }

        const openDifferenceOverLayPanel = (event: any, data: any) => {
            dataDifOverLayPanel.value = data;
            differenceOverLayPanel.value.toggle(event);
        }

        const getListValues=()=>{
            let arrValueList:string[]=[];
            if (valuesList.value.includes(',')) {
                arrValueList=valuesList.value.trim().split(','); 
            }
            else if (valuesList.value.includes(';')) {
                arrValueList= valuesList.value.trim().split(';'); 
            }
            else{
                arrValueList= valuesList.value.trim().split('\n');
            }

            return arrValueList.filter(x=>x.trim()!="");
            
        }

        const getSearchFilterList=()=>{

            const searchData:SearchData={
                id:HelperUtils.newGuid().toString().replaceAll('-',''),
                fieldId: fieldList.value as any,
                value:null as any,
                filter:FiltroBusquedaConst.FILTROBUSQUEDA_CONTIENE,
                valueList:getListValues(),
                valueBool:null as any,
                valueNumber:null as any,
                valueDateTime:null as any,
                rangeNumber:null as any,
                rangeDateTime: {
                                    from: null,
                                    to: null
                                },
                 rangeValue: {
                                    from: null,
                                    to: null
                                },
            }

            const groupSearchList:GroupSearch={
                operatorLogic:OperatorLogicConst.AND,
                fields: [searchData],
                children:[]
            }

            return JSON.stringify(
                    {
                        searchFilters:groupSearchList,
                        columnFilters:{},
                        multiSortMeta:{}

                    })
        }

        const saveData = () => {
            if (isValidData()) {
                
                if(props.searchType!=SearchConfigTypeConst.SEARCHCONFIGTYPE_LIST){
                    data.value.data = JSON.stringify(
                    {
                        searchFilters:props.dataFilter,
                        columnFilters: props.columnFilters,
                        multiSortMeta:props.sortData

                    })
                }
                else{
                    data.value.data=getSearchFilterList();
                }
               data.value.group=HelperUtils.newGuid();

               saveByUserProfile(data.value);

            }
        }

        const saveSearchConfig=(searchConfig:IampSearchConfig)=>{
            if (props.container) {
                    let dataInput = JSON.parse(JSON.stringify(searchConfig));
                    const _srv = props.container.get<IServiceSearchConfig>(
                        TYPES.SEARCH_USR_REPOSITORY
                    );
                    HelperLoading.showSaving();
                    _srv.add(dataInput as any)
                        .then((response) => {
                            if(response.userId==store.getters.getCurrentUser.id && (response.profileId==null || response.profileId==store.getters.getCurrentProfile )){
                                dataSearch.value?.unshift(response)
                            }
                            hideSaveSearch();
                            loadData();
                        })
                        .finally(() => {
                            HelperLoading.hideSaving();
                        });
                }
        }

        const saveByUserProfile=(dataToSave:IampSearchConfig)=>{
            
            dataToSave.profileId=null;

            let allDataToSave:IampSearchConfig[]=[];

            getSCFromSelectDat(dataToSave,allDataToSave);

            if(!selectedKeys.value || allDataToSave.filter(x=>x.userId==store.getters.getCurrentUser.id).length==0){
                saveSearchConfig(dataToSave);
            }

            allDataToSave.forEach(searchConfig=>{
                saveSearchConfig(searchConfig);
            });

           
        }

        const getSCFromSelectDat=(dataToSave:IampSearchConfig,allDataToSave:IampSearchConfig[])=>{

            if(selectedKeys.value){
                Object.keys(selectedKeys.value).forEach(x=>{

                            const item=selectedKeys.value[x];
                            if(item && item?.checked){
                                if(!x.includes('-') && !item.partialChecked){
                                    const datToSave = JSON.parse(JSON.stringify(dataToSave));
                                    datToSave.userId=parseInt(x);
                                    datToSave.profileId=null;
                                    datToSave.id=0;

                                    allDataToSave.push(datToSave);
                                }
                            else{
                                const keys=x.split('-');
                                if(keys.length>0 && selectedKeys.value[keys[0]].partialChecked){
                                    const datToSave = JSON.parse(JSON.stringify(dataToSave));
                                    datToSave.userId=parseInt(keys[0]);
                                    datToSave.profileId=parseInt(keys[1]);
                                    datToSave.id=0;

                                    allDataToSave.push(datToSave);
                                }

                            }
                            }
                            });

            }
      }

        const updateData=()=>{
            if (isValidData()) {
                data.value.data=getSearchFilterList();

                
                let allDataToSave:IampSearchConfig[]=[];

                getSCFromSelectDat(data.value,allDataToSave);


                if (props.container) {
                    let dataInput = JSON.parse(JSON.stringify(data.value));
                    const _srv = props.container.get<IServiceSearchConfig>(
                        TYPES.SEARCH_USR_REPOSITORY
                    );
                    HelperLoading.showSaving();
                    _srv.update(dataInput as any,allDataToSave)
                        .then((response) => {
                            if(response){
                                hideSaveSearch();
                                objCount.value=[];
                                loadData();

                            }
                            
                        })
                        .finally(() => {
                            HelperLoading.hideSaving();
                        });
                }
            }

        }
        const executeOperation=()=>{
            if(formUpdate.value){
                if(props.searchType==SearchConfigTypeConst.SEARCHCONFIGTYPE_LIST){
                    updateData();
                }
            }
            else{
                saveData();
            }
        }

        const loadData = () => {
            if (props.container) {
                
                const _srv = props.container.get<IServiceSearchConfig>(
                    TYPES.SEARCH_USR_REPOSITORY
                );
                HelperLoading.showLoading();
                _srv.getByAllByUserId(store.getters.getCurrentUser.id, props.applicationId, props.applicationVersion, props.componentId,props.searchType)
                    .then((response) => {
                        dataSearch.value = response.filter(x=>(x.userId==store.getters.getCurrentUser.id && x.profileId==null) || (x.userId==store.getters.getCurrentUser.id && x.profileId===store.getters.getCurrentProfile)).sort((a, b) => { return +new Date(b.fcr) - +new Date(a.fcr) })
                        searchConfigAll.value=response;
                        getSaveSearchCount();

                        addVisible.value=true;
                    })
                    .finally(() => {
                        HelperLoading.hideLoading();
                    });
              
            }
        }

        const getUsersProfiles=()=>{

            if(props.container){
                const _srv = props.container.get<IServiceUser>(
                                TYPES.USR_REPOSITORY
                            );
                            HelperLoading.showLoading();
                            _srv.getAllUsersWithProfiles()
                                .then((response) => {

                                    if(response){
                                        
                                        userProfileData.value=response.flatMap(x=>x.userEntityProfile);
                                    }
                                    
                                })
                                .finally(() => {
                                    HelperLoading.hideLoading();
                                });
            }
            
        }

   

        const formatData = (data: IampSearchConfig) => {

            var output = new Array<string>();

            getAllTreeData(JSON.parse(data.data)?.searchFilters as any,output);

            formatDataFilterColumn(JSON.parse(data.data)?.columnFilters as any, output);

            return output.join('</br>')
        }

        const getAllTreeData=(data: GroupSearch, outPut: string[]): string[] => {

            data.fields.forEach((item: { filter: string; fieldId: string; value: any;valueList:any;valueNumber:Number }) => {
                let criteria = props.catalogosData.find(x => x.id == item.filter)?.description ?? item.filter
                const field = props.compDataSource.dataSource.iapDataSourceFields.find(x => x.id == item.fieldId)
                const fieldDescription = (field?.field ?? field?.shortDescription) ?? item.fieldId
                let value = (item.value ?? '')
                let valueList = (item.valueList ?? '')
                if (field?.dataSourceLookUpId != null && field?.dataSourceLookUp) {
                    if (value) {
                        if (field?.dataSourceLookUp?.idControlType == ControlTypeConst.CTMULTISELECT) {
                            const values: string[] = [];
                            value.forEach(x => {
                                x.lookUpModelValue.forEach(lkmv => {
                                    values.push(lkmv.value.toString());
                                });

                            });
                            if (values.length > 0) {
                                value = values.join(", ");
                            }
                        }
                        else {
                            value = value[0].value.toString();
                        }

                    }
                }
                if (item['rangeDateTime'] && (item['rangeDateTime']['from'] != null || item['rangeDateTime']['to'] != null)) {
                    // criteria ='';
                    const from = HelperCommon.formateaFecha(item['rangeDateTime']['from'])
                    const to = HelperCommon.formateaFecha(item['rangeDateTime']['to'])

                    if (from && item.filter == FiltroBusquedaConst.FILTROBUSQUEDA_IGUAL) {
                        value = from;
                    }
                    if (from && item.filter != FiltroBusquedaConst.FILTROBUSQUEDA_IGUAL) {
                        value = ' Desde: ' + from

                    }
                    if (to && item.filter != FiltroBusquedaConst.FILTROBUSQUEDA_IGUAL) {
                        value += ' Hasta: ' + to
                    }

                }

                if(!HelperCommon.isNullOrWhitespace(valueList)){
                    value = valueList.join(", ");
                }

                if (!HelperCommon.isNullOrWhitespace(value) || item.filter == FiltroBusquedaConst.FILTROBUSQUEDA_NULO || item.filter == FiltroBusquedaConst.FILTROBUSQUEDA_NONULO || dateFilter.includes(item.filter)) {
                    outPut.push('<b>' + fieldDescription + '</b> ' + (props.searchType!=SearchConfigTypeConst.SEARCHCONFIGTYPE_LIST?criteria:'') + ' ' + (!dateFilter.includes(item.filter)?value:item.filter==FiltroBusquedaConst.FILTROBUSQUEDA_DAYSAGO?(item.valueNumber+' días.'):''));
                }
               

            });

            if(data.children && data.children.length>0){
                data.children.forEach(x=>{
                    getAllTreeData(x,outPut);
                });
            }

            return outPut;
        }

        const formatDataFilterColumn = (data: any, outPut: string[]): string[] => {
            
            Object.keys(data).forEach(key => {

                const _field = key.replace(DataSourceConst.DELIMITERFIELDFORMAT, '').replace(DataSourceConst.DELIMITERFIELDLOOKUPDESCRIPTION, '');
                const dsf = getFieldFromKeyCol(_field,false,props.compDataSource.dataSource.iapDataSourceFields);
                const fieldDescription = (dsf?.field ?? dsf?.shortDescription) ?? key;


                if (data[key].hasOwnProperty('operator')) {

                    data[key]?.constraints?.forEach((c: any) => {
                        if (!HelperCommon.isNullOrWhitespace(c?.value ?? '') || dateFilterMatchMode.includes(c?.matchMode)) {
                            outPut.push('<b>' + fieldDescription + '</b> ' + getMatchModeDesc(c?.matchMode) + ' ' + (!dateFilterMatchMode.includes(c?.matchMode)? getFilterColumnValue(c?.value, c?.matchMode):''));
                        }
                    });
                }
                else {
                    if (!HelperCommon.isNullOrWhitespace(data[key]?.value ?? '') || (dateFilterMatchMode.includes(data[key]?.matchMode))) {
                        outPut.push('<b>' + fieldDescription + '</b> ' + getMatchModeDesc(data[key]?.matchMode) + ' ' + (!dateFilterMatchMode.includes(data[key]?.matchMode)?getFilterColumnValue(data[key]?.value, data[key]?.matchMode):''));
                    }
                }

            });

            return outPut;
        }

        const getMatchModeDesc = (matchMode: string) => {
            return props.catalogosData.filter(x => x.typeId == CatalogTypeConst.MATCHMODE)?.find(x => x.value == matchMode)?.description ?? matchMode;

        }

        const getFilterColumnValue = (value: any, matchMode: string) => {

            if (matchMode == FilterMatchMode.IN) {
                return value.join(", ");
            }
            else if (matchMode == FilterMatchMode.DATE_IS || matchMode == FilterMatchMode.DATE_IS_NOT || matchMode == FilterMatchMode.DATE_AFTER || matchMode == FilterMatchMode.DATE_BEFORE) {
                return HelperCommon.formateaFecha(value);
            }
            return value.toString();

        }

        const deleteSearch = (data: IampSearchConfig) => {
            
            var doCallbackOk = () => {
                if (props.container) {
                    const _srv = props.container.get<IServiceSearchConfig>(
                        TYPES.SEARCH_USR_REPOSITORY
                    );
                    HelperLoading.showLoading();
                    _srv.delete(data.id)
                        .then((response) => {
                            if (response) {

                                const index = dataSearch?.value?.findIndex(x =>x.id == data.id)??-1


                                if (index> -1) {
                                    dataSearch?.value?.splice(index, 1)
                                }

                                MessageService.showToast(MessageType.Correcto, "", 'Registro eliminado correctamente.');

                            }
                        })
                        .finally(() => {
                            HelperLoading.hideLoading();
                        });
                }

            }

            MessageService.showMessage(MessageType.Pregunta, '', '¿Eliminar búsqueda "' + data.name + '" ?', true, true, false, '', doCallbackOk, null);
        }

        const doRecoverValues=()=>{
            
            doSearch(undefined);
        }

        const recoverValuesFromItems=(items:any[])=>{

            if(fieldList.value && items){
                const field=props.compDataSource?.iapComponentDataSourceFieldConfigurations?.find(x=>x.dataSourceFieldId==fieldList.value)?.dataSourceField;
                if(field){
                    const fieldKey=getFieldKeyColumn(field);
                    const values=items.map(x=>x[fieldKey]);
                    return values.join(',');

                }
               
            }
            return '';
           
        }

        
        const actualItems=(fieldId:any,items:any[])=>{

            if(fieldId && items){
                const field=props.compDataSource?.iapComponentDataSourceFieldConfigurations?.find(x=>x.dataSourceFieldId==fieldId)?.dataSourceField;
                if(field){
                    const fieldKey=getFieldKeyColumn(field);
                    return items.map(x=>x[fieldKey]);

                }
            
            }
            return [];

            }


        const doSearch = (searchConfig: IampSearchConfig | undefined) => {//si hay searchconfig ese que se esta contando sino se esta recuperando valores de las listas
            
            if (props.container) {

                if(searchConfig){
                    loadSearch(searchConfig);
                }

                let data = JSON.parse(JSON.stringify(searchConfig?dataSearchFilter.value:props.dataFilter));

                //sacar los searchData del primer nivel que tienen filtros de expresiones y no son campos de búsqueda por defecto, estos son AND estrictos. Seria los campos ocultos de busqueda.
                reorderSearchTree(data);

                clearEmptyFiltersList(data);

                adjustAllTreeSearchRequest(data);

                const cdsCopy = adjustCdsAndClauses(props.compDataSource);

                const lazyParams:LazyParams={
                    first:0,
                    rows:searchConfig && !(searchConfig.idSearchConfigType==SearchConfigTypeConst.SEARCHCONFIGTYPE_LIST)?10:-1,
                    filters:searchConfig?JSON.stringify(JSON.parse(searchConfig.data)?.columnFilters):JSON.stringify(props.columnFilters),
                    multiSortMeta:searchConfig?JSON.parse(searchConfig.data)?.multiSortMeta:props.sortData,
                    fieldsConfig:props.compDataSource?.iapComponentDataSourceFieldConfigurations,
                    catalogs:[],
                    provideData:!searchConfig?[{data:OperationLazyDataProviderConst.ITEMS,columnRequest:[]}]:searchConfig.idSearchConfigType==SearchConfigTypeConst.SEARCHCONFIGTYPE_LIST?[{data:OperationLazyDataProviderConst.COUNT,columnRequest:[]},{data:OperationLazyDataProviderConst.ITEMS,columnRequest:[]}]:[{data:OperationLazyDataProviderConst.COUNT,columnRequest:[]}]
                }
                
                
                const _srv = props.container.get<IServiceSearch>(
                    TYPES.SEARCH_REPOSITORY
                );
                HelperLoading.showLoading();
                _srv.search(props.applicationId, props.applicationVersion, props.componentId,
                data as any, [],cdsCopy?.maxRowsReturned??100, cdsCopy, props.compDataSource?.dataSourceId ?? -1, false,lazyParams)
                    .then((response) => {
                        
                        if(response){
                            if(!searchConfig && !HelperCommon.isNullOrWhitespace(response.items??'')){
                                valuesList.value=recoverValuesFromItems(JSON.parse(response?.items ?? '[]'));
                            }

                               //calculamos los items de la lista
                            let totalListItems:Number | null=null;   
                            let actualItemsArr:any[]=[];
                            let itemsDifference:string="";

                            if(searchConfig && searchConfig.idSearchConfigType==SearchConfigTypeConst.SEARCHCONFIGTYPE_LIST){
                                
                                const searchFilter = JSON.parse(searchConfig.data)?.searchFilters as any;

                                if(searchFilter){
                                    const valuesList=searchFilter.fields[0].valueList;
                                    totalListItems=valuesList.length;

                                    if(!HelperCommon.isNullOrWhitespace(response.items??'')){
                                    actualItemsArr=actualItems(searchFilter.fields[0].fieldId,JSON.parse(response?.items ?? '[]'));
                                    const actualItemsSet = new Set(actualItemsArr.map(String));
                                    const itemsDiff= valuesList.filter(x => !actualItemsSet.has(String(x)));
                                    if(itemsDiff.length>0){
                                        itemsDifference=itemsDiff.join(',');
                                    }
                                  }

                                }

                               

                            }     
                              
                            if(searchConfig){
                            const count = response?.totalRecords??0;

                                     objCount.value.push({
                                        id: searchConfig.id,
                                        count: count,
                                        totalListItems:totalListItems,
                                        itemsDifference:itemsDifference
                                    } as any);
                            
                        }

                        }

                    })
                    .finally(() => {
                        HelperLoading.hideLoading();
                        data = null;
                    });
            }

        }

        const getSaveSearchCount = () => {
            if (props.searchType!=SearchConfigTypeConst.SEARCHCONFIGTYPE_NCUS) {
                dataSearch.value?.filter(x => x.idSearchConfigType!=SearchConfigTypeConst.SEARCHCONFIGTYPE_NCUS).forEach(x => {
                    doSearch(x);
                });

            }
        }

        const loadSearch = (data: IampSearchConfig) => {
            
            const savedData = JSON.parse(data.data)?.searchFilters;
            HelperUtils.handleDates(savedData)

            if (savedData) {

                HelperLoading.showLoading();

                dataSearchFilter.value=savedData;

            }
        }

        const getDesc = (searchConfig: IampSearchConfig,difference:boolean=false): string => {

            const index = objCount.value.findIndex(x =>
                x.id == searchConfig.id);

            if (index > -1) {
                if(difference){
                    return   (objCount.value[index].count + '/'+ objCount.value[index].totalListItems);
                }
                else{
                    return searchConfig.name + ' (' + objCount.value[index].count + ' regs)';
                }
                
            }
            return searchConfig.name;

        }

        const getItemsDifference = (searchConfig: IampSearchConfig): string => {
            
            const index = objCount.value.findIndex(x =>
            x.id == searchConfig.id);

            if (index > -1) {
                 return objCount.value[index].itemsDifference;       
            }
             return '';
        }


        const stopPropagateEventDropDown = (event: DropdownChangeEvent  | MultiSelectChangeEvent) => {
            event.originalEvent.stopPropagation();
            event.originalEvent.preventDefault();
        }

        const filterInputProperties = {
            onClick: (event) => {
                event.stopPropagation();
            }
         };

         const onUpdateSelectionKeys=(selectKeys:any)=>{
            selectedKeys.value=selectKeys;
         }

         const setSearch= (data:any)=>{
            emit('click:setSearch',data);
         }

        onMounted(() => {
            loadData()
            getUsersProfiles()
        });
     
        return {
            v$,
            submitted,
            data,
            // includeCurrentProfile,
            dataSearch,
            formatData,
            deleteSearch,
            openSaveSearch,
            opSaveSearch,
            openColOverLayPanel,
            colOverLayPanel,
            dataOverLayPanel,
            objCount,
            getDesc,
            SearchConfigTypeConst,
            fieldsListOptions,
            fieldList,
            stopPropagateEventDropDown,
            valuesList,
            hideSaveSearch,
            doRecoverValues,
            formUpdate,
            executeOperation,
            // optionsUserProfile,
            userProfile,
            getUsersProfiles,
            filterInputProperties,

            userProfileData,
            onUpdateSelectionKeys,
            addVisible,
            differenceOverLayPanel,
            getItemsDifference,
            openDifferenceOverLayPanel,
            dataDifOverLayPanel,
            HelperCommon,
            setSearch,
            searchConfigAll


        };
    },
});
</script>
<style scoped></style>
