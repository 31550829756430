<template>
    <span>Tipo</span>
    <Dropdown @pointerdown.stop="" @dblclick.stop="" filter v-model="variableRedirectTypeSelected"
        :options="[{ id: 0, name: 'Url' }, { id: 1, name: 'Aplicación' }]" optionValue="id" optionLabel="name"
        placeholder="Selecciona una opción" class="w-full md:w-56" @change="changeType()" />

    <div v-if="variableRedirectTypeSelected === 0">
        <span>Url</span>
        <div style="display: flex;">
            <textarea style="resize: none;" @pointerdown.stop="" @dblclick.stop="" id="w3review" name="msgText" rows="2"
                cols="50" v-model="variableParamsRedirectUrlSelected"
                class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full "
                @change="changeVariableUrlValue()"></textarea>
            <i @pointerdown.stop="" @dblclick.stop="" aria-hidden="true" :class="{ 'fa fa-subscript': true, 'text-green-500': hasExpression(NavigationNodeConst.VAR_PARURL) }"
                @click="showExpression(NavigationNodeConst.VAR_PARURL, 'Url', { parameterName: NavigationNodeConst.VAR_PARURL } as any)"></i>
        </div>

        <br />

        <span>Mostrar en</span>
        <div style="display: flex;">
            <Dropdown @pointerdown.stop="" @dblclick.stop="" filter v-model="variableParamsRedirectUrlTargetSelected"
                :options="[{ id: '_blank', name: 'Nueva Ventana' },
    { id: '_parent', name: 'Ventana padre del actual frame' },
    { id: '_self', name: 'Ventana Actual' },
    { id: '_top', name: 'Ventana Actual reemplaza todos los frames' },
    { id: 'name', name: 'Nombre de la ventana' }]" optionValue="id" optionLabel="name"
                placeholder="Selecciona una opción" class="w-full md:w-56" @change="changeVariableUrlTargetValue()" />

            <i @pointerdown.stop="" @dblclick.stop="" aria-hidden="true" :class="{ 'fa fa-subscript': true, 'text-green-500': hasExpression(NavigationNodeConst.VAR_PARURLTARGET) }"
                @click="showExpression(NavigationNodeConst.VAR_PARURLTARGET, 'Url Target', { parameterName: NavigationNodeConst.VAR_PARURLTARGET } as any)"></i>
        </div>

        <br />

        <span>Parámetros</span>
        <div style="display: flex;">
            <textarea style="resize: none;" @pointerdown.stop="" @dblclick.stop="" id="w3review" name="msgText" rows="2"
                cols="50" v-model="variableParamsRedirectUrlParamsSelected"
                class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full "
                @change="changeVariableUrlParamsValue()"></textarea>
            <i @pointerdown.stop="" @dblclick.stop="" aria-hidden="true" :class="{ 'fa fa-subscript': true, 'text-green-500': hasExpression(NavigationNodeConst.VAR_PARURLPARAMS) }"
                @click="showExpression(NavigationNodeConst.VAR_PARURLPARAMS, 'Url', { parameterName: NavigationNodeConst.VAR_PARURLPARAMS } as any)"></i>
        </div>

        <br />

    </div>
    <div v-else>
        <span>Menú</span>
        <Dropdown @pointerdown.stop="" @dblclick.stop="" filter v-model="selectedData" :options="getMenuWithPath()"
            optionValue="id" optionLabel="path" placeholder="Selecciona una opción" class="w-full md:w-56"
            @change="changeMenu()" />

        <br />
        <div @pointerdown.stop="" @dblclick.stop="" style="overflow-y:auto; overflow-x:hidden; height:200px;">
            <span @pointerdown.stop="" @dblclick.stop="">Componentes</span>
            <Tree @pointerdown.stop="" @dblclick.stop="" v-model:selectionKeys="variableCompsInput" :value="nodes"
                selectionMode="checkbox" class="w-full md:w-[30rem]"  @update:selection-keys="updateSelectionKeys"></Tree>
            <br />

        </div>
        <div v-if="variableCompsInput" > 
            <span>Parámetros</span>
        <div @pointerdown.stop="" @dblclick.stop=""
            style="display: flex;overflow-y:auto; overflow-x:hidden; height:200px;">

            <table class="tableParameters">
                <thead>
                    <tr>
                        <th v-for="(column, index) in ['Componente', 'Nombre', 'Valor', '']" :key="index"> {{ column }}</th>
                        <th><button @pointerdown.stop="" @dblclick.stop="" aria-hidden="true" class="fa fa-plus"
                                style="color:green" @click="addNewItem()"></button></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in selectedParameters" :key="index">
                        <!--<td><input @pointerdown.stop="" @dblclick.stop="" type="text" v-model="item.key" readonly
                                class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full " />

                        </td>
                        -->
                        <td> 
                            <Dropdown @pointerdown.stop="" @dblclick.stop="" filter v-model="item.value.value1"
                                :options="selectedComps" optionValue="id" optionLabel="name"
                                placeholder="Seleccione un componente" class="w-full md:w-14rem" @change="changeVariableValue()" />
                        </td>
                   
                        <td><input @pointerdown.stop="" @dblclick.stop="" type="text" @change="changeVariableValue()"
                                v-model="item.value.key"
                                class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full " />

                        </td>
                        <td><textarea style="resize: none;" @pointerdown.stop="" @dblclick.stop="" id="w3review"
                                name="msgText" rows="3" cols="50" v-model="item.value.value"
                                class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full "
                                @change="changeVariableValue()"></textarea>
                        </td>
                        <td><i @pointerdown.stop="" @dblclick.stop="" aria-hidden="true" :class="{ 'fa fa-subscript': true, 'text-green-500': hasExpression(NavigationNodeConst.VAR_PAR,item.value.key) }"
                                @click="showExpression(NavigationNodeConst.VAR_PAR, 'Parámetro ' + item.key.toString(), item.value)"></i>
                        </td>
                        <td><i @pointerdown.stop="" @dblclick.stop="" aria-hidden="true" class="fa fa-trash"
                                style="color:red" @click="deleteItem(item.key)"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>


        <br />
        </div>
        

    </div>

</template>
<script lang="ts">

import { CustomMenu } from '@ilinium/shared/src/common/domain/modelos/menu/CustomMenu';
import store from '@ilinium/shared/src/common/infrastructure/almacen';
import { computed, defineComponent, onMounted, getCurrentInstance, ref, shallowRef } from 'vue';
import { searchMenuTree } from '../../../../../menu/infrastructure/HelperMenu';
import NavigationNodeConst from '../constants/NavigationNodeConst';
import HelperCustomComponentControls from '../../../functions/helperCustomComponentControls';
import { Dictionary } from '../../../../../expression/domain/dictionary';
import HelperUtils from '../../../../../../../common/infrastructure/funciones/HelperUtils';
import { IapComponent } from '../../../../../component/domain/iapComponent';
import { Container } from 'inversify';
import { IServiceComponent } from '../../../../../component/application/IServiceComponent';
import HelperLoading from '../../../../../../../common/infrastructure/funciones/HelperLoading';
import { TYPES } from '../../../../../../../common/domain/types';
import Dropdown from 'primevue/dropdown';
import Tree, { TreeSelectionKeys } from 'primevue/tree';
import PrimeVue from 'primevue/config';
import Fieldset from 'primevue/fieldset';
import HelperPrime from '../../../../../../../common/infrastructure/funciones/HelperPrime';
import ControlTypeConst from '../../../../../form/domain/Constants/ControlTypeConst';
import ComponentUtil from '../../../../../designer/infrastructure/component/util/componentUtil';
import CatalogTypeConst from '../../../../../catalog/domain/const/CatalogTypeConst';
import helperCatalog from '../../../../../catalog/infrastructure/helper/helperCatalog';
import Panel from 'primevue/panel';
import Card from 'primevue/card';
import { Tuple } from '../../../../../expression/domain/tuple';

export default defineComponent({
    name: 'customredirectcontrol',
    components: {

    },
    props: {
        data: Object
    },
    setup(props) {
        const { nest } = ComponentUtil();
        const app = getCurrentInstance();
        
        
        app?.appContext.app.component('Tree', Tree)
        app?.appContext.app.component('Dropdown', Dropdown)
        app?.appContext.app.use(PrimeVue, HelperPrime.getPrimeOptions())

        const { showExpression, hasExpression,loadExpressions } = HelperCustomComponentControls(props.data);
        const selectedData = ref();
        const selectedParameters = ref<Dictionary<String, Tuple<String, String,String>>[]>([]);
        const cusMenu = store.getters.getApplicationMenus as CustomMenu[];
        const comps = ref<IapComponent[]>([])
        const variableRedirectTypeSelected = shallowRef(0);
        const variableParamsRedirectUrlSelected = shallowRef('');
        const variableParamsRedirectUrlTargetSelected = shallowRef('_blank');
        const variableParamsRedirectUrlParamsSelected = shallowRef('');
        const variableCompsInput = ref(null);

        const nodes = computed(() => {
            if (comps.value.length > 0) {
                const aux = nest(comps.value, null, 'parentId')?.find((x: any) => x !== undefined)
                console.log(aux)
                return [aux];
            }
            else {
                return [];
            }




        })

        const selectedComps = computed(()=>{
            let keys = Object.keys(variableCompsInput.value ?? {})
            if (keys.length > 0) {
                return comps.value.filter( x=> keys.includes(x.id.toString()))
            }
            else{
                return [];
            }            
        })

        const getComponents = async (componentId: number): Promise<boolean> => {


            return await new Promise<boolean>((resolve) => {

                if (props.data && props.data.container && componentId > 0) {

                    const _srv = (props.data.container as Container).get<IServiceComponent>(TYPES.COMPONENT_REPOSITORY)
                    HelperLoading.showLoading()

                    _srv.getSimpleTreeById(props.data.applicationId ?? -1, props.data.applicationVersion ?? -1, componentId, true).then(response => {

                        const catalogTypes = helperCatalog.getCatalog(CatalogTypeConst.TIPOCONTROL);

                        comps.value = response.map(o => ({
                            key: o.id.toString(), label: ((catalogTypes?.find(x => x.id == o.idType)?.description ?? '') + ' ' + o.name), data: o.name, selectable: (o.parentId == null
                                || o.idType == ControlTypeConst.CTTABVIEW
                                || o.idType == ControlTypeConst.CTTABPANEL
                                || o.idType == ControlTypeConst.CTCRUD)
                            , ...o
                        }))

                    })
                        .finally(() => {
                            HelperLoading.hideLoading()
                            resolve(true)
                        })

                }
                else {
                    comps.value = [];
                    resolve(true)
                }


            });
        }










        const getPathFromChildMenu = (menuCustom: CustomMenu, newPath: string | null): string | null => {

            const label = menuCustom.label as string;

            if (newPath == null) {
                newPath = label;
            }
            else {
                newPath = label + ' --> ' + newPath;
            }

            if (menuCustom.parentId) {
                const parent = searchMenuTree(cusMenu, menuCustom.parentId);
                if (parent != null) {
                    return getPathFromChildMenu(parent, newPath);
                }
            }

            return newPath;

        }


        const getAllMenu = (data: CustomMenu[]) => {

            let extractedItems: CustomMenu[] = [];

            for (const element of data) {

                extractedItems.push(element);

                if (element.items && element?.items.length > 0) {

                    const subItems = getAllMenu(element.items as CustomMenu[]);
                    extractedItems = extractedItems.concat(subItems);
                }
            }

            return extractedItems;

        };

        const getMenuWithPath = () => {

            var data = getAllMenu(cusMenu);

            var menu = data.map(x => ({
                id: x.id,
                label: x.label,
                path: getPathFromChildMenu(x, null),
                parentId: x.parent,
                componentId: x.componentId

            })).sort((a, b) => { return (a.parentId ?? 0) - (b.parentId ?? 0); }) as any[]


            return menu;

        };


        const deleteItem = (key: String) => {
            selectedParameters.value = selectedParameters.value.filter(x => x.key !== key)
            changeVariableValue()
            loadExpressions();

        }

        const addNewItem = () => {
            selectedParameters.value.push({
                key: HelperUtils.newGuid(),
                value: {
                    key: 'nuevo parámetro',
                    value: '',
                    value1:''
                }
            } as any)
            changeVariableValue()
        }

        const changeVariableValue = () => {
            if (props.data) {
                props.data.onChange({ key: NavigationNodeConst.VAR_PAR, data: selectedParameters.value })
            }

        }

        const changeVariableUrlValue = () => {
            if (props.data) {
                props.data.onChange({ key: NavigationNodeConst.VAR_PARURL, data: variableParamsRedirectUrlSelected.value })
            }
        }

        const changeVariableUrlTargetValue = () => {
            if (props.data) {
                props.data.onChange({ key: NavigationNodeConst.VAR_PARURLTARGET, data: variableParamsRedirectUrlTargetSelected.value })
            }
        }

        const changeVariableUrlParamsValue = () => {
            if (props.data) {
                props.data.onChange({ key: NavigationNodeConst.VAR_PARURLPARAMS, data: variableParamsRedirectUrlParamsSelected.value })
            }
        }

        const changeType = () => {

            if (props.data) {




                selectedData.value = -1;
                selectedParameters.value = [];


                variableParamsRedirectUrlSelected.value = '';
                variableParamsRedirectUrlTargetSelected.value = '_blank';
                variableParamsRedirectUrlParamsSelected.value = '';
                variableCompsInput.value = null;


                props.data.onChange({ key: NavigationNodeConst.VAR_PAR, data: selectedParameters.value })
                props.data.onChange({ key: NavigationNodeConst.VAR_MENUID, data: selectedData.value })


                props.data.onChange({ key: NavigationNodeConst.VAR_TIPO, data: variableRedirectTypeSelected.value })
                props.data.onChange({ key: NavigationNodeConst.VAR_PARURL, data: variableParamsRedirectUrlSelected.value })
                props.data.onChange({ key: NavigationNodeConst.VAR_PARURLTARGET, data: variableParamsRedirectUrlTargetSelected.value })
                props.data.onChange({ key: NavigationNodeConst.VAR_PARURLPARAMS, data: variableParamsRedirectUrlParamsSelected.value })
                props.data.onChange({ key: NavigationNodeConst.VAR_COMPS, data: variableCompsInput.value })

            }
        }

        const getComponentFromMenu = async () => {
            if (selectedData.value > 0) {
                const mnu = getMenuWithPath().find(x => x.id == selectedData.value)
                if (mnu) {
                    await getComponents(mnu.componentId ?? -1);
                }

            }
        }

        const changeMenu = () => {

            if (props.data) {
                props.data.onChange({ key: NavigationNodeConst.VAR_MENUID, data: selectedData.value });
                variableCompsInput.value = null;
                props.data.onChange({ key: NavigationNodeConst.VAR_COMPS, data: variableCompsInput.value });
 

                getComponentFromMenu();

            }

        }

        const updateSelectionKeys = (value: TreeSelectionKeys) => {
         if (variableCompsInput.value && props.data) {
                        let keys = Object.keys(variableCompsInput.value ?? {})
                        if (keys.length > 0) {

                            keys.forEach((k: string) => {
                                if (variableCompsInput.value && comps.value.find(o => (o.parentId == null
                                    || o.idType == ControlTypeConst.CTTABVIEW
                                    || o.idType == ControlTypeConst.CTTABPANEL
                                    || o.idType == ControlTypeConst.CTCRUD) && o.id.toString() == k) == undefined) {
                                    delete variableCompsInput.value[k]
                                }

                            })

                            keys = Object.keys(variableCompsInput.value ?? {})
                            props.data.onChange({ key: NavigationNodeConst.VAR_COMPS, data: (keys.length > 0 ? JSON.stringify(variableCompsInput.value) :null) });
                        }
                        else {
                            props.data.onChange({ key: NavigationNodeConst.VAR_COMPS, data: null });

                        }
                    }
         
        }
        const getObject = (obj:any) => {
            if (typeof obj == 'string'){
                try{
                    return JSON.parse(obj);
                }
                catch{
                    return null;
                }
            }
            return obj;
        }

        onMounted(() => {
            if (props.data) {
                selectedData.value = props.data[NavigationNodeConst.VAR_MENUID];
                selectedParameters.value = props.data[NavigationNodeConst.VAR_PAR] ?? [];
                variableCompsInput.value = getObject(props.data[NavigationNodeConst.VAR_COMPS]);
                variableRedirectTypeSelected.value = props.data[NavigationNodeConst.VAR_TIPO] ?? 0;
                variableParamsRedirectUrlSelected.value = props.data[NavigationNodeConst.VAR_PARURL];
                variableParamsRedirectUrlTargetSelected.value = props.data[NavigationNodeConst.VAR_PARURLTARGET] ?? '_blank';
                variableParamsRedirectUrlParamsSelected.value = props.data[NavigationNodeConst.VAR_PARURLPARAMS];

                getComponentFromMenu();

                


            }

        })




        return {
            selectedData,
            selectedParameters,
            getMenuWithPath,
            NavigationNodeConst,
            showExpression,
            deleteItem,
            changeVariableValue,
            addNewItem,
            variableRedirectTypeSelected,
            variableParamsRedirectUrlSelected,
            variableParamsRedirectUrlTargetSelected,
            variableParamsRedirectUrlParamsSelected,
            changeType,
            changeVariableUrlValue,
            changeVariableUrlTargetValue,
            changeVariableUrlParamsValue,
            comps,
            changeMenu,
            nodes,
            variableCompsInput,
            updateSelectionKeys,
            hasExpression,
            selectedComps


        };
    },
});
</script>
<style scoped></style>
