import gql from 'graphql-tag';


export const UPDATE = gql(`
mutation($data: ComponentDataSourceInputType!){
    componentDataSourceMutation{
        updateComponentDataSource(data:$data){
           id,          
  dataSourceId,
  componentId,
  fcr
  ucr
  name
  autoSave
  autoLoad
  addEmptyRow
  maxRowsReturned
  queryWhere
  queryOrderBy
  queryGroupBy
  queryHaving
  filterDistinct
  order
  applicationId
  applicationVersion
  iapComponentDataSourceFieldConfigurations{
      componentDataSourceId
      dataSourceFieldId
      defaultFilterSearch
      autoUpdate
      defaultValue
      defaultValidation
      defaultFilterSearchOrder
      edit
      add
      editMassive
      import
      fcr
      filterResult
      filterResultOrder
      filterSearch
      filterSearchOrder
      fum
      view
      list
      mandatory
      sortOrder
      idSortType
      aggregateOperation
      idFilterType
      idFilterControlType
      idDefaultMatchMode
      defaultFilterValue  
      cellStyle 
      displayContentMaxlength         
      dataSourceField{
          tableName
          field
          fieldAlias
      }
      
  }
  dataSource{
      id,
      name,
      description,
      idDataSourceType
      ,
        iapDataSourceDataBase{
          id
          dataSourceId
          idBaseDatos
          idDataBaseType
          querySelect
          queryFrom
          queryWhere
          queryOrderBy
          queryGroupBy
          queryHaving
          filterDistinct
          fcr
          ucr
          uum
          fum
        },
        iapDataSourceService{
          dataSourceId
          idHttpMethodType
          methodName
          type
          idAuthType
          url
          fileJson
          host
          fcr
          ucr
          uum
          fum         
        },        
       iapDataSourceFields{
     id,
     dataSourceId,
     dataSourceTableAliasId
      tableName,
      field,
      shortDescription,
      description,
      fieldAlias
  }
      iapDataSourceTableAliases{
        tableName,
        tableAlias,
        shortDescription,
        join
        isInput
      }
  }
        }
    }
}
`);

export const DELETE = gql(`
mutation($componentDataSourceId: Int!){
    componentDataSourceMutation{
        deleteComponentDataSource(componentDataSourceId:$componentDataSourceId)
    }
}
`);


export const ADD = gql(`
mutation($data: ComponentDataSourceInputType!){
    componentDataSourceMutation{
        addComponentDataSource(data:$data){
           id,          
  dataSourceId,
  componentId,
  fcr
  ucr
  name
  autoSave
  autoLoad  
  addEmptyRow
  maxRowsReturned
  queryWhere
  queryOrderBy
  queryGroupBy
  queryHaving
  filterDistinct
  order
  applicationId
  applicationVersion
  iapComponentDataSourceFieldConfigurations{
      componentDataSourceId
      dataSourceFieldId
      defaultFilterSearch
      defaultFilterSearchOrder
      autoUpdate
      defaultValue
      defaultValidation
      edit
      add
      editMassive
      import
      fcr
      filterResult
      filterResultOrder
      filterSearch
      filterSearchOrder
      fum
      list
      view
      mandatory
      sortOrder
      idSortType
      aggregateOperation
      idFilterType
      idFilterControlType
      idDefaultMatchMode
      defaultFilterValue  
      cellStyle  
      displayContentMaxlength        
      dataSourceField{
        id,
        dataSourceId,
        dataSourceTableAliasId
        tableName,
        field,
        fieldAlias
      }
      
  }
  dataSource{
      id,
      name,
      description,
      idDataSourceType   
      ,
        iapDataSourceDataBase{
          id
          dataSourceId
          idBaseDatos
          idDataBaseType
          querySelect
          queryFrom
          queryWhere
          queryOrderBy
          queryGroupBy
          queryHaving
          filterDistinct
          fcr
          ucr
          uum
          fum
        },
        iapDataSourceService{
          dataSourceId
          idHttpMethodType
          idAuthType
          url
          fileJson
          fcr
          ucr
          uum
          fum         
        },     
       iapDataSourceFields{
     id,
     dataSourceId,
     dataSourceTableAliasId
      tableName,
      field,
      shortDescription,
      description,
      dataSourceLookUpId,
      fieldAlias
  }
      iapDataSourceTableAliases{
        tableName,
        tableAlias,
        shortDescription,
        join
        isInput
      }
  }
        }
    }
}
`);



