import gql from 'graphql-tag';





export const GETBYUSERID = gql(`
query($userId: Int!,$applicationId: Int!,$applicationVersion: Int!,$componentId: Int! , $searchType: String!){
    searchConfigQuery{
        getByAllByUserId(userId: $userId, applicationId: $applicationId, applicationVersion: $applicationVersion, componentId: $componentId,searchType:$searchType)
        {  
            id
            group          
            userId
            applicationId
            applicationVersion
            componentId
            profileId
            name
            data
            fcr
            idSearchConfigType
            ucr
        }
    }
}
`);
