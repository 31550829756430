<template>

    <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
    :data="Component"
    :vmodel="vmodel"

    />


    <div v-if="hasLoaded" :key="componentKey" :id="getPropertyValue(StructuredFieldControlTypeConst.ID)"
        v-tooltip="getPropertyValue(StructuredFieldControlTypeConst.TOOLTIP)"
        :style="getPropertyValue(StructuredFieldControlTypeConst.STYLE)"
        :class="{ [getPropertyValue(StructuredFieldControlTypeConst.CLASS) ?? '']: true, 'customrequired': getPropertyBooleanValue(StructuredFieldControlTypeConst.REQUIRED) }"
        :name="getPropertyValue(StructuredFieldControlTypeConst.NAME)"
        :disabled="getPropertyBooleanValue(StructuredFieldControlTypeConst.DISABLED)"
        :visible="getPropertyBooleanValue(StructuredFieldControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)">


        <div v-if="useJsonEditor" :id="'editorJson' + Component.id">
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
        </div>
        <!--<div v-else-if="Object.keys(vmodel ?? {})?.length > 0">-->
        <div v-else >
            <NewRegister v-if="!hasSlotForm" :container="container" :compDataSource="datasource"
                :catalogosData="catalogos" :rootParentId="Component.rootParentId" :Component="Component" :formKey="Component.formKey"
                :canUpdate="true" :canDelete="false" :isStructureField="true"
                :recordToUpdate="datasource?.dataSource.items[0]" @changeData="updateDataItem" />
                <div v-else ref="slotElement" @change="updateDataSet"  @mouseleave ="updateDataSet">
                    <slot name="newform" >

                    </slot>
                </div>
        </div>

    </div>
</template>
<script lang="ts">


import { defineComponent, onMounted, ref, onBeforeUnmount, shallowRef, watch,watchEffect, computed } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import StructuredFieldControlTypeConst from '../../../../domain/Constants/StructuredFieldControlTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import helperCatalog from '../../../../../catalog/infrastructure/helper/helperCatalog';
import NewRegister from '../../../../../crud/infrastructure/search/NewRegister.vue';
import { IapCatalog } from '../../../../../catalog/domain/iapCatalog';
import DataSourceComp from '../../../../../crud/infrastructure/functions/dataSourceComp';
import CatalogStructFileConst from '../../../../../catalog/domain/const/CatalogStructFileConst';
import { claveNew } from '../../../../../dataupdate/domain/clave';
import Environment from '../../../../../../../common/infrastructure/funciones/environment';
import { fetchWrapper } from '../../../../../../../common/infrastructure/funciones/helperFetch';
import HelperCompress from '../../../../../../../common/infrastructure/funciones/HelperCompress';
import HelperUtils from '../../../../../../../common/infrastructure/funciones/HelperUtils';
import HelperCommon from '../../../../../../../common/infrastructure/funciones/HelperCommon';
import CatalogModelValueConst from '../../../../../catalog/domain/const/CatalogModelValueConst';
import InteractionConst from '../../../../../interaction/domain/interactionConst';
import { InteractionEvent } from '../../../../../interaction/domain/interactionEvent';
import EventConst from '../../../../../../../common/domain/constantes/EventConst';
import EventBusCustom from '../../../../../../../common/infrastructure/event-bus-custom';
import KeysDataModelComponentDataSource from '../../../../domain/keysDataModelComponentDataSource';
import SummaryDebug from '../../shared/SummaryDebug.vue';
import { IapComponentDataSource } from '../../../../../component/domain/iapComponentDataSource';

export default defineComponent({
    name: 'dynamic_structured_field',
    components: {
        NewRegister,
        SummaryDebug
    },
    props:
    {

        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
      
        slotProps: {
            type: Object,
            default: () => ({})
        },
       
    },
    setup(props, context) {

        let editor: any
        let myTimeoutJSonEditor: any
        const schemaData = shallowRef()
        const hasLoadedSchemaData = ref(false)
        const store = useStore();
        const datasource = ref<IapComponentDataSource>();
        const useJsonEditor = shallowRef(false)
        const { getFieldKeyColumn, insertEmptyRowToDataSource } = DataSourceComp(props.container as any, props, context.emit, {}, datasource.value as any, [], store, {} as any)
        let isLoaded = false;
        const catalogos = shallowRef<IapCatalog[]>(helperCatalog.getAllCatalogApp().filter(x => x.iapCatalogs).flatMap(x => x.iapCatalogs));

        const optionsXml = {
            ignoreAttributes: false,
            attributeNamePrefix: "@_",
            alwaysCreateTextNode: true
        };

        const doObserverChanges = () => {
            if (datasource.value) {
                watch(
                    datasource.value.dataSource.items[0], () => {
                        fieldToVmodel()
                        updateVmodelFromParent()
                    },
                    {
                        flush: 'post',
                        immediate: true,
                        deep: true
                    }
                )

            }

        }



        const updateVmodelFromParent = () => {
            const mv = getProperty(BaseControlTypeConst.MODELVALUE)

            if (mv?.value && mv?.idModelValueType == CatalogModelValueConst.MODELVALUE_DATASOURCE) {
                const ids = mv.value?.split('#');
                const _dataSourceFieldId = ids[0];
                const fieldKeyComponentDataSourceId = parseInt(ids[1]);

                const cds = props.Component.get(-1,'iapComponentDataSources')?.find(x => x.id == fieldKeyComponentDataSourceId)
                if (cds) {
                
                    const modelvalueKeys = {
                        type: CatalogModelValueConst.MODELVALUE_DATASOURCE,
                        dataSourceFieldId: _dataSourceFieldId,
                        componentDataSourceId: fieldKeyComponentDataSourceId
                        } as KeysDataModelComponentDataSource


                    var data: Partial<InteractionEvent> = {
                        objectId: mv.id,
                        typeId: InteractionConst.CALL_FUNCTION,
                        objectValue: { _newValue:vmodel.value, start:true,lkFieldId:'',modelvalueKeysInput:modelvalueKeys},
                        objectName: 'updateData()'

                    }


                    const keyComponentEventBus = props.Component.formKey + props.Component.rootParentId.toString() + mv.componentId.toString() + EventConst.INTERACTION;



                    EventBusCustom.emit(keyComponentEventBus, { data: data, callBackResponse: null });

                }
            }


        }


        const parseXmlSchemaToXml = () => {
            const { XMLParser, XMLBuilder, XMLValidator } = require("fast-xml-parser");
            //const XMLdata = '<root><observations label="Observaciones">ABCDEFTGI</observations><claimRisk label="Riesgo" value="328358">2834DCT IVECO EUROCARGO</claimRisk><claimType label="Tipo" value="ovClaimType-02">Reclamación</claimType><claimDate label="Fecha siniestro">29/09/2017</claimDate><claimHour label="Hora siniestro">11</claimHour><claimPartDate label="Fecha parte">29/09/2017</claimPartDate><claimDamages label="Daños">PARACHOQUES</claimDamages><claimPlace label="Lugar">CALLE DIAZ</claimPlace><claimCause label="Motivo">CHOQUE</claimCause><claimDescription label="Descripción">TEST INTEGRACIÓN SINIESTRO</claimDescription><claimContactPhone label="Teléfono">922456545</claimContactPhone><claimContactMail label="E-Mail">DAIDA@INTRASOFT.ES</claimContactMail><claimAffectedDocId label="Doc. Identidad">11111111H</claimAffectedDocId><claimAffectedName label="Nombre">LUIS</claimAffectedName><claimAffectedSurname1 label="Apellido 1">DIAZ</claimAffectedSurname1><claimAffectedSurname2 label="Apellido 2">FUENTES</claimAffectedSurname2><claimAffectedVeh label="Marca/Modelo">OPEL CORSA</claimAffectedVeh><claimAffectedPlate label="Matrícula">1245XXX</claimAffectedPlate><claimAffectedCompany label="Compañía">ALLIANZ</claimAffectedCompany><claimAffectedPolNum label="Nº Póliza">12345</claimAffectedPolNum><claimWorkName label="Nombre">TALLER MANOLO</claimWorkName><claimWorkAddress label="Dirección">CALLE ABCDE</claimWorkAddress><claimWorkTown label="Localidad">MADRID</claimWorkTown><claimWorkContact label="Tel. contacto">92245659</claimWorkContact><chkPeritaje label="Solicitar peritación">Sí</chkPeritaje><comments label="Comentarios (incluir aquí datos de otros contrarios y de los lesionados)">CONTRARIOS SIN LESIONES</comments></root>'
            //xmlData=XMLdata


            const builder = new XMLBuilder(optionsXml);
            const xmlContent = builder.build(schemaData.value);
            return xmlContent

        }

        const parseXmlSchema = (xmlData: string) => {
            const { XMLParser, XMLBuilder, XMLValidator } = require("fast-xml-parser");
            //const XMLdata = '<root><observations label="Observaciones">ABCDEFTGI</observations><claimRisk label="Riesgo" value="328358">2834DCT IVECO EUROCARGO</claimRisk><claimType label="Tipo" value="ovClaimType-02">Reclamación</claimType><claimDate label="Fecha siniestro">29/09/2017</claimDate><claimHour label="Hora siniestro">11</claimHour><claimPartDate label="Fecha parte">29/09/2017</claimPartDate><claimDamages label="Daños">PARACHOQUES</claimDamages><claimPlace label="Lugar">CALLE DIAZ</claimPlace><claimCause label="Motivo">CHOQUE</claimCause><claimDescription label="Descripción">TEST INTEGRACIÓN SINIESTRO</claimDescription><claimContactPhone label="Teléfono">922456545</claimContactPhone><claimContactMail label="E-Mail">DAIDA@INTRASOFT.ES</claimContactMail><claimAffectedDocId label="Doc. Identidad">11111111H</claimAffectedDocId><claimAffectedName label="Nombre">LUIS</claimAffectedName><claimAffectedSurname1 label="Apellido 1">DIAZ</claimAffectedSurname1><claimAffectedSurname2 label="Apellido 2">FUENTES</claimAffectedSurname2><claimAffectedVeh label="Marca/Modelo">OPEL CORSA</claimAffectedVeh><claimAffectedPlate label="Matrícula">1245XXX</claimAffectedPlate><claimAffectedCompany label="Compañía">ALLIANZ</claimAffectedCompany><claimAffectedPolNum label="Nº Póliza">12345</claimAffectedPolNum><claimWorkName label="Nombre">TALLER MANOLO</claimWorkName><claimWorkAddress label="Dirección">CALLE ABCDE</claimWorkAddress><claimWorkTown label="Localidad">MADRID</claimWorkTown><claimWorkContact label="Tel. contacto">92245659</claimWorkContact><chkPeritaje label="Solicitar peritación">Sí</chkPeritaje><comments label="Comentarios (incluir aquí datos de otros contrarios y de los lesionados)">CONTRARIOS SIN LESIONES</comments></root>'
            //xmlData=XMLdata

            const parser = new XMLParser(optionsXml);
            const jObj = parser.parse(xmlData);
            return jObj
            //console.log(jObj)
            //const builder = new XMLBuilder(optionsXml);
            //const xmlContent = builder.build(jObj);
            //console.log(xmlContent)
        }


        function previewFile(rootImg:any,preview:any, file:any) {

        //var preview1 = document.getElementById('imgLogoEvid');
        //@ts-ignore:disable-next-line
        var file = file.files.length > 0 ? file.files[0] : null ;//document.getElementById('fileLogoEvid').files[0];// document.getElementById('fileLogoEvid'); //.querySelector('input[type=file]').files[0];
        var reader = new FileReader();

        reader.onloadend = function () {
            var resu = reader.result;

            //@ts-ignore:disable-next-line
            rootImg.value = resu;
            //@ts-ignore:disable-next-line
            rootImg.dispatchEvent(new Event('change'));
            //@ts-ignore:disable-next-line
            preview.src = resu;
        }

        if (file) {
            reader.readAsDataURL(file);
        } else {
            rootImg.value = "";
            preview.src = "";

        }
    }

    const getParentImg = (el:Element) =>{
        const _path = Array.from(el.classList).find(x=> x.startsWith('locationParentNode:'))
        if (_path){
            return document.querySelector(_path.replace('locationParentNode:',''))?.parentNode
        }
        return undefined
    }
         const convertImages  =() =>{
            Array.from(document.getElementsByClassName('change-image')).forEach( (el:Element,index:number) =>
            {
                        //@ts-ignore:disable-next-line
                        if (el.style.visibility == ''){

                            const id= 'change-image_' + index.toString()
                            //@ts-ignore:disable-next-line
                            el.style.visibility='hidden'
                            const lbl = el.previousSibling
                            if (lbl){
                             
                                var dv = document.createElement("div")
                                dv.className = "row";

                                var img = document.createElement("img");
                                img.id = "img_" + id;
                                img.src = el.textContent ?? '';
                                img.style.cssText = "max-width:200px";
                                
                                var fileImg = document.createElement("input");
                                fileImg.id = "fileImg_" + id;
                                fileImg.accept="image/*";
                                fileImg.type = "file";
                                fileImg.onchange = function () { return previewFile(el,img,this) };
                                fileImg.style.visibility = 'hidden';

                                var btnChoose = document.createElement("input");
                                btnChoose.className = "p-button p-component";
                                //btnChoose.style="float:left";
                                //@ts-ignore:disable-next-line
                                btnChoose.value = el.previousSibling.innerText;
                                btnChoose.onclick = function () { fileImg.click()}
                                var i = document.createElement('i')    
                                i.className='fas fa-search'
                                btnChoose.prepend(i)

                                dv.prepend(fileImg)
                                dv.prepend(btnChoose)

                                let parentLocationNode = getParentImg(el)

                                if (parentLocationNode == undefined || parentLocationNode == null){
                                    parentLocationNode= el.parentNode
                                }

                                if (parentLocationNode){

                                    parentLocationNode.prepend(img)
                                    parentLocationNode.prepend(dv)
                                }

                                
                                //@ts-ignore:disable-next-line
                                lbl.style.visibility='hidden'
                                
                            }

                        }
            })
         }

        const initJsonEditor = () => {
            const elementEditor = document.getElementById('editorJson' + props.Component.id)
            if (schemaData.value && elementEditor) {
                //@ts-ignore:disable-next-line
                JSONEditor.defaults.theme ='bootstrap5';
                //@ts-ignore:disable-next-line
                JSONEditor.defaults.iconlib = 'bootstrap5';
                //@ts-ignore:disable-next-line
                JSONEditor.defaults.callbacks = {
                    "button": {
                        "changeImage": function (jseditor: any, e: any) {
                            alert('Button action')
                        }
                    }
                }

                //@ts-ignore:disable-next-line
                //JSONEditor.defaults.language = "es";
                //@ts-ignore:disable-next-line
                elementEditor.innerText = ''
                //@ts-ignore:disable-next-line
                elementEditor.innerHTML = ''
                //@ts-ignore:disable-next-line
                elementEditor.style.visibility = ''
                //@ts-ignore:disable-next-line
                editor = new JSONEditor(elementEditor,
                    {
                        // Enable fetching schemas via ajax
                        ajax: false,
                        disable_edit_json: true,
                        disable_properties: true,
                        //form_name_root:"[text][about][name]",
                        // The schema for the editor
                        iconlib: "fontawesome5",
                        schema: schemaData.value,
                        /*schema: {
                            //$ref: schema_value,
                            //title: "Configuración",
                            //type: "object",
                            properties: JSON.stringify(schemaData.value),
                            //$ref: "person.json",
                            //format: "grid"
                        },
                        */

                        // Seed the form with a starting value
                        startval: HelperCommon.isNullOrWhitespace(vmodel.value ?? '') ? null : JSON.parse(vmodel.value)
                    });



                editor.on('change', () => {
                    // Do something

                    if (editor.ready){
                        convertImages()                        
                    }

                    vmodel.value = JSON.stringify(editor.getValue())
                });

                editor.on('add', (property: any) => {
                    // Do something

                    vmodel.value = JSON.stringify(editor.getValue())
                });


                editor.on('switch', (property: any) => {
                    // Do something

                    vmodel.value = JSON.stringify(editor.getValue())
                });





                clearTimeout(myTimeoutJSonEditor)

            }


        }



        const VmodelToField = () => {
            if (hasLoaded.value) {
                if (!useJsonEditor.value) {
                    const item = datasource.value?.dataSource.items.find(x => x !== undefined)
                    if (item) {
                        if (parseToJson) {
                            const _vmodel = JSON.parse(vmodel.value ?? '{}')
                            HelperUtils.handleDatesApollo(_vmodel);
                            
                            const keys = Object.keys(_vmodel)

                            if (keys.length > 0) {
                                keys.forEach(k => {
                                    const field = datasource.value?.dataSource.iapDataSourceFields.find(x => x.fieldAlias == k)
                                    if (field) {
                                        item[getFieldKeyColumn(field, (field?.dataSourceTableAliasId == null))] = _vmodel[k]                                        
                                    }
                                })
                            }
                            else {
                                fieldToVmodel()
                            }
                        }
                        else {
                            if (schemaData.value == undefined) {
                                const _xmlVmodel = vmodel.value ?? ''
                                if (!HelperCommon.isNullOrWhitespace(_xmlVmodel)) {
                                    schemaData.value = parseXmlSchema(_xmlVmodel)
                                }

                            }

                            if (schemaData.value !== undefined) {

                                datasource.value?.dataSource.iapDataSourceFields.forEach(field => {
                                    const objData = HelperUtils.getDeepvalueObjectByKey(schemaData.value, (field.fieldAlias ?? field.field))
                                    HelperUtils.handleDatesApollo(objData);
                                    if (objData) {
                                        item[getFieldKeyColumn(field, (field?.dataSourceTableAliasId == null))] = objData[(field.fieldAlias ?? field.field)]['#text'] ?? ''
                                    }


                                })
                            }
                            else {
                                fieldToVmodel()
                            }


                        }


                        /*fieldsDef.value.forEach(x=>
                        {
                            const _key = keys.find(k => k == x.name)
                            if (_key){
                                x.value = _vmodel[_key]
                            }
                        })*/

                        doObserverChanges()

                    }
                }
                else {
                    myTimeoutJSonEditor = setTimeout(initJsonEditor, 2000);

                }

            }

        }


        const cbVModel = (vmodelInput:any) =>{
            VmodelToField()
            componentKey.value = HelperUtils.newGuid();
            /*if (vmodelInput && !Array.isArray(vmodelInput)){
                const newData = (vmodelInput?.replace(/\"/g,'') ?? '').split(',').filter(x=> x!== '')
                if (arrayModel.value.toString() !== newData.toString()){
            
                    arrayModel.value = newData
                }
                
            }else{
                arrayModel.value = Array.isArray(vmodelInput)?vmodelInput:[];
            }
            */
        }

        const { getProperty, getLayoutComponent, vmodel, canDoOperation, getPropertyValue, getPropertyNumberValue, 
            getPropertyBooleanValue, loaded, resolveExpressions, baseOnBeforeUnmount, fieldKey, fieldKeyComponentDataSourceId
            ,componentKey,baseOnMounted
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store,VmodelToField,cbVModel)        
        //const fieldsDef = ref<fieldDefinition[]>(JSON.parse(getPropertyValue(StructuredFieldControlTypeConst.FIELDS_DEF) ?? "[]") ?? []);        
        const submitted = shallowRef(false);
        //const catalogs = helperCatalog.getCatalogTypes(fieldsDef.value.filter(x=> x.catalogTypeId).map(x=> x.catalogTypeId))
        const parseToJson = getPropertyValue(StructuredFieldControlTypeConst.FILE_TYPE) == CatalogStructFileConst.JSON


        /*
        const reglas = {
            items: {
                $each: helpers.forEach({
                    value: {
                        required:  (value, helpers) => {
                            if( helpers.required
                                && !(value)){
                                 return {
                                    $valid:false,
                                    $message: 'El campo es obligatorio',
                                    extraParams: {}
                                  }
                                }
                            
                                return { $valid: true }

                        },

                    },
                })
            }
        }

        


        const v$ = useVuelidate(reglas, { items: fieldsDef });

*/

    const hasLoadedComponent = computed(() => {
            return loaded.value && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW) 
        })



    const hasLoaded = computed(() => {
            return hasLoadedComponent.value && hasLoadedSchemaData.value
        })




        const fieldToVmodel = () => {
            const item = datasource.value?.dataSource.items.find(x => x !== undefined)
            if (item) {
                if (parseToJson) {
                    let aux = {}
                    datasource.value?.dataSource.iapDataSourceFields.forEach(f => {
                        let key = getFieldKeyColumn(f, false);
                        if (Object.keys(item).find( x=> x === key) == undefined){
                            key = getFieldKeyColumn(f, true);
                        }
                        aux[f.fieldAlias ?? f.field] = item[key]
                    })

                    vmodel.value = JSON.stringify(aux)
                }
                else {
                    // se debe completar el otro objeto xml
                    if (schemaData.value !== undefined) {
                        datasource.value?.dataSource.iapDataSourceFields.forEach(f => {
                            const objData = HelperUtils.getDeepvalueObjectByKey(schemaData.value, (f.fieldAlias ?? f.field))
                            if (objData) {
                                objData[(f.fieldAlias ?? f.field)]['#text'] = item[getFieldKeyColumn(f, (f?.dataSourceTableAliasId == null))]
                            }
                        })
                    }
                    else {
                        let _xml = '<' + getPropertyValue(StructuredFieldControlTypeConst.XML_ROOT_NAME) + '>';
                        datasource.value?.dataSource.iapDataSourceFields.forEach(f => {
                            _xml += '<' + (f.fieldAlias ?? f.field) + '>' + item[getFieldKeyColumn(f, (f?.dataSourceTableAliasId == null))] + '</' + (f.fieldAlias ?? f.field) + '>'

                        })
                        _xml += '</' + getPropertyValue(StructuredFieldControlTypeConst.XML_ROOT_NAME) + '>'
                        schemaData.value = parseXmlSchema(_xml)
                    }

                    vmodel.value = parseXmlSchemaToXml()

                }



            }


        }




        const loadSchemaFile = async (): Promise<boolean> => {


            return await new Promise<boolean>((resolve) => {

                const attrDictionary = props.Component.iapComponentAttributes.find(x => x.name == StructuredFieldControlTypeConst.JSON_SCHEMA);

                if (attrDictionary && attrDictionary?.valueBinaryFileName) {
                    const fileName = Environment.URL_COMPONENT_ATTR + attrDictionary.id + "_" + attrDictionary.valueBinaryFileName;
                    fetchWrapper.getBinaryFile(fileName + '.zip' + '?_t=' + new Date().getTime().toString(), store)
                        .then((response: any) => {
                            if (response) {
                                HelperCompress.decompressFile(attrDictionary.id + "_" + attrDictionary.valueBinaryFileName, response)
                                    .then((responseData: string) => {
                                        if (responseData) {
                                            schemaData.value = JSON.parse(responseData)

                                            responseData = ''
                                            resolve(true)
                                        }
                                        else {
                                            resolve(false)
                                        }
                                    })

                            }
                        }).catch(() => {
                            resolve(false)
                        })
                }
                else {
                    resolve(false)
                }
            });

        };


        const updateDataItem = (data: claveNew) => {
            if (datasource.value) {
                datasource.value.dataSource.items[0][data.fieldId] = data.value
                fieldToVmodel()

            }
        }

        const hasSlotForm = computed(() => {
            return (getLayoutComponent(props.Component, StructuredFieldControlTypeConst.LAYOUT_ADDFORM) !== undefined)
        })


        const updateDataSet = () => {
            const cds = props.Component.get(-1, 'iapComponentDataSources').find(x => x.id == getPropertyNumberValue(StructuredFieldControlTypeConst.DS))
            if (cds && datasource.value) {
                datasource.value = cds
                fieldToVmodel()
                updateVmodelFromParent()
            }
        }


        onMounted(() => {
            baseOnMounted();
            useJsonEditor.value = getPropertyBooleanValue(StructuredFieldControlTypeConst.USE_SCHEMA_EDITOR)
            watchEffect(() => {
                    if (hasLoadedComponent.value == true && !isLoaded) {
                        loadSchemaFile().then(() => {
                            if (!useJsonEditor.value) {
                                const cds = props.Component.get(-1,'iapComponentDataSources').find(x => x.id == getPropertyNumberValue(StructuredFieldControlTypeConst.DS))
                                if (cds) {
                                    datasource.value = cds
                                    insertEmptyRowToDataSource(null, [datasource.value as any])
                                }
                                const _xmlVmodel = vmodel.value ?? ''
                                if (!HelperCommon.isNullOrWhitespace(_xmlVmodel) && !parseToJson) {
                                    schemaData.value = parseXmlSchema(_xmlVmodel)
                                }

                            }

                            hasLoadedSchemaData.value = true

                            VmodelToField()
                            isLoaded = true;

                        })
                    }
                })


           

        })

       
        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
            if (editor) {
                editor.destroy()
            }
        })

        return {
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            StructuredFieldControlTypeConst,
            loaded,
            BaseControlTypeConst,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            , canDoOperation
            //, fieldsDef
            , submitted
            //,v$
            //,CatalogDataTypeConst

            , hasLoaded
            , catalogos
            , datasource
            , fieldToVmodel
            , updateDataItem
            , useJsonEditor
            , hasSlotForm
            ,componentKey
            ,updateDataSet

        };
    },
});
</script>
<style lang="css" scoped >
</style>
