<template>
    <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
    
    <div class="card" v-if="loaded">
        <div class="flex flex-column md:flex-row gap-4">
            <div class="w-full md:w-3 xl:w-2 xl:p-3">
                <MailSidebar :items="sidebarItems" :activeMailItem="activeMailItem" @navigate="handleNavigate"></MailSidebar>
            </div>
            <div class="md:w-9 xl:w-10 xl:p-3">
                <div class="w-full">
                    <MailTypes ref="emailTypes" v-if="(sections.inbox || sections.draft || sections.trash || sections.archived || sections.sent || sections.spam || sections.starred || sections.important || sections.folder != '' || sections.group != '') && filterFirstLoaded" :mails="allMails" :filterDefaultValue="filterDefaultValue" :totalMails="mailsCount" :objectId="objIdValue" :idObject="idObjectValue" @navigate="handleNavigate" @changeAccount="toggleChangeAccount" @reply="showReplyDialog" @changeMailType="onChangeMailType" @refresh="refresh" @pageChange="getMailsBySection" @trash="moveToTrash" @archive="moveToArchive" @assign="assignToObject" @spam="moveToSpam" v-model:Component="Component" :slotProps="slotProps">
                        <template #buttons>
                            <slot name="buttons"></slot>
                        </template>
                    </MailTypes>
                    <MailDetail v-if="sections.detail" :mails="allMails" :emailId="emailId" :groupId="sections.group" @navigate="handleNavigate" @reply="showReplyDialog" @changeMailType="onChangeMailType" @refresh="refresh" @trash="moveToTrash" @archive="moveToArchive" @spam="moveToSpam"></MailDetail>
                    <DynamicEmail v-if="sections.compose" v-model:paramEmail="myDraft" @update-email-id="updateEmailId" :objectId="objIdValue" :idObject="idObjectValue" :container="container" v-model:Component="Component" :slotProps="slotProps" @navigate="handleNavigate" @pageChange="getDrafts">
                        <template v-slot:to>
                            <slot name="to"></slot>
                        </template>

                        <template v-slot:body>
                            <slot name="body"></slot>
                        </template>
                    </DynamicEmail>
                </div>
            </div>
        </div>
    </div>
    <Reply v-model:visible="dialogVisible" :mailDetail="mailDetail" @save="onSaveReplyMail" @update:dialogVisible="onChangeDialogVisibility()"></Reply>

    <Dialog header="Cambiar a otra cuenta" v-model:visible="isDialogVisible"  modal class="mx-3 sm:mx-0 sm:w-full md:w-8 lg:w-6" contentClass="border-round-bottom border-top-1 surface-border p-0">

       <div class="p-0 m-0">
            <div class="surface-section grid grid-nogutter formgrid flex-column md:flex-row gap-6 p-5 border-round">
                <div class="col">
                    <label for="value" :class="{ 'p-invalid': v$.$error && submitted, 'customrequired': true }">Correo: </label>
                    <InputText id="value" v-model="changeAccountEmail" type="text"
                        :class="{ 'p-invalid': v$.$error && submitted }" aria-describedby="text-error" />
                </div>
            </div>

            <div class="flex column-gap-3 justify-content-end p-5 border-top-1 surface-border">
                <Button type="button" class="h-3rem" icon="pi pi-users" label="Cambiar cuenta" @click="doChangeAccount"></Button>
            </div>
        </div>
   </Dialog>
</template>
<script lang="ts">


import { defineComponent, onMounted, ref, shallowRef, onBeforeUnmount, watch, watchEffect, computed, onUnmounted } from 'vue';
import { MessageService } from '../../../../../../../common/infrastructure/servicios';
import { MessageType } from '../../../../../../../common/infrastructure/servicios/MessageService';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';


import { useStore } from 'vuex';
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import SummaryDebug from '../../shared/SummaryDebug.vue';
import Editor from 'primevue/editor';
import EmailTypeConst from '../../../../domain/Constants/EmailTypeConst';
import ControlTypeConst from '../../../../domain/Constants/ControlTypeConst';
import CrudDocument from '../../../../../../builderDocument/document/infrastructure/component/CrudDocument.vue'
import { StorageDocument } from '../../../../../../builderDocument/document/domain/storageDocument';
import { FileUploadRemoveEvent } from 'primevue/fileupload';
import { uploadFile } from '../../../../../../builderMaster/auth/domain/uploadFile';
import HelperCommon from '../../../../../../../common/infrastructure/funciones/HelperCommon';
import { Actions } from '../../../../../../../common/infrastructure/almacen/enums/StoreEnums';
import HelperLoading from '@ilinium/shared/src/common/infrastructure/funciones/HelperLoading';
import { msalInstance, initializeMsalInstance, authMicrosoft, getAccessToken } from "../../../../../../builderMaster/auth/infrastructure/component/authConfig";
import { useRoute } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import MailSidebar from './DynamicEmailSideBar.vue';
import MailTypes from './DynamicEmailTypes.vue';
import MailDetail from './DynamicEmailDetail.vue';
import Reply from './DynamicEmailReply.vue';
import DynamicEmail from './DynamicEmail.vue';

export default defineComponent({
    name: 'dynamic_crud_tabla',
    components: {
        SummaryDebug,
        Editor,
        CrudDocument,
        MailSidebar,
        MailTypes,
        MailDetail,
        Reply,
        DynamicEmail
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
       
        slotProps: {
            type: Object,
            default: () => ({})
        },
        formKey: {
            type: String,
            default: ''
        },

    },
    setup(props, context) {
        //
        const store = useStore();
        const { vmodel, canDoOperation, getProperty, getPropertyValue, setPropertyValueByName, 
            getPropertyBooleanValue, getPropertyNumberValue, getLayoutComponent, loaded, processEventComponent
            ,componentKey,baseOnBeforeUnmount
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);
        
        const objIdValue = ref(getPropertyNumberValue(EmailTypeConst.OBJECTID));
        const idObjectValue = ref(getPropertyNumberValue(EmailTypeConst.IDOBJECT));
        const filterDefaultValue = ref(getPropertyValue(EmailTypeConst.FILTERDEFAULTVALUE));
        const emailTypes = ref(null) as any;
        const sortField = ref('receivedDateTime');  // Campo por defecto para ordenar
        const sortOrder = ref(-1);  // Orden descendente por defecto
        const toast = useToast();
        const emailId = ref(null) as any;
        const allMails = ref([]) as any;
        const filteredMails = ref({});
        const activeMailItem = ref('inbox');
        const fromActiveMailItem = ref('');
        const dialogVisible = ref(false);
        const mailDetail = ref(null);
        const mailsCount = ref(0);
        const router = useRoute();
        const filterFirstLoaded = ref(false);
        const opChangeAccount = ref();
        const changeAccountEmail = ref('')
        const submitted = ref(false);
        const isDialogVisible = ref(false);

        const loadStarred = async (loading: boolean = true, targetEmail: string = '') => {
            var starred = {} as any;
            if (filterDefaultValue.value) {
                starred = await getStarred({ searchFilters: { fromTo: filterDefaultValue.value }, page: EmailTypeConst.TOP, skip: 0, sortField: sortField.value, sortOrder: sortOrder.value }, loading, targetEmail);
            }
            else {
                starred = await getStarred({ page: EmailTypeConst.TOP, skip: 0, sortField: sortField.value, sortOrder: sortOrder.value }, loading, targetEmail);
            }
            allMails.value = starred?.messages;
            mailsCount.value = starred?.total;
        };

        const loadImportant = async (loading: boolean = true, targetEmail: string = '') => {
            var important = {} as any;
            if (filterDefaultValue.value) {
                important = await getImportants({ searchFilters: { fromTo: filterDefaultValue.value }, page: EmailTypeConst.TOP, skip: 0, sortField: sortField.value, sortOrder: sortOrder.value }, loading, targetEmail);
            }
            else {
                important = await getImportants({ page: EmailTypeConst.TOP, skip: 0, sortField: sortField.value, sortOrder: sortOrder.value }, loading, targetEmail);
            }
            allMails.value = important?.messages;
            mailsCount.value = important?.total;
        };

        const loadSpam = async (loading: boolean = true, targetEmail: string = '') => {
            var spam = {} as any;
            if (filterDefaultValue.value) {
                spam = await getSpam({ searchFilters: { fromTo: filterDefaultValue.value }, page: EmailTypeConst.TOP, skip: 0, sortField: sortField.value, sortOrder: sortOrder.value }, loading, targetEmail);
            }
            else {
                spam = await getSpam({ page: EmailTypeConst.TOP, skip: 0, sortField: sortField.value, sortOrder: sortOrder.value }, loading, targetEmail);
            }
            allMails.value = spam?.messages;
            mailsCount.value = spam?.total;
        };

        const loadSent = async (loading: boolean = true, targetEmail: string = '') => {
            var sent = {} as any;
            if (filterDefaultValue.value) {
                sent = await getSentFiltered({ searchFilters: { fromTo: filterDefaultValue.value }, page: EmailTypeConst.TOP, skip: 0, sortField: sortField.value, sortOrder: sortOrder.value }, loading, targetEmail);
            }
            else {
                sent = await getSent({ page: EmailTypeConst.TOP, skip: 0, sortField: sortField.value, sortOrder: sortOrder.value }, loading, targetEmail);
            }
            allMails.value = sent?.messages;
            mailsCount.value = sent?.total;
        };

        const loadArchived = async (loading: boolean = true, targetEmail: string = '') => {
            var archived = {} as any;
            if (filterDefaultValue.value) {
                archived = await getArchived({ searchFilters: { fromTo: filterDefaultValue.value }, page: EmailTypeConst.TOP, skip: 0, sortField: sortField.value, sortOrder: sortOrder.value }, loading, targetEmail);
            }
            else {
                archived = await getArchived({ page: EmailTypeConst.TOP, skip: 0, sortField: sortField.value, sortOrder: sortOrder.value }, loading, targetEmail);
            }
            allMails.value = archived?.messages;
            mailsCount.value = archived?.total;
        };

        const loadGroup = async (loading: boolean = true) => {
            var mails = {} as any;
            if (filterDefaultValue) {
                mails = await getGroupEmails(sections.value.group, { searchFilters: { fromTo: filterDefaultValue.value }, page: EmailTypeConst.TOP, skip: 0, sortField: sortField.value, sortOrder: sortOrder.value }, loading);
            }
            else {
                mails = await getGroupEmails(sections.value.group, { page: EmailTypeConst.TOP, skip: 0, sortField: sortField.value, sortOrder: sortOrder.value }, loading);
            }
            allMails.value = mails?.messages;
            mailsCount.value = mails?.total;
        };

        const loadFolder = async (loading: boolean = true) => {
            var mails = {} as any;
            if (filterDefaultValue.value) {
                mails = await getMailsFromFolder(sections.value.folder, { searchFilters: { fromTo: filterDefaultValue.value }, page: EmailTypeConst.TOP, skip: 0, sortField: sortField.value, sortOrder: sortOrder.value }, loading);
            }
            else {
                mails = await getMailsFromFolder(sections.value.folder, { page: EmailTypeConst.TOP, skip: 0, sortField: sortField.value, sortOrder: sortOrder.value }, loading);
            }
            allMails.value = mails?.messages;
            mailsCount.value = mails?.total;
        };

        const loadInbox = async (loading: boolean = true, targetEmail: string = '') => {
            var mails = {} as any;
            if (filterDefaultValue.value) {
                mails = await getMails({ searchFilters: { fromTo: filterDefaultValue.value }, page: EmailTypeConst.TOP, skip: 0, sortField: sortField.value, sortOrder: sortOrder.value }, loading, targetEmail);
            }
            else {
                mails = await getMails({ page: EmailTypeConst.TOP, skip: 0, sortField: sortField.value, sortOrder: sortOrder.value }, loading, targetEmail);
            }
            allMails.value = mails?.messages;
            mailsCount.value = mails?.total;
        };

        const loadDraft = async (loading: boolean = true, targetEmail: string = '') => {
            var drafts = {} as any;
            if (filterDefaultValue.value) {
                drafts = await getDrafts({ searchFilters: { fromTo: filterDefaultValue.value }, page: EmailTypeConst.TOP, skip: 0, sortField: sortField.value, sortOrder: sortOrder.value }, loading, targetEmail);
            }
            else {
                drafts = await getDrafts({ page: EmailTypeConst.TOP, skip: 0, sortField: sortField.value, sortOrder: sortOrder.value }, loading, targetEmail);
            }
            allMails.value = drafts?.messages;
            mailsCount.value = drafts?.total;
        };

        const loadTrash = async (loading: boolean = true, targetEmail: string = '') => {
            
            var trash = {} as any;
            if (filterDefaultValue.value) {
                trash = await getTrash({ searchFilters: { fromTo: filterDefaultValue.value }, page: EmailTypeConst.TOP, skip: 0, sortField: sortField.value, sortOrder: sortOrder.value }, loading, targetEmail);
            }
            else {
                trash = await getTrash({ page: EmailTypeConst.TOP, skip: 0, sortField: sortField.value, sortOrder: sortOrder.value }, loading, targetEmail);
            }
            allMails.value = trash?.messages;
            mailsCount.value = trash?.total;
        };

        const sections = ref({
            inbox: true,
            draft: false,
            trash: false,
            detail: false,
            archived: false,
            starred: false,
            important: false,
            sent: false,
            spam: false,
            compose: false,
            folder: '',
            group: '',
        });

        const toggleChangeAccount = () => {
            changeAccountEmail.value = '';
            isDialogVisible.value = true;
        };

        const rules = computed(() => ({
            changeAccountEmail: {
                required: helpers.withMessage('La cuenta es obligatoria', required),
            },
        }))

        const v$ = useVuelidate(rules, { changeAccountEmail })

        const isValidData = (): boolean => {
            submitted.value = true;
            v$.value.$touch();
            if (v$.value.$invalid) {
                HelperCommon.setFocusOnFirstError();
                return false;
            }

            return true;
        }

        const handleNavigate = async (section: any) => {
            fromActiveMailItem.value = activeMailItem.value;
            activeMailItem.value = section.groupId ? section.groupId : (section.folderId ? section.folderId : (section.targetSection ? section.targetSection : section));
            if (activeMailItem.value === 'inbox') {
                loadInbox(true, changeAccountEmail.value);
                loadSubfolders(section.staticFolderId);
                sections.value = { inbox: true, draft: false, archived: false, detail: false, compose: false, starred: false, important: false, sent: false, trash: false, spam: false, folder: '', group: '' };
            } else if (activeMailItem.value === 'draft') {
                loadDraft(true, changeAccountEmail.value);
                loadSubfolders(section.staticFolderId);
                sections.value = { inbox: false, draft: true, archived: false, detail: false, compose: false, starred: false, important: false, sent: false, trash: false, spam: false, folder: '', group: '' };
            } else if (activeMailItem.value === 'trash') {
                loadTrash(true, changeAccountEmail.value);
                loadSubfolders(section.staticFolderId);
                sections.value = { inbox: false, draft: false, archived: false, detail: false, compose: false, starred: false, important: false, sent: false, trash: true, spam: false, folder: '', group: '' };
            } else if (activeMailItem.value === 'starred') {
                loadStarred(true, changeAccountEmail.value);
                sections.value = { inbox: false, draft: false, archived: false, detail: false, compose: false, starred: true, important: false, sent: false, trash: false, spam: false, folder: '', group: '' };
            } else if (activeMailItem.value === 'important') {
                loadImportant(true, changeAccountEmail.value);
                sections.value = { inbox: false, draft: false, archived: false, detail: false, compose: false, starred: false, important: true, sent: false, trash: false, spam: false, folder: '', group: '' };
            } else if (activeMailItem.value === 'spam') {
                loadSpam(true, changeAccountEmail.value);
                loadSubfolders(section.staticFolderId);
                sections.value = { inbox: false, draft: false, archived: false, detail: false, compose: false, starred: false, important: false, sent: false, trash: false, spam: true, folder: '', group: '' };
            } else if (activeMailItem.value === 'sent') {
                loadSubfolders(section.staticFolderId);
                loadSent(true, changeAccountEmail.value);
                sections.value = { inbox: false, draft: false, archived: false, detail: false, compose: false, starred: false, important: false, sent: true, trash: false, spam: false, folder: '', group: '' };
            } else if (activeMailItem.value === 'archived') {
                loadSubfolders(section.staticFolderId);
                loadArchived(true, changeAccountEmail.value);
                sections.value = { inbox: false, draft: false, archived: true, detail: false, compose: false, starred: false, important: false, sent: false, trash: false, spam: false, folder: '', group: '' };
            } else if (activeMailItem.value === 'groups') {
                var groups = await getUserGroups();
            } else if (activeMailItem.value === 'detail') {
                emailId.value = section.emailId;
                if (allMails.value.find(d => d.id === section.emailId) && allMails.value.find(d => d.id === section.emailId).isDraft == true) {
                    sections.value = { inbox: false, draft: false, archived: false, detail: false, compose: true, starred: false, important: false, sent: false, trash: false, spam: false, folder: '', group: '' };
                }
                else {
                    var mails = allMails.value;
                    if (mails && mails.length && mails.filter(m => m.id == section.emailId).length > 0) {
                        var mail = mails.find(m => m.id == section.emailId);
                        if (!mail.isRead) {
                            await markAsRead(section.emailId);
                        }
                    }
                    sections.value = { inbox: false, draft: false, archived: false, detail: true, compose: false, starred: false, important: false, sent: false, trash: false, spam: false, folder: '', group: '' };
                }
            } else if (activeMailItem.value === 'compose') {
                emailId.value = null;
                sections.value = { inbox: false, draft: false, archived: false, detail: false, compose: true, starred: false, important: false, sent: false, trash: false, spam: false, folder: '', group: '' };
            }
            else if (section.folderId && section.folderId != '') { // ES UNA CARPETA y en el valor es el id de la carpeta
                sections.value = { inbox: false, draft: false, archived: false, detail: false, compose: false, starred: false, important: false, sent: false, trash: false, spam: false, folder: section.folderId, group: '' };
                loadFolder();
                loadSubfolders(section.folderId);
            }
            else if (section.groupId && section.groupId != '') { // ES UN grupo
                sections.value = { inbox: false, draft: false, archived: false, detail: false, compose: false, starred: false, important: false, sent: false, trash: false, spam: false, folder: '', group: section.groupId };
                loadGroup();
            }
        };

        const loadSubfolders = async(folderId: string) => {
            var subFolders = await getSubfoldersByFolderId(folderId);
            const targetIndex = sideBarMenu.value.findIndex(f => f.folderId === folderId || f.staticFolderId === folderId);
            for (const f of subFolders) {
                const exists = sideBarMenu.value.some(item => item.folderId === f.id);
                if (!exists) {
                    sideBarMenu.value.splice(targetIndex + 1, 0, { label: f.displayName, icon: 'pi pi-folder-open', badge: allMails.value.filter(m => !m.isRead)?.length || 0, routerLink: 'folder', folderId: f.id, childFolderCount: f.childFolderCount, childLevel: sideBarMenu.value.find(f => f.folderId === folderId || f.staticFolderId === folderId).childLevel + 1, displayed: 0 });
                    sideBarMenu.value[targetIndex].displayed = 1;
                }
                else {
                    /*sideBarMenu.value = sideBarMenu.value.filter(item => 
                        !subFolders.some(sub => sub.id === item.folderId)
                    );*/
                    let deleteIndex = targetIndex + 1;
                    while (deleteIndex < sideBarMenu.value.length && sideBarMenu.value[deleteIndex].childLevel > 0) {
                        sideBarMenu.value.splice(deleteIndex, 1);
                    }
                    sideBarMenu.value[targetIndex].displayed = 0;
                    break;
                }
            }
        };

        /*watch(
            () => router.path,
            (newPath) => {
                activeMailItem.value = newPath.split('/')[3];
            }
        );*/

        watchEffect(() => {
            if (loaded.value == true) {
                objIdValue.value = getPropertyNumberValue(EmailTypeConst.OBJECTID);
                idObjectValue.value = getPropertyNumberValue(EmailTypeConst.IDOBJECT);
                filterDefaultValue.value = getPropertyValue(EmailTypeConst.FILTERDEFAULTVALUE);
            }
        })


        const initMail = async (mails) => {
            setBadgeValues(mails);
        };

        const refresh = async () => {
            HelperLoading.showLoading();
            await loadCurrentSection();
            HelperLoading.hideLoading();
        };

        const markAsRead = async (messageId: string) => {
            HelperLoading.showLoading();
            const accessToken = await getAccessToken();
            if (accessToken) {
                let url;
                if (changeAccountEmail.value === '') {
                    url = `https://graph.microsoft.com/v1.0/me/messages/${messageId}`;
                } else {
                    url = `https://graph.microsoft.com/v1.0/users/${encodeURIComponent(changeAccountEmail.value)}/messages/${encodeURIComponent(messageId)}`;
                }

                try {
                    const response = await fetch(url, {
                        method: 'PATCH',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            isRead: true
                        })
                    });

                    if (!response.ok) {
                        throw new Error(`Error al marcar el correo como leído: ${response.statusText}`);
                    }

                    console.log("Correo marcado como leído");
                } catch (error) {
                    console.error("Error al marcar el correo como leído:", error);
                }
            } else {
                console.error("No se pudo obtener el token de acceso.");
            }
            HelperLoading.hideLoading();
        };

        const markAsFlagged = async (messageId: string, flagged: string) => {
            HelperLoading.showLoading();
            const accessToken = await getAccessToken();
            if (accessToken) {
                let url;
                if (changeAccountEmail.value === '') {
                    url = `https://graph.microsoft.com/v1.0/me/messages/${encodeURIComponent(messageId)}`;
                } else {
                    url = `https://graph.microsoft.com/v1.0/users/${encodeURIComponent(changeAccountEmail.value)}/messages/${encodeURIComponent(messageId)}`;
                }

                try {
                    const response = await fetch(url, {
                        method: 'PATCH',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            flag: {
                                flagStatus: flagged
                            }
                        })
                    });

                    if (!response.ok) {
                        throw new Error(`Error al marcar el correo como destacado: ${response.statusText}`);
                    }

                    console.log("Correo marcado como destacado");
                } catch (error) {
                    console.error("Error al marcar el correo como destacado:", error);
                }
            } else {
                console.error("No se pudo obtener el token de acceso.");
            }
            HelperLoading.hideLoading();
        };

        const markAsImportant = async (messageId: string, importance: string) => {
            HelperLoading.showLoading();
            const accessToken = await getAccessToken();
            if (accessToken) {
                let url;
                if (changeAccountEmail.value === '') {
                    url = `https://graph.microsoft.com/v1.0/me/messages/${encodeURIComponent(messageId)}`;
                } else {
                    url = `https://graph.microsoft.com/v1.0/users/${encodeURIComponent(changeAccountEmail.value)}/messages/${encodeURIComponent(messageId)}`;
                }

                try {
                    const response = await fetch(url, {
                        method: 'PATCH',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            importance: importance
                        })
                    });

                    if (!response.ok) {
                        throw new Error(`Error al marcar el correo como importante: ${response.statusText}`);
                    }

                    console.log("Correo marcado como importante");
                } catch (error) {
                    console.error("Error al marcar el correo como importante:", error);
                }
            } else {
                console.error("No se pudo obtener el token de acceso.");
            }
            HelperLoading.hideLoading();
        };

        const getMailsBySection = async (object: any, loading: boolean) => {
            if (sections.value.inbox) {
                return await getMails(object, loading);
            }
            else if (sections.value.starred) {
                return await getStarred(object, loading);
            }
            else if (sections.value.spam) {
                return await getSpam(object, loading);
            }
            else if (sections.value.important) {
                return await getImportants(object, loading);
            }
            else if (sections.value.sent) {
                if (filterDefaultValue.value) {
                    return await getSentFiltered(object, loading, changeAccountEmail.value);
                }
                else {
                    return await getSent(object, loading);
                }
            }
            else if (sections.value.archived) {
                return await getArchived(object, loading);
            }
            else if (sections.value.draft) {
                return await getDrafts(object, loading);
            }
            else if (sections.value.trash) {
                return await getTrash(object, loading);
            }
            else if (sections.value.folder != '') {
                return await getMailsFromFolder(sections.value.folder, object, loading);
            }
            else if (sections.value.group != '') {
                return await await getGroupEmails(sections.value.group, object, loading);
            }
        }

        const getUser = async () => {
            const accessToken = await getAccessToken();
            if (accessToken) {
                try {
                    const response = await fetch('https://graph.microsoft.com/v1.0/me', {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        }
                    });

                    if (!response.ok) {
                        throw new Error(`Error en la petición: ${response.statusText}`);
                    }

                    const user = await response.json();
                    console.log('Usuario actual:', user);
                    return user;
                } catch (error) {
                    console.error("Error al obtener el usuario:", error);
                    return null;
                }
            }
        };

        const getSharedMailsWithEmail = async (sharedMailboxEmail, accessToken) => {
            const url = `https://graph.microsoft.com/v1.0/users/${sharedMailboxEmail}/mailFolders/inbox/messages`;

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`Error fetching shared mailbox messages: ${response.statusText}`);
            }

            const data = await response.json();
            return data.value;
        };

        const getGroupEmails = async (groupId: string, object: any, loading: boolean) => {
            if (loading) {
                HelperLoading.showLoading();
            }
            const accessToken = await getAccessToken();
            if (accessToken) {
                let url = `https://graph.microsoft.com/v1.0/groups/${groupId}/conversations?$top=${object.page}&$skip=${object.skip}&$count=true`;

                let filters: string[] = [];
                //let filters: string[] = ['lastDeliveredDateTime ge 1900-01-01'];

                if (object.searchFilters?.fromTo) {
                    const encodedFromTo = encodeURIComponent(object.searchFilters.fromTo);
                    //filters.push(`(contains(from/emailAddress/address, '${encodedFromTo}') or contains(toRecipients/emailAddress/address, '${encodedFromTo}'))`);
                    //filters.push(`(contains(from/emailAddress/address, '${encodedFromTo}') or toRecipients/any(r: r/emailAddress/address eq '${encodedFromTo}'))`);
                    filters.push(`contains(from/emailAddress/address, '${encodedFromTo}')`);
                }

                if (object.searchFilters?.subject) {
                    const encodedSubject = encodeURIComponent(object.searchFilters.subject);
                    filters.push(`contains(subject, '${encodedSubject}')`);
                }

                if (object.searchFilters?.body) {
                    const encodedBody = encodeURIComponent(object.searchFilters.body);
                    filters.push(`contains(body/content, '${encodedBody}')`);
                }

                if (filters.length > 0) {
                    const filterString = filters.join(' and ');
                    url += `&$filter=${filterString}`;
                }

                /*if (object.sortField) {
                    const order = object.sortOrder === -1 ? 'desc' : 'asc'; // Definir 'asc' o 'desc' según el valor de sortOrder
                    url += `&$orderby=${object.sortField} ${order}`;
                }*/

                try {
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });

                    HelperLoading.hideLoading();
                    if (!response.ok) {
                        throw new Error(`Error al obtener correos del grupo: ${response.statusText}`);
                    }

                    const conversations = await response.json();
                    
                    allMails.value = conversations.value;
                    mailsCount.value = conversations['@odata.count'];

                    return {
                        messages: conversations.value,
                        total: conversations['@odata.count']
                    };

                } catch (error) {
                    HelperLoading.hideLoading();
                    console.error("Error al obtener correos del grupo:", error);
                    return [];
                }
            }
        };

        const getUserGroups = async () => {
            const accessToken = await getAccessToken(); // Obtén el token de acceso

            if (accessToken) {
                const url = `https://graph.microsoft.com/v1.0/me/memberOf`;

                try {
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });

                    if (!response.ok) {
                        throw new Error(`Error al obtener grupos del usuario: ${response.statusText}`);
                    }

                    var groups = await response.json();
                    //groups.value = groups.value;//.filter(g => g.groupTypes.includes('Unified'));

                    var groupsIndex = sideBarMenu.value.findIndex(g => g.label == 'Grupos')
                    for (const g of groups.value) {
                        const exists = sideBarMenu.value.some(item => item.groupId === g.id);
                        if (!exists) {
                            sideBarMenu.value.push({ label: g.displayName, icon: 'pi pi-users', badge: allMails.value.filter(m => !m.isRead)?.length || 0, routerLink: 'group', groupId: g.id, childLevel: 1, displayed: 0 });
                            sideBarMenu.value[groupsIndex].displayed = 1;
                        }
                        else {
                            sideBarMenu.value = sideBarMenu.value.filter(item => 
                                !groups.value.some(g => g.id === item.groupId)
                            );
                            sideBarMenu.value[groupsIndex].displayed = 0;
                            break;
                        }
                    }

                    return groups.value; // Devuelve la lista de grupos

                } catch (error) {
                    console.error("Error al obtener grupos del usuario:", error);
                }
            }
        };

        const getSharedMails = async (sharedMailboxUserId) => {
            const accessToken = await getAccessToken();
            if (accessToken) {
                const url = `https://graph.microsoft.com/v1.0/users/${sharedMailboxUserId}/mailFolders/inbox/messages`;

                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`Error fetching shared mailbox messages: ${response.statusText}`);
                }

                const data = await response.json();
                return data.value;
            }
            return null;
        };

        const getUsers = async () => {
            const accessToken = await getAccessToken();
            if (accessToken) {
                try {
                    const response = await fetch('https://graph.microsoft.com/v1.0/users', {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        }
                    });

                    if (!response.ok) {
                        throw new Error(`Error en la petición: ${response.statusText}`);
                    }

                    const users = await response.json();
                    console.log('Usuarios disponibles:', users.value);
                    return users.value;
                } catch (error) {
                    console.error("Error al obtener usuarios:", error);
                    return null;
                }
            }
        };

        const getMails = async (object: any, loading: boolean, targetEmail: string = '') => {
            if (loading) {
                HelperLoading.showLoading();
            }
            const accessToken = await getAccessToken();
            if (accessToken) {
                let url = '';
                if (targetEmail == '') {
                    url = `https://graph.microsoft.com/v1.0/me/mailFolders/inbox/messages?$top=${object.page}&$skip=${object.skip}&$count=true&$expand=singleValueExtendedProperties($filter=id eq 'String {11223344-5566-7788-99AA-BBCCDDEEFF00} Name objectId' or id eq 'String {AABBCCDD-EEFF-0011-2233-445566778899} Name idObject')`;
                }
                else {
                    url = `https://graph.microsoft.com/v1.0/users/${encodeURIComponent(targetEmail)}/mailFolders/inbox/messages?$top=${object.page}&$skip=${object.skip}&$count=true&$expand=singleValueExtendedProperties($filter=id eq 'String {11223344-5566-7788-99AA-BBCCDDEEFF00} Name objectId' or id eq 'String {AABBCCDD-EEFF-0011-2233-445566778899} Name idObject')`;
                }
                
                let filters: string[] = ['receivedDateTime ge 1900-01-01'];

                if (object.searchFilters?.fromTo) {
                    const encodedFromTo = encodeURIComponent(object.searchFilters.fromTo);
                    //filters.push(`(contains(from/emailAddress/address, '${encodedFromTo}') or contains(toRecipients/emailAddress/address, '${encodedFromTo}'))`);
                    //filters.push(`(contains(from/emailAddress/address, '${encodedFromTo}') or toRecipients/any(r: r/emailAddress/address eq '${encodedFromTo}'))`);
                    filters.push(`contains(from/emailAddress/address, '${encodedFromTo}')`);
                }

                if (object.searchFilters?.subject) {
                    const encodedSubject = encodeURIComponent(object.searchFilters.subject);
                    filters.push(`contains(subject, '${encodedSubject}')`);
                }

                if (object.searchFilters?.body) {
                    const encodedBody = encodeURIComponent(object.searchFilters.body);
                    filters.push(`contains(body/content, '${encodedBody}')`);
                }

                if (filters.length > 0) {
                    const filterString = filters.join(' and ');
                    url += `&$filter=${filterString}`;
                }

                if (object.sortField) {
                    const order = object.sortOrder === -1 ? 'desc' : 'asc'; // Definir 'asc' o 'desc' según el valor de sortOrder
                    url += `&$orderby=${object.sortField} ${order}`;
                }

                try {
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        }
                    });

                    HelperLoading.hideLoading();
                    if (!response.ok) {
                        throw new Error(`Error en la petición: ${response.statusText}`);
                    }

                    const messages = await response.json();

                    allMails.value = messages.value;
                    mailsCount.value = messages['@odata.count'];

                    return {
                        messages: messages.value,
                        total: messages['@odata.count']
                    };

                } catch (error) {
                    HelperLoading.hideLoading();
                    console.error("Error al obtener correos:", error);
                    return null;
                }

            } else {
                HelperLoading.hideLoading();
                return null;
            }
        };

        const getMailsFromFolder = async (folderId: string, object: any, loading: boolean) => {
            if (loading) {
                HelperLoading.showLoading();
            }
            const accessToken = await getAccessToken();
            if (accessToken) {
                let url = `https://graph.microsoft.com/v1.0/me/mailFolders/${folderId}/messages?$top=${object.page}&$skip=${object.skip}&$count=true&$expand=singleValueExtendedProperties($filter=id eq 'String {11223344-5566-7788-99AA-BBCCDDEEFF00} Name objectId' or id eq 'String {AABBCCDD-EEFF-0011-2233-445566778899} Name idObject')`;

                let filters: string[] = ['receivedDateTime ge 1900-01-01'];

                // Aplicar filtros de búsqueda
                if (object.searchFilters?.fromTo) {
                    const encodedFromTo = encodeURIComponent(object.searchFilters.fromTo);
                    filters.push(`contains(from/emailAddress/address, '${encodedFromTo}')`);
                }

                if (object.searchFilters?.subject) {
                    const encodedSubject = encodeURIComponent(object.searchFilters.subject);
                    filters.push(`contains(subject, '${encodedSubject}')`);
                }

                if (object.searchFilters?.body) {
                    const encodedBody = encodeURIComponent(object.searchFilters.body);
                    filters.push(`contains(body/content, '${encodedBody}')`);
                }

                if (filters.length > 0) {
                    const filterString = filters.join(' and ');
                    url += `&$filter=${filterString}`;
                }

                // Ordenar los resultados
                if (object.sortField) {
                    const order = object.sortOrder === -1 ? 'desc' : 'asc';
                    url += `&$orderby=${object.sortField} ${order}`;
                }

                try {
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        }
                    });

                    HelperLoading.hideLoading();
                    if (!response.ok) {
                        throw new Error(`Error en la petición: ${response.statusText}`);
                    }

                    const messages = await response.json();

                    allMails.value = messages.value;
                    mailsCount.value = messages['@odata.count'];

                    return {
                        messages: messages.value,
                        total: messages['@odata.count']
                    };

                } catch (error) {
                    HelperLoading.hideLoading();
                    console.error("Error al obtener correos:", error);
                    return null;
                }

            } else {
                HelperLoading.hideLoading();
                return null;
            }
        };

        const getMailFolders = async (loading = false) => {
            if (loading) {
                HelperLoading.showLoading();
            }

            const accessToken = await getAccessToken();
            if (!accessToken) {
                HelperLoading.hideLoading();
                console.error("No se pudo obtener el token de acceso.");
                return null;
            }

            const url = 'https://graph.microsoft.com/v1.0/me/mailFolders?$expand=childFolders';

            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    }
                });

                HelperLoading.hideLoading();

                if (!response.ok) {
                    throw new Error(`Error en la petición: ${response.statusText}`);
                }

                const mailFolders = await response.json();
                const flattenedFolders = flattenMailFolders(mailFolders.value); // Desanidar las carpetas
                return flattenedFolders;

            } catch (error) {
                HelperLoading.hideLoading();
                console.error("Error al obtener las carpetas con subcarpetas:", error);
                return null;
            }
        };

        const getSubfoldersByFolderId = async (folderId: string, loading = true) => {
            if (loading) {
                HelperLoading.showLoading();
            }

            const accessToken = await getAccessToken();
            if (!accessToken) {
                HelperLoading.hideLoading();
                console.error("No se pudo obtener el token de acceso.");
                return null;
            }

            const url = `https://graph.microsoft.com/v1.0/me/mailFolders/${folderId}/childFolders?$expand=childFolders`;

            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    }
                });

                HelperLoading.hideLoading();

                if (!response.ok) {
                    throw new Error(`Error en la petición: ${response.statusText}`);
                }
                const mailFolders = await response.json();
                return flattenMailFolders(mailFolders.value); // Desanidar las carpetas si es necesario

            } catch (error) {
                HelperLoading.hideLoading();
                console.error(`Error al obtener las carpetas del folder ${folderId}:`, error);
                return null;
            }
        };

        const flattenMailFolders = (folders: any) => {
            const flattened = [] as any;

            const processFolder = (folder) => {
                flattened.push({
                    id: folder.id,
                    displayName: folder.displayName,
                    parentFolderId: folder.parentFolderId || null,
                    childFolderCount: folder.childFolderCount || 0,
                });

                /*if (folder.childFolders && folder.childFolders.length > 0) {
                    folder.childFolders.forEach(processFolder);
                }*/
            };

            folders.forEach(processFolder);
            return flattened;
        };

        const getTrash = async (object: any, loading: boolean, targetEmail: string = '') => {
            if (loading) {
                HelperLoading.showLoading();
            }
            const accessToken = await getAccessToken();
            if (accessToken) {

                let url;
                if (targetEmail == '') {
                    url = `https://graph.microsoft.com/v1.0/me/mailFolders/deletedItems/messages?$top=${object.page}&$skip=${object.skip}&$count=true`;
                } else {
                    url = `https://graph.microsoft.com/v1.0/users/${encodeURIComponent(targetEmail)}/mailFolders/deletedItems/messages?$top=${object.page}&$skip=${object.skip}&$count=true`;
                }

                let filters: string[] = ['receivedDateTime ge 1900-01-01'];

                if (object.searchFilters?.fromTo) {
                    const encodedFromTo = encodeURIComponent(object.searchFilters.fromTo);
                    //filters.push(`(contains(from/emailAddress/name, '${encodedFromTo}') or contains(toRecipients/emailAddress/name, '${encodedFromTo}'))`);
                    filters.push(`contains(from/emailAddress/address, '${encodedFromTo}')`);
                }

                if (object.searchFilters?.subject) {
                    const encodedSubject = encodeURIComponent(object.searchFilters.subject);
                    filters.push(`contains(subject, '${encodedSubject}')`);
                }

                if (object.searchFilters?.body) {
                    const encodedBody = encodeURIComponent(object.searchFilters.body);
                    filters.push(`contains(body/content, '${encodedBody}')`);
                }

                if (filters.length > 0) {
                    const filterString = filters.join(' and ');
                    url += `&$filter=${filterString}`;
                }

                if (object.sortField) {
                    const order = object.sortOrder === -1 ? 'desc' : 'asc'; // Definir 'asc' o 'desc' según el valor de sortOrder
                    url += `&$orderby=${object.sortField} ${order}`;
                }

                try {
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        }
                    });

                    HelperLoading.hideLoading();
                    if (!response.ok) {
                        throw new Error(`Error en la petición: ${response.statusText}`);
                    }

                    const messages = await response.json();

                    // Actualizar la lista de correos eliminados y el conteo total
                    allMails.value = messages.value;
                    mailsCount.value = messages['@odata.count'];

                    return {
                        messages: messages.value,
                        total: messages['@odata.count']
                    };

                } catch (error) {
                    HelperLoading.hideLoading();
                    console.error("Error al obtener correos eliminados:", error);
                    return null;
                }

            } else {
                HelperLoading.hideLoading();
                return null;
            }
        };

        const getArchived = async (object: any, loading: boolean, targetEmail: string = '') => {
            if (loading) {
                HelperLoading.showLoading();
            }
            const accessToken = await getAccessToken();
            if (accessToken) {
                let url;
                if (targetEmail == '') {
                    url = `https://graph.microsoft.com/v1.0/me/mailFolders/archive/messages?$top=${object.page}&$skip=${object.skip}&$count=true`;
                } else {
                    url = `https://graph.microsoft.com/v1.0/users/${encodeURIComponent(targetEmail)}/mailFolders/archive/messages?$top=${object.page}&$skip=${object.skip}&$count=true`;
                }

                let filters: string[] = ['receivedDateTime ge 1900-01-01'];

                if (object.searchFilters?.fromTo) {
                    const encodedFromTo = encodeURIComponent(object.searchFilters.fromTo);
                    //filters.push(`(contains(from/emailAddress/name, '${encodedFromTo}') or contains(toRecipients/emailAddress/name, '${encodedFromTo}'))`);
                    filters.push(`contains(from/emailAddress/address, '${encodedFromTo}')`);
                }

                if (object.searchFilters?.subject) {
                    const encodedSubject = encodeURIComponent(object.searchFilters.subject);
                    filters.push(`contains(subject, '${encodedSubject}')`);
                }

                if (object.searchFilters?.body) {
                    const encodedBody = encodeURIComponent(object.searchFilters.body);
                    filters.push(`contains(body/content, '${encodedBody}')`);
                }

                if (filters.length > 0) {
                    const filterString = filters.join(' and ');
                    url += `&$filter=${filterString}`;
                }

                if (object.sortField) {
                    const order = object.sortOrder === -1 ? 'desc' : 'asc'; // Definir 'asc' o 'desc' según el valor de sortOrder
                    url += `&$orderby=${object.sortField} ${order}`;
                }

                try {
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        }
                    });

                    HelperLoading.hideLoading();
                    if (!response.ok) {
                        throw new Error(`Error en la petición: ${response.statusText}`);
                    }

                    const messages = await response.json();

                    // Actualizar la lista de correos archivados y el conteo total
                    allMails.value = messages.value;
                    mailsCount.value = messages['@odata.count'];

                    return {
                        messages: messages.value,
                        total: messages['@odata.count']
                    };

                } catch (error) {
                    HelperLoading.hideLoading();
                    console.error("Error al obtener correos archivados:", error);
                    return null;
                }

            } else {
                HelperLoading.hideLoading();
                return null;
            }
        };

        const getSpam = async (object: any, loading: boolean, targetEmail: string = '') => {
            if (loading) {
                HelperLoading.showLoading();
            }
            const accessToken = await getAccessToken();
            if (accessToken) {

                let url;
                if (targetEmail == '') {
                    url = `https://graph.microsoft.com/v1.0/me/mailFolders/junkemail/messages?$top=${object.page}&$skip=${object.skip}&$count=true`;
                } else {
                    url = `https://graph.microsoft.com/v1.0/users/${encodeURIComponent(targetEmail)}/mailFolders/junkemail/messages?$top=${object.page}&$skip=${object.skip}&$count=true`;
                }

                let filters: string[] = ['receivedDateTime ge 1900-01-01'];

                if (object.searchFilters?.fromTo) {
                    const encodedFromTo = encodeURIComponent(object.searchFilters.fromTo);
                    //filters.push(`(contains(from/emailAddress/name, '${encodedFromTo}') or contains(toRecipients/emailAddress/name, '${encodedFromTo}'))`);
                    filters.push(`contains(from/emailAddress/address, '${encodedFromTo}')`);
                }

                if (object.searchFilters?.subject) {
                    const encodedSubject = encodeURIComponent(object.searchFilters.subject);
                    filters.push(`contains(subject, '${encodedSubject}')`);
                }

                if (object.searchFilters?.body) {
                    const encodedBody = encodeURIComponent(object.searchFilters.body);
                    filters.push(`contains(body/content, '${encodedBody}')`);
                }

                if (filters.length > 0) {
                    const filterString = filters.join(' and ');
                    url += `&$filter=${filterString}`;
                }

                if (object.sortField) {
                    const order = object.sortOrder === -1 ? 'desc' : 'asc'; // Definir 'asc' o 'desc' según el valor de sortOrder
                    url += `&$orderby=${object.sortField} ${order}`;
                }

                try {
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        }
                    });

                    HelperLoading.hideLoading();
                    if (!response.ok) {
                        throw new Error(`Error en la petición: ${response.statusText}`);
                    }

                    const messages = await response.json();

                    // Actualizar la lista de correos no deseados y el conteo total
                    allMails.value = messages.value;
                    mailsCount.value = messages['@odata.count'];

                    return {
                        messages: messages.value,
                        total: messages['@odata.count']
                    };

                } catch (error) {
                    HelperLoading.hideLoading();
                    console.error("Error al obtener correos no deseados:", error);
                    return null;
                }

            } else {
                HelperLoading.hideLoading();
                return null;
            }
        };

        const getDrafts = async (object: any, loading: boolean, targetEmail: string = '') => {
            if (loading) {
                HelperLoading.showLoading();
            }
            const accessToken = await getAccessToken();
            if (accessToken) {
                let url;
                if (targetEmail == '') {
                    url = `https://graph.microsoft.com/v1.0/me/mailFolders/drafts/messages?$top=${object.page}&$skip=${object.skip}&$count=true`;
                } else {
                    url = `https://graph.microsoft.com/v1.0/users/${encodeURIComponent(targetEmail)}/mailFolders/drafts/messages?$top=${object.page}&$skip=${object.skip}&$count=true`;
                }

                let filters = ['receivedDateTime ge 1900-01-01', 'isDraft eq true'];

                if (object.searchFilters?.fromTo) {
                    const encodedFromTo = encodeURIComponent(object.searchFilters.fromTo);
                    //filters.push(`(contains(from/emailAddress/name, '${encodedFromTo}') or contains(toRecipients/emailAddress/name, '${encodedFromTo}'))`);
                    filters.push(`contains(from/emailAddress/address, '${encodedFromTo}')`);
                }

                if (object.searchFilters?.subject) {
                    const encodedSubject = encodeURIComponent(object.searchFilters.subject);
                    filters.push(`contains(subject, '${encodedSubject}')`);
                }

                if (object.searchFilters?.body) {
                    const encodedBody = encodeURIComponent(object.searchFilters.body);
                    filters.push(`contains(body/content, '${encodedBody}')`);
                }

                if (filters.length > 0) {
                    const filterString = filters.join(' and ');
                    url += `&$filter=${filterString}`;
                }

                if (object.sortField) {
                    const order = object.sortOrder === -1 ? 'desc' : 'asc'; // Definir 'asc' o 'desc' según el valor de sortOrder
                    url += `&$orderby=${object.sortField} ${order}`;
                }

                try {
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        }
                    });

                    HelperLoading.hideLoading();
                    if (!response.ok) {
                        throw new Error(`Error en la petición: ${response.statusText}`);
                    }

                    const messages = await response.json();

                    // Actualizar la lista de borradores y el conteo total
                    allMails.value = messages.value;
                    mailsCount.value = messages['@odata.count'];

                    return {
                        messages: messages.value,
                        total: messages['@odata.count']
                    };

                } catch (error) {
                    HelperLoading.hideLoading();
                    console.error("Error al obtener borradores:", error);
                    return null;
                }

            } else {
                HelperLoading.hideLoading();
                return null;
            }
        };

        const getStarred = async (object: any, loading: boolean, targetEmail: string = '') => {
            if (loading) {
                HelperLoading.showLoading();
            }
            const accessToken = await getAccessToken();
            if (accessToken) {
                let url;
                if (targetEmail == '') {
                    url = `https://graph.microsoft.com/v1.0/me/messages?$top=${object.page}&$skip=${object.skip}&$count=true`;
                } else {
                    url = `https://graph.microsoft.com/v1.0/users/${encodeURIComponent(targetEmail)}/messages?$top=${object.page}&$skip=${object.skip}&$count=true`;
                }

                let filters = ['receivedDateTime ge 1900-01-01',`flag/flagStatus eq 'flagged'`];

                if (object.searchFilters?.fromTo) {
                    const encodedFromTo = encodeURIComponent(object.searchFilters.fromTo);
                    //filters.push(`(contains(from/emailAddress/name, '${encodedFromTo}') or contains(toRecipients/emailAddress/name, '${encodedFromTo}'))`);
                    filters.push(`contains(from/emailAddress/address, '${encodedFromTo}')`);
                }

                if (object.searchFilters?.subject) {
                    const encodedSubject = encodeURIComponent(object.searchFilters.subject);
                    filters.push(`contains(subject, '${encodedSubject}')`);
                }

                if (object.searchFilters?.body) {
                    const encodedBody = encodeURIComponent(object.searchFilters.body);
                    filters.push(`contains(body/content, '${encodedBody}')`);
                }

                if (filters.length > 0) {
                    const filterString = filters.join(' and ');
                    url += `&$filter=${filterString}`;
                }

                if (object.sortField) {
                    const order = object.sortOrder === -1 ? 'desc' : 'asc'; // Definir 'asc' o 'desc' según el valor de sortOrder
                    url += `&$orderby=${object.sortField} ${order}`;
                }

                try {
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        }
                    });

                    HelperLoading.hideLoading();
                    if (!response.ok) {
                        throw new Error(`Error en la petición: ${response.statusText}`);
                    }

                    const messages = await response.json();

                    // Actualizar la lista de correos destacados y el conteo total
                    allMails.value = messages.value;
                    mailsCount.value = messages['@odata.count'];

                    return {
                        messages: messages.value,
                        total: messages['@odata.count']
                    };

                } catch (error) {
                    HelperLoading.hideLoading();
                    console.error("Error al obtener correos destacados:", error);
                    return null;
                }

            } else {
                HelperLoading.hideLoading();
                return null;
            }
        };

        const getImportants = async (object: any, loading: boolean, targetEmail: string = '') => {
            if (loading) {
                HelperLoading.showLoading();
            }
            const accessToken = await getAccessToken();
            if (accessToken) {
                let url;
                if (targetEmail == '') {
                    url = `https://graph.microsoft.com/v1.0/me/mailFolders/inbox/messages?$top=${object.page}&$skip=${object.skip}&$count=true`;
                } else {
                    url = `https://graph.microsoft.com/v1.0/users/${encodeURIComponent(targetEmail)}/mailFolders/inbox/messages?$top=${object.page}&$skip=${object.skip}&$count=true`;
                }

                let filters = ['receivedDateTime ge 1900-01-01', `importance eq 'high'`];
                
                if (object.searchFilters?.fromTo) {
                    const encodedFromTo = encodeURIComponent(object.searchFilters.fromTo);
                    //filters.push(`(contains(from/emailAddress/name, '${encodedFromTo}') or contains(toRecipients/emailAddress/name, '${encodedFromTo}'))`);
                    filters.push(`contains(from/emailAddress/address, '${encodedFromTo}')`);
                }

                if (object.searchFilters?.subject) {
                    const encodedSubject = encodeURIComponent(object.searchFilters.subject);
                    filters.push(`contains(subject, '${encodedSubject}')`);
                }

                if (object.searchFilters?.body) {
                    const encodedBody = encodeURIComponent(object.searchFilters.body);
                    filters.push(`contains(body/content, '${encodedBody}')`);
                }

                if (filters.length > 0) {
                    const filterString = filters.join(' and ');
                    url += `&$filter=${filterString}`;
                }

                if (object.sortField) {
                    const order = object.sortOrder === -1 ? 'desc' : 'asc'; // Definir 'asc' o 'desc' según el valor de sortOrder
                    url += `&$orderby=${object.sortField} ${order}`;
                }

                try {
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        }
                    });

                    HelperLoading.hideLoading();
                    if (!response.ok) {
                        throw new Error(`Error en la petición: ${response.statusText}`);
                    }

                    const messages = await response.json();

                    // Actualizar la lista de correos importantes y el conteo total
                    allMails.value = messages.value;
                    mailsCount.value = messages['@odata.count'];

                    return {
                        messages: messages.value,
                        total: messages['@odata.count']
                    };

                } catch (error) {
                    HelperLoading.hideLoading();
                    console.error("Error al obtener correos importantes:", error);
                    return null;
                }

            } else {
                HelperLoading.hideLoading();
                return null;
            }
        };

        const getSent = async (object: any, loading: boolean, targetEmail: string = '') => {
            if (loading) {
                HelperLoading.showLoading();
            }
            const accessToken = await getAccessToken();
            if (accessToken) {
                let url;
                if (targetEmail == '') {
                    url = `https://graph.microsoft.com/v1.0/me/mailFolders/sentitems/messages?$top=${object.page}&$skip=${object.skip}&$count=true`;
                } else {
                    url = `https://graph.microsoft.com/v1.0/users/${encodeURIComponent(targetEmail)}/mailFolders/sentitems/messages?$top=${object.page}&$skip=${object.skip}&$count=true`;
                }
                
                let filters: string[] = ['receivedDateTime ge 1900-01-01'];

                if (object.searchFilters?.fromTo) {
                    const encodedFromTo = encodeURIComponent(object.searchFilters.fromTo);
                    //filters.push(`(contains(from/emailAddress/name, '${encodedFromTo}') or contains(toRecipients/emailAddress/name, '${encodedFromTo}'))`);
                    filters.push(`contains(from/emailAddress/address, '${encodedFromTo}')`);
                }

                if (object.searchFilters?.subject) {
                    const encodedSubject = encodeURIComponent(object.searchFilters.subject);
                    filters.push(`contains(subject, '${encodedSubject}')`);
                }

                if (object.searchFilters?.body) {
                    const encodedBody = encodeURIComponent(object.searchFilters.body);
                    filters.push(`contains(body/content, '${encodedBody}')`);
                }

                if (filters.length > 0) {
                    const filterString = filters.join(' and ');
                    url += `&$filter=${filterString}`;
                }

                if (object.sortField) {
                    const order = object.sortOrder === -1 ? 'desc' : 'asc'; // Definir 'asc' o 'desc' según el valor de sortOrder
                    url += `&$orderby=${object.sortField} ${order}`;
                }

                try {
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        }
                    });

                    HelperLoading.hideLoading();
                    if (!response.ok) {
                        throw new Error(`Error en la petición: ${response.statusText}`);
                    }

                    const messages = await response.json();

                    // Actualizar la lista de correos enviados y el conteo total
                    allMails.value = messages.value;
                    mailsCount.value = messages['@odata.count'];

                    return {
                        messages: messages.value,
                        total: messages['@odata.count']
                    };

                } catch (error) {
                    HelperLoading.hideLoading();
                    console.error("Error al obtener correos enviados:", error);
                    return null;
                }

            } else {
                HelperLoading.hideLoading();
                return null;
            }
        };

        const getSentFiltered = async (object: any, loading: boolean, targetEmail: string = '') => {
            if (loading) {
                HelperLoading.showLoading();
            }
            const accessToken = await getAccessToken();
            if (accessToken) {
                let url;
                if (targetEmail == '') {
                    url = `https://graph.microsoft.com/v1.0/me/mailFolders/sentitems/messages?$top=${100}&$count=true`;
                } else {
                    url = `https://graph.microsoft.com/v1.0/users/${encodeURIComponent(targetEmail)}/mailFolders/sentitems/messages?$top=${100}&$count=true`;
                }
                
                let searchQueries: string[] = [];

                if (object.searchFilters?.fromTo) {
                    const encodedFromTo = encodeURIComponent(object.searchFilters.fromTo);
                    searchQueries.push(`to:${encodedFromTo}`);
                }

                if (object.searchFilters?.subject) {
                    const encodedSubject = encodeURIComponent(object.searchFilters.subject);
                    searchQueries.push(`subject:${encodedSubject}`);
                }

                if (object.searchFilters?.body) {
                    const encodedBody = encodeURIComponent(object.searchFilters.body);
                    searchQueries.push(`${encodedBody}`);
                }

                if (searchQueries.length > 0) {
                    const searchString = searchQueries.join(' AND ');
                    url += `&$search="${searchString}"`;
                }

                try {
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        }
                    });

                    HelperLoading.hideLoading();
                    if (!response.ok) {
                        throw new Error(`Error en la petición: ${response.statusText}`);
                    }

                    const messages = await response.json();

                    // Actualizar la lista de correos enviados y el conteo total
                    allMails.value = messages.value;
                    mailsCount.value = messages['@odata.count'];

                    return {
                        messages: messages.value,
                        total: messages['@odata.count']
                    };

                } catch (error) {
                    HelperLoading.hideLoading();
                    console.error("Error al obtener correos enviados:", error);
                    return null;
                }

            } else {
                HelperLoading.hideLoading();
                return null;
            }
        };

        const setBadgeValues = (mails) => {
            for (const mail of mails) {
                if (!mail.archived && !mail.trash && !mail.spam && !mail.sent && !mail.isDraft) {
                    setFilteredMails('inbox', mail);
                }
                Object.keys(mail).forEach((label) => {
                    if (mail[label] === true) {
                        setFilteredMails(label, mail);
                    }
                });
            }
        };

        const moveToTrash = async (message: any) => {
            HelperLoading.showLoading();
            try {
                const accessToken = await getAccessToken();
                if (accessToken) {
                    if (Array.isArray(message)) {
                        const batchRequests = message.map((mail, index) => {
                            let url;
                            if (changeAccountEmail.value === '') {
                                url = `/me/messages/${mail.id}/move`;
                            } else {
                                url = `/users/${encodeURIComponent(changeAccountEmail.value)}/messages/${mail.id}/move`;
                            }
                            
                            return {
                                id: `${index}`, 
                                method: "POST",
                                url: url,
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: {
                                    destinationId: "deleteditems"
                                }
                            };
                        });

                        const batchResponse = await fetch(`https://graph.microsoft.com/v1.0/$batch`, {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${accessToken}`,
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                requests: batchRequests
                            })
                        });

                        HelperLoading.hideLoading();
                        if (batchResponse.ok) {
                            const results = await batchResponse.json();
                            results.responses.forEach(response => {
                                if (response.status !== 200) {
                                    console.error('Error moving message to trash:', response);
                                    MessageService.showMessage(MessageType.Error, 'Error de validación', 'Error al eliminar un correo. ' + response.body);
                                }
                            });
                            MessageService.showMessage(MessageType.Correcto, '', 'Correos enviados a elementos eliminados.');
                            await loadCurrentSection();
                        } else {
                            const errorText = await batchResponse.text();
                            MessageService.showMessage(MessageType.Error, 'Error de validación', 'Error al eliminar correos: ' + errorText);
                        }
                    } else {
                        let url;
                        if (changeAccountEmail.value === '') {
                            url = `https://graph.microsoft.com/v1.0/me/messages/${message.id}/move`;
                        } else {
                            url = `https://graph.microsoft.com/v1.0/users/${encodeURIComponent(changeAccountEmail.value)}/messages/${message.id}/move`;
                        }

                        const moveResponse = await fetch(url, {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${accessToken}`,
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                destinationId: "deleteditems"
                            })
                        });

                        HelperLoading.hideLoading();
                        if (moveResponse.ok) {
                            await loadCurrentSection();
                            MessageService.showMessage(MessageType.Correcto, '', 'Correo enviado a elementos eliminados.');
                        } else {
                            const messageError = await moveResponse.text();
                            MessageService.showMessage(MessageType.Error, 'Error de validación', 'Error al eliminar el correo. ' + messageError);
                        }
                    }
                }
            } catch (error) {
                HelperLoading.hideLoading();
                console.error('Error moving message to trash:', error);
            }
        };

        const assignToObject = async (message: any) => {
            HelperLoading.showLoading();
            try {
                const accessToken = await getAccessToken();
                if (accessToken) {
                    let url;
                    if (changeAccountEmail.value === '') {
                        url = `https://graph.microsoft.com/v1.0/me/messages/${message.id}`;
                    } else {
                        url = `https://graph.microsoft.com/v1.0/users/${encodeURIComponent(changeAccountEmail.value)}/messages/${message.id}`;
                    }

                    const objIdValue = getPropertyNumberValue(EmailTypeConst.OBJECTID);
                    const idObjectValue = getPropertyNumberValue(EmailTypeConst.IDOBJECT);

                    const extendedProperties = [] as any;
                    if (objIdValue) {
                        extendedProperties.push({
                            id: "String {11223344-5566-7788-99AA-BBCCDDEEFF00} Name objectId",
                            value: objIdValue.toString(),
                        });
                    }

                    if (idObjectValue) {
                        extendedProperties.push({
                            id: "String {AABBCCDD-EEFF-0011-2233-445566778899} Name idObject",
                            value: idObjectValue.toString(),
                        });
                    }

                    if (extendedProperties.length > 0) {
                        const updateResponse = await fetch(url, {
                            method: 'PATCH',
                            headers: {
                                'Authorization': `Bearer ${accessToken}`,
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                singleValueExtendedProperties: extendedProperties
                            })
                        });

                        HelperLoading.hideLoading();
                        if (updateResponse.ok) {
                            await loadCurrentSection();
                            MessageService.showMessage(MessageType.Correcto, '', 'Propiedades asignadas al correo correctamente.');
                        } else {
                            const messageError = await updateResponse.text();
                            MessageService.showMessage(MessageType.Error, 'Error de validación', 'Error al asignar propiedades al correo. ' + messageError);
                        }
                    } else {
                        HelperLoading.hideLoading();
                        MessageService.showMessage(MessageType.Error, 'Error de validación', 'No se encontraron valores para asignar.');
                    }
                }
            } catch (error) {
                HelperLoading.hideLoading();
                console.error('Error assigning properties to message:', error);
            }
        };

        const moveToArchive = async (message: any) => {
            HelperLoading.showLoading();
            try {
                const accessToken = await getAccessToken();
                if (accessToken) {
                    if (Array.isArray(message)) {
                        const batchRequests = message.map((mail, index) => {
                            let url;
                            if (changeAccountEmail.value === '') {
                                url = `/me/messages/${mail.id}/move`;
                            } else {
                                url = `/users/${encodeURIComponent(changeAccountEmail.value)}/messages/${mail.id}/move`;
                            }

                            return {
                                id: `${index}`, 
                                method: "POST",
                                url: url,
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: {
                                    destinationId: "archive" 
                                }
                            };
                        });

                        const batchResponse = await fetch(`https://graph.microsoft.com/v1.0/$batch`, {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${accessToken}`,
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                requests: batchRequests
                            })
                        });

                        HelperLoading.hideLoading();
                        if (batchResponse.ok) {
                            const results = await batchResponse.json();
                            results.responses.forEach(response => {
                                if (response.status !== 200) {
                                    console.error('Error moving message to archive:', response);
                                    MessageService.showMessage(MessageType.Error, 'Error de validación', 'Error al archivar un correo. ' + response.body);
                                }
                            });
                            MessageService.showMessage(MessageType.Correcto, '', 'Correos archivados.');
                            await loadCurrentSection();
                        } else {
                            const errorText = await batchResponse.text();
                            MessageService.showMessage(MessageType.Error, 'Error de validación', 'Error al archivar correos: ' + errorText);
                        }
                    } else {
                        let url;
                        if (changeAccountEmail.value === '') {
                            url = `https://graph.microsoft.com/v1.0/me/messages/${message.id}/move`;
                        } else {
                            url = `https://graph.microsoft.com/v1.0/users/${encodeURIComponent(changeAccountEmail.value)}/messages/${message.id}/move`;
                        }

                        const moveResponse = await fetch(url, {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${accessToken}`,
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                destinationId: "archive" 
                            })
                        });

                        HelperLoading.hideLoading();
                        if (moveResponse.ok) {
                            await loadCurrentSection();
                            MessageService.showMessage(MessageType.Correcto, '', 'Correo enviado a archivados.');
                        } else {
                            const messageError = await moveResponse.text();
                            MessageService.showMessage(MessageType.Error, 'Error de validación', 'Error al archivar el correo. ' + messageError);
                        }
                    }
                }
            } catch (error) {
                HelperLoading.hideLoading();
                console.error('Error moving message to archive:', error);
            }
        };

        const loadCurrentSection = async () => {
            if (sections.value.inbox) {
                await loadInbox(true, changeAccountEmail.value);
            }
            else if (sections.value.starred) {
                await loadStarred(true, changeAccountEmail.value);
            }
            else if (sections.value.spam) {
                await loadSpam(true, changeAccountEmail.value);
            }
            else if (sections.value.important) {
                await loadImportant(true, changeAccountEmail.value);
            }
            else if (sections.value.sent) {
                await loadSent(true, changeAccountEmail.value);
            }
            else if (sections.value.archived) {
                await loadArchived(true, changeAccountEmail.value);
            }
            else if (sections.value.draft) {
                await loadDraft(true, changeAccountEmail.value);
            }
            else if (sections.value.trash) {
                await loadTrash(true, changeAccountEmail.value);
            }
            else if (sections.value.folder != '') { // Folders
                await loadFolder();
            }
            else if (sections.value.group != '') {
                await loadGroup();
            }
        };

        const moveToSpam = async (message: any) => {
            HelperLoading.showLoading();
            try {
                const accessToken = await getAccessToken();
                if (accessToken) {
                    if (Array.isArray(message)) {
                        const batchRequests = message.map((mail, index) => {
                            const url = changeAccountEmail.value === ''
                                ? `/me/messages/${mail.id}/move`
                                : `/users/${encodeURIComponent(changeAccountEmail.value)}/messages/${mail.id}/move`;

                            return {
                                id: `${index}`, // Identificador único
                                method: "POST",
                                url: url,
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: {
                                    destinationId: "junkemail" // Carpeta de correos no deseados
                                }
                            };
                        });

                        const batchResponse = await fetch(`https://graph.microsoft.com/v1.0/$batch`, {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${accessToken}`,
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                requests: batchRequests
                            })
                        });

                        HelperLoading.hideLoading();
                        if (batchResponse.ok) {
                            const results = await batchResponse.json();
                            results.responses.forEach(response => {
                                if (response.status !== 200) {
                                    console.error('Error moving message to junk:', response);
                                    MessageService.showMessage(MessageType.Error, 'Error de validación', 'Error al mover un correo a no deseados. ' + response.body);
                                }
                            });
                            MessageService.showMessage(MessageType.Correcto, '', 'Correos enviados a no deseados.');
                            await loadCurrentSection();
                        } else {
                            const errorText = await batchResponse.text();
                            MessageService.showMessage(MessageType.Error, 'Error de validación', 'Error al mover correos: ' + errorText);
                        }
                    } else {
                        const url = changeAccountEmail.value === ''
                            ? `https://graph.microsoft.com/v1.0/me/messages/${message.id}/move`
                            : `https://graph.microsoft.com/v1.0/users/${encodeURIComponent(changeAccountEmail.value)}/messages/${message.id}/move`;

                        const moveResponse = await fetch(url, {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${accessToken}`,
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                destinationId: "junkemail"
                            })
                        });

                        HelperLoading.hideLoading();
                        if (moveResponse.ok) {
                            MessageService.showMessage(MessageType.Correcto, '', 'Correo enviado a no deseados.');
                            await loadCurrentSection();
                        } else {
                            const messageError = await moveResponse.text();
                            MessageService.showMessage(MessageType.Error, 'Error de validación', 'Error al mover el correo a no deseados. ' + messageError);
                        }
                    }
                }
            } catch (error) {
                HelperLoading.hideLoading();
                console.error('Error moving message to junk:', error);
            }
        };

        const setFilteredMails = (type, mail) => {
            if (!filteredMails.value[type]) {
                filteredMails.value[type] = [];
            } else {
                filteredMails.value[type].push(mail);
            }
        };

        const myDraft = computed(() => {
            return emailId.value != null ? allMails.value.find(d => d.id == emailId.value): undefined;
        });

        const sideBarMenu = ref([
                { label: 'Bandeja de entrada', icon: 'pi pi-inbox', badge: allMails.value.filter(m => !m.isRead)?.length || 0, routerLink: 'inbox', childLevel: 0, displayed: 0 },
                { label: 'Seguimiento', icon: 'pi pi-star', badge: allMails.value.filter(m => !m.isRead)?.length || 0, routerLink: 'starred', childLevel: 0, displayer: 0 },
                { label: 'No deseado', icon: 'pi pi-ban', badge: allMails.value.filter(m => !m.isRead)?.length || 0, routerLink: 'spam', childLevel: 0, displayer: 0 },
                { label: 'Importantes', icon: 'pi pi-bookmark', badge: allMails.value.filter(m => !m.isRead)?.length || 0, routerLink: 'important', childLevel: 0, displayed: 0 },
                { label: 'Enviados', icon: 'pi pi-send', badge: allMails.value.filter(m => !m.isRead)?.length || 0, routerLink: 'sent', childLevel: 0, displayed: 0 },
                { label: 'Archivados', icon: 'pi pi-book', badge: allMails.value.filter(m => !m.isRead)?.length || 0, routerLink: 'archived', childLevel: 0, displayed: 0 },
                { label: 'Borradores', icon: 'pi pi-pencil', badge: allMails.value.filter(m => !m.isRead)?.length || 0, routerLink: 'draft', childLevel: 0, displayed: 0 },
                { label: 'Papelera', icon: 'pi pi-trash', badge: allMails.value.filter(m => !m.isRead)?.length || 0, routerLink: 'trash', childLevel: 0, displayed: 0 }
            ]) as any;

        const sidebarItems = computed(() => {
            //const mails: any = filteredMails.value;

            return sideBarMenu.value;
        });

        const onDeleteMail = async (selectedMails) => {
            filteredMails.value = {};
            const toastDetail = selectedMails.length > 0 ? 'Mails deleted' : 'Mail deleted';

            findAndApplyAction('trash', selectedMails);

            toast.add({ severity: 'info', summary: 'Info', detail: toastDetail, life: 3000 });
            initMail(allMails.value);
        };

        const onSpamMail = async (selectedMails) => {
            filteredMails.value = {};

            selectedMails.forEach((selectedMail) => {
                allMails.value.find((mail) => mail.id === selectedMail.id).spam = true;
            });
            initMail(allMails.value);
        };

        const onArchiveMail = async (selectedMails) => {
            filteredMails.value = {};

            findAndApplyAction('archived', selectedMails);

            initMail(allMails.value);
        };

        const onChangeMailType = async (type, selectedMail) => {
            if (type == 'starred') {
                await markAsFlagged(selectedMail.id, selectedMail.flag.flagStatus == 'flagged' ? 'notFlagged' : 'flagged');
                await loadCurrentSection();
            }
            else if (type == 'important') {
                await markAsImportant(selectedMail.id, selectedMail.importance == 'high' ? 'normal' : 'high');
                await loadCurrentSection();
            }

            initMail(allMails.value);
        };

        const findAndApplyAction = (action, selectedMails) => {
            if (selectedMails.length > 0) {
                selectedMails.forEach((selectedMail) => {
                    allMails.value.find((mail) => mail.id === selectedMail.id)[action] = true;
                });
            } else {
                allMails.value.find((mail) => mail.id === selectedMails.id)[action] = true;
            }
        };

        const showReplyDialog = (mail) => {
            dialogVisible.value = true;
            mailDetail.value = mail;
        };

        const onSaveReplyMail = async (messageId: string, mail: any) => {
            dialogVisible.value = false;
            mailDetail.value = null;

            //filteredMails.value = {};
            //allMails.value.push(mail);

            //initMail(allMails.value);

            await replyMailMicrosoft(messageId, mail);
            await loadSent(false);
        };

        const replyMailMicrosoft = async (messageId: string, newMail: any) => {
            HelperLoading.showLoading();

            const accessToken = await getAccessToken();
            if (accessToken) {
                // Determinar la URL en función de si `changeAccountEmail.value` tiene un valor
                const url = changeAccountEmail.value === ''
                    ? `https://graph.microsoft.com/v1.0/me/messages/${messageId}/reply`
                    : `https://graph.microsoft.com/v1.0/users/${encodeURIComponent(changeAccountEmail.value)}/messages/${messageId}/reply`;

                // Construir el cuerpo del mensaje de respuesta
                const replyMailData = {
                    message: {
                        subject: newMail.subject,
                        body: {
                            contentType: "HTML",
                            content: newMail.body,
                        },
                        toRecipients: Array.isArray(newMail.toRecipients) ? newMail.toRecipients.split(';').map(email => email.trim()).map(recipient => ({
                            emailAddress: { address: recipient }
                        })) : [{ emailAddress: { address: newMail.toRecipients }}],
                        ccRecipients: newMail.ccRecipients && newMail.ccRecipients.length > 0 ? newMail.ccRecipients.split(';').map(email => email.trim()).map(cc => ({
                            emailAddress: { address: cc }
                        })) : [],
                        /*attachments: uploadFiles.value?.map((attachment: any) => ({
                            '@odata.type': '#microsoft.graph.fileAttachment',
                            name: attachment.name,
                            contentBytes: attachment.contentBytes,
                        })) || [],*/
                    },
                    saveToSentItems: true,
                };

                try {
                    const response = await fetch(url, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(replyMailData),
                    });

                    HelperLoading.hideLoading();

                    if (!response.ok) {
                        MessageService.showMessage(MessageType.Error, 'Error de validación', 'Error al enviar la respuesta.');
                        return false;
                    }

                    MessageService.showMessage(MessageType.Correcto, '', 'Respuesta enviada correctamente.');
                    return true;

                } catch (error) {
                    HelperLoading.hideLoading();
                    MessageService.showMessage(MessageType.Error, 'Error de validación', 'Error al enviar la respuesta.');
                    return false;
                }
            } else {
                HelperLoading.hideLoading();
                console.error("No se pudo obtener el token de acceso.");
                return false;
            }
        };

        const onSendNewMail = (mail) => {
            filteredMails.value = {};
            allMails.value.push(mail);

            initMail(allMails.value);
        };

        const onChangeDialogVisibility = (isVisible) => {
            dialogVisible.value = isVisible;
        };

        const updateEmailId = (draftId: string) => {
            emailId.value = draftId;
        };


        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
        })

        const doChangeAccount = async () => {
            if (HelperCommon.validarEmail(changeAccountEmail.value)) {
                if (isValidData()) {
                    isDialogVisible.value = false;
                    await loadCurrentSection();
                }
            }
            else{
                MessageService.showMessage(MessageType.Error, 'Error de validación', 'Debe indicar un e-mail válido.');
            }
        }

        /*onUnmounted(() => {
            baseOnUnmount();
        })*/


        onMounted(async () => {
            await initializeMsalInstance();

            activeMailItem.value = 'inbox';
            await loadInbox(true);
            filterFirstLoaded.value = true;

            var folders = await getMailFolders(true);
            sideBarMenu.value.find(x => x.label == 'Bandeja de entrada').childFolderCount = folders.find(f => f.displayName == 'Bandeja de entrada').childFolderCount;
            sideBarMenu.value.find(x => x.label == 'Bandeja de entrada').staticFolderId = folders.find(f => f.displayName == 'Bandeja de entrada').id;
            sideBarMenu.value.find(x => x.label == 'Enviados').childFolderCount = folders.find(f => f.displayName == 'Bandeja de salida').childFolderCount;
            sideBarMenu.value.find(x => x.label == 'Enviados').staticFolderId = folders.find(f => f.displayName == 'Bandeja de salida').id;
            sideBarMenu.value.find(x => x.label == 'Borradores').childFolderCount = folders.find(f => f.displayName == 'Borradores').childFolderCount;
            sideBarMenu.value.find(x => x.label == 'Borradores').staticFolderId = folders.find(f => f.displayName == 'Borradores').id;
            sideBarMenu.value.find(x => x.label == 'No deseado').childFolderCount = folders.find(f => f.displayName == 'Correo no deseado').childFolderCount;
            sideBarMenu.value.find(x => x.label == 'No deseado').staticFolderId = folders.find(f => f.displayName == 'Correo no deseado').id;
            sideBarMenu.value.find(x => x.label == 'Papelera').childFolderCount = folders.find(f => f.displayName == 'Elementos eliminados').childFolderCount;
            sideBarMenu.value.find(x => x.label == 'Papelera').staticFolderId = folders.find(f => f.displayName == 'Elementos eliminados').id;  
            sideBarMenu.value.find(x => x.label == 'Archivados').childFolderCount = folders.find(f => f.displayName == 'Archive').childFolderCount;
            sideBarMenu.value.find(x => x.label == 'Archivados').staticFolderId = folders.find(f => f.displayName == 'Archive').id;
            var newFolders = folders.filter(f => f.displayName != 'Bandeja de entrada' && f.displayName != 'Bandeja de salida' && f.displayName != 'Borradores' && f.displayName != 'Correo no deseado' && f.displayName != 'Elementos eliminados' && f.displayName != 'Archive');
            newFolders.forEach(f => {
                sideBarMenu.value.push({ label: f.displayName, icon: 'pi pi-folder-open', badge: allMails.value.filter(m => !m.isRead)?.length || 0, routerLink: 'folder', folderId: f.id, childFolderCount: f.childFolderCount, childLevel: 0, displayed: 0 });    
            });

            sideBarMenu.value.push({ label: 'Grupos', icon: 'pi pi-users', badge: 0, routerLink: 'groups', childLevel: 0, childFolderCount: 1, displayed: 0 });
            
            //var user = await getUser();
            //var users = await getUsers();
            //var sharedMails = await getSharedMails();

            /*if (emailTypes.value && filterDefaultValue) {
                emailTypes.value.onSearch();  // Invocar el método del componente hijo
            }*/
            //initMail(allMails.value);
        });

        return {
            BaseControlTypeConst,
            getPropertyBooleanValue,
            vmodel,
            EmailTypeConst,
            getPropertyValue,
            getPropertyNumberValue,
            loaded,
            canDoOperation,
            ObjectGroupConst,
            componentKey,
            allMails,
            filteredMails,
            activeMailItem,
            moveToTrash,
            moveToArchive,
            moveToSpam,
            onSpamMail,
            onArchiveMail,
            onChangeMailType,
            showReplyDialog,
            onSaveReplyMail,
            onSendNewMail,
            sidebarItems,
            dialogVisible,
            mailDetail,
            onChangeDialogVisibility,
            handleNavigate,
            sections,
            emailId,
            getMails,
            getDrafts,
            getTrash,
            getArchived,
            getSpam,
            getImportants,
            getSent,
            getStarred,
            mailsCount,
            refresh,
            updateEmailId,
            myDraft,
            objIdValue,
            idObjectValue,
            filterDefaultValue,
            filterFirstLoaded,
            emailTypes,
            getMailsBySection,
            isDialogVisible,
            toggleChangeAccount,
            changeAccountEmail,
            submitted,
            v$,
            doChangeAccount,
            assignToObject,
        };
    },
});
</script>
<style scoped></style>
